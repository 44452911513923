<table class="DataTableComponent table table-striped table-hover">
  <caption *ngIf="caption">{{ caption }}</caption>
  <thead>
  <tr>
    <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
  </tr>
  </thead>
  <tbody>
  <ng-container
    *ngTemplateOutlet="bodyTemplate ? bodyTemplate : defaultBodyTemplate; context: { $implicit: data }"></ng-container>
  </tbody>
</table>

<div class="empty-state" *ngIf="data.length === 0 && !isLoading">
  <span class="text-muted">{{ emptyStateMessage }}</span>
</div>

<im-paginator></im-paginator>

<ng-template #defaultBodyTemplate let-rows>
  <tr *ngFor="let row of rows">
    <ng-container *ngTemplateOutlet="rowTemplate; context: { $implicit: row }"></ng-container>
  </tr>
</ng-template>
