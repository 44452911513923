<form [formGroup]="form" (submit)="onSubmit()">
  <div class="form-group">
    <label for="Key">Herkunfts-ID</label>
    <input type="text" class="form-control" id="Key"[formControl]="Key" />
    <im-field-error-display
      [displayError]="isFieldInValid('Key')"
      errorMsg="Bitte einen gültigen Wert eintragen (Nur Nummern erlaubt)">
    </im-field-error-display>
  </div>

  <div class="form-group">
    <label for="Name">Bezeichnung</label>
    <input type="text" class="form-control" id="Name" [formControl]="Name" />
    <im-field-error-display
      [displayError]="isFieldInValid('Name')"
      errorMsg="Bitte einen gültigen Namen eintragen">
    </im-field-error-display>
  </div>

  <div class="form-group">
    <button class="btn btn-primary" type="submit" imDisabledWhileLoading>
      <im-icon name="plus"></im-icon>
      Erstellen
    </button>
  </div>
</form>
