import {ApiClient} from "../../../../common/api/ApiClient";
import {Injectable} from "@angular/core";
import {AppointmentSeasons} from "../../enums/AppointmentSeasons";
import {SEASON_OCCASIONS, SeasonOccasion} from "../../models/SeasonOccasion";

@Injectable({
  providedIn: 'root',
})
export class SeasonHelper {
  public summerSeasonStart: Date;
  public winterSeasonStart: Date;
  public currentSeason: string;
  public currentSeasonEnd: Date;
  public nextSeasonStart: Date;
  public nextSeasonEnd: Date;

  constructor(private api: ApiClient) {
    this.api.all<SeasonOccasion>(SEASON_OCCASIONS)
      .subscribe(seasonOccasions => {
        seasonOccasions.forEach((seasonOccasion) => {
          let convertedDate = new Date(seasonOccasion.date.toString().replace(/Z/g, ''))
          if (seasonOccasion.name === "summerStart") {
            this.summerSeasonStart = convertedDate
          } else if (seasonOccasion.name === "winterStart") {
            this.winterSeasonStart = convertedDate
          } else if (seasonOccasion.name === "currentSeasonEnd") {
            this.currentSeasonEnd = convertedDate
          } else if (seasonOccasion.name === "nextSeasonStart") {
            this.nextSeasonStart = convertedDate
          } else if (seasonOccasion.name === "nextSeasonEnd") {
            this.nextSeasonEnd = convertedDate
          }
        })

        if (this.winterSeasonStart < new Date()) {
          this.currentSeason = AppointmentSeasons.WINTER
        } else {
          this.currentSeason = AppointmentSeasons.SUMMER
        }
      });
  }

  public getNextSeasonEnd(): Promise<Date> {
    return new Promise<Date>((resolve, reject) => {
      if (this.nextSeasonEnd) {
        resolve(this.nextSeasonEnd);
      } else {
        this.api.all<SeasonOccasion>(SEASON_OCCASIONS)
          .subscribe(seasonOccasions => {
            seasonOccasions.forEach((seasonOccasion) => {
              if (seasonOccasion.name === "nextSeasonEnd") {
                resolve(new Date(seasonOccasion.date.toString().replace(/Z/g, '')));
              }
            })
          });
      }
    });
  }

}
