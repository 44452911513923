/* eslint-disable */
import { Component } from '@angular/core';

@Component({
  selector: 'sebu-logo',
  styleUrls: ['logo.component.scss'],
  templateUrl: 'logo.component.html',
})
export class LogoComponent {
}
