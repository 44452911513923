import { Injectable } from '@angular/core';
import { CUSTOMER_ENDPOINT, Customer } from '../models/Customer';
import { ApiClient } from '../../../common/api/ApiClient';
import { tap } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { FromDB } from '../../../common/api/FromDB';

/**
 * Service zum Speichern des bereits geladenen Customers in
 * der Customerdetailansicht. Da hier zwischen Routen gewechselt wird,
 * fungiert dieser Service als "Shared State", um nicht bei jedem Routenwechsel neu
 * die Daten vom Server abrufen zu müssen.
 */
@Injectable({
  providedIn: 'root',
})
export class CustomerDetailService {
  constructor(private api: ApiClient) {
  }

  public customer: FromDB<Customer>;

  public reload(): Observable<FromDB<Customer>> {
    if (!this.customer) {
      return EMPTY;
    }

    return this.api
      .show<Customer>(CUSTOMER_ENDPOINT, this.customer.Id)
      .pipe(tap(customer => {
        this.customer = customer;
      }));
  }
}
