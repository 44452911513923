import { NgModule } from '@angular/core';
import { CommonModule as AngularCommonModule } from '@angular/common';
import { CommonModule } from '../../../common/common.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JWTInterceptor } from './interceptors/jwt.interceptor';
import { ClientTypeInterceptor } from './interceptors/client-type.interceptor';
import { AuthService } from './auth.service';
import { AuthGuard } from './auth.guard';
import { AdminOnlyDirective } from './admin-only.directive';
import { UserNavEntryComponent } from './user-nav-entry/user-nav-entry.component';
import { RouterModule } from '@angular/router';
import {AdminGuard} from "./admin.guard";

@NgModule({
  imports: [
    AngularCommonModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  declarations: [
    LoginComponent,
    AdminOnlyDirective,
    UserNavEntryComponent,
  ],
  exports: [
    LoginComponent,
    AdminOnlyDirective,
    UserNavEntryComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JWTInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ClientTypeInterceptor,
      multi: true,
    },
    AuthService,
    AuthGuard,
    AdminGuard,
  ],
})
export class AuthModule {
}
