import { Component, Input } from '@angular/core';
import { CustomerAddress, showCustomerName } from '../../../../models/Customer';

@Component({
  selector: 'sebu-customer-address',
  template: `
    <ng-container *ngIf="address">
        <sebu-customer-attribute *ngIf="address.Matchcode" label="Matchcode">
            {{ address.Matchcode }}
      </sebu-customer-attribute>
      <sebu-customer-attribute label="Name">{{ showCustomerName(address) }}</sebu-customer-attribute>
      <sebu-customer-attribute label="Strasse">{{ address.Strasse }}</sebu-customer-attribute>
      <sebu-customer-attribute label="PLZ">{{ address.PLZ}}</sebu-customer-attribute>
      <sebu-customer-attribute label="Ort">{{ address.Ort }}</sebu-customer-attribute>
      <sebu-customer-attribute label="Telefon">{{ address.Telefon }}</sebu-customer-attribute>
      <sebu-customer-attribute label="Handynummer">{{ address.Handynummer }}</sebu-customer-attribute>
      <sebu-customer-attribute label="E-Mail">{{ address.EMail }}</sebu-customer-attribute>
    </ng-container>
  `,
})
export class CustomerAddressComponent {
  @Input() address: CustomerAddress;
  showCustomerName = showCustomerName;
}
