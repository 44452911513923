import { Injectable } from '@angular/core';
import { ApiClient } from '../../../common/api/ApiClient';
import { Customer } from '../models/Customer';
import { FromDB } from '../../../common/api/FromDB';
import { Contact, CONTACT_ENDPOINT } from '../models/Contact';

@Injectable({
  providedIn: 'root',
})
export class CustomerContactService {
  constructor(private api: ApiClient) {
  }

  forCustomer(customer: FromDB<Customer>) {
    return this.api.index<Contact>(CONTACT_ENDPOINT + `?customer=${customer.Id}`);
  }
}
