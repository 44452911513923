import { User } from './User';
import { InjectionToken } from '@angular/core';
import { Route, ActivatedRouteSnapshot } from '@angular/router';

/**
 * Konfigurationsmöglichkeiten für das Users-Modul
 */
export interface UsersModuleConfig {
  /**
   * Methode, die für den eingeloggten Nutzer feststellt, ob dieser die Views des UsersModule sehen kann, oder nicht.
   *
   * @param {User} user
   * @returns {boolean}
   */
  canAccess: (route: ActivatedRouteSnapshot, user: User | null) => boolean;
}

export const USERS_MODULE_DEFAULT_CONFIG: UsersModuleConfig = {
  canAccess: () => true,
};

export const UsersModuleConfigService = new InjectionToken<UsersModuleConfig>('UsersModuleConfig', {
  factory: () => {
    return USERS_MODULE_DEFAULT_CONFIG;
  },
});
