<div class="selectedOptions"
     [ngClass]="{ 'empty': isEmpty, 'form-group': shouldRenderList }">
  <ul class="list-group">
    <li *ngFor="let value of _values; let i = index"
        class="list-group-item d-flex justify-content-between align-items-center">
      {{display(value)}}
      <button class="btn btn-sm btn-outline-danger" (click)="remove(i)"
              *ngIf="editable" [hidden]="disabled">
        <im-icon name="minus"></im-icon>
      </button>
    </li>
  </ul>
</div>

<div class="input-group" *ngIf="editable">
  <input type="text" class="form-control" [(ngModel)]="inputValue"
         (change)="_onInputChange()"
         [attr.disabled]="disabled ? true : null"/>
    <button class="btn btn-success addButton" type="button"
            (click)="add(inputValue)"
            [attr.disabled]="(disabled || !inputValue) ? true : null">
      <im-icon name="plus"></im-icon>
    </button>
</div>
