<h3 class="h3 mt-3 mb-3">Neukunden-Pdf Vorlage Upload</h3>
  <div class="btn-group">
    <button class="btn btn-outline-dark"
            (click)="download()" imDisabledWhileLoading>
      <im-icon name="download"></im-icon>
      Aktuelles Pdf herunterladen
    </button>
  </div>
<form [formGroup]="form" (submit)="onSubmit()">
  <br>
  <div class="form-group">
    <im-file-input id="pdfFile" [formControl]="pdfFile" accept="application/pdf"
                   placeholder="PDF hochladen..." (change)="onFileChange()"
                   [inputClasses]="displayFieldCss('pdfFile')">
    </im-file-input>
    <im-field-error-display
      [displayError]="isFieldInValid('pdfFile')"
      errorMsg="Bitte laden Sie eine PDF-Datei hoch!">
    </im-field-error-display>
    <br>
    <div class="form-group">
      <button type="submit" class="btn btn-primary" imDisabledWhileLoading>
        <im-icon name="plus"></im-icon>
        Hochladen
      </button>
    </div>
  </div>
</form>
