// Alle Routen bitte auch als Konstante exportieren, erleichtert
// ein mögliches Refactoring und ist expliziter als mit Strings zu arbeiten
export const HOME = '';
export const LOGIN = 'login';
export const USERS = 'users';
export const MESSAGES = 'messages';
export const CALENDAR = 'calendar';
export const PRESENTATION = 'presentation';
export const CUSTOMERS = 'customers';
export const POTENTIALCUSTOMERS = 'potentialCustomers'
export const SETTINGS = 'settings';
export const ARTICLES = 'articles';
export const ARTICLE_SUGGESTIONS = 'article_suggestions';
export const ORDERS = 'orders';
