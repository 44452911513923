<div *ngIf="hasLoaded && appointments.length === 0"
     class="mt-5 w-100 text-muted text-center" style="user-select: none">
  Keine Termine vorhanden
</div>

<div>
  <div
    class="appointments-for-day"
    *ngFor="let appointmentBundle of appointmentsByDay"
  >
    <h4>{{appointmentBundle.day | sbDate:true}}</h4>

    <ul class="list-group">
      <li
        class="list-group-item container"
        *ngFor="let appointment of appointmentBundle.appointments"
        [imAppointmentCategoryBorder]="appointment"
      >
        <im-appointment-list-item
          [appointment]="appointment"
          [date]="appointmentBundle.day"
          [isSelectionMode]="isSelectionMode"
          [selected]="appointmentIsSelected(appointment)"
          (selectionToggledEvent)="onAppointmentSelectClicked($event)"
        ></im-appointment-list-item>
      </li>
    </ul>
  </div>
</div>
