<!-- Switcher -->
<div class="row">
  <div class="col-md-8">
    <h1 class="page-title">{{ heading }}</h1>
  </div>
  <div class="col-md-4" *ngIf="creationFormTemplate">
    <button class="btn btn-primary switcher" (click)="switchView()">
      <ng-container *ngIf="overviewActive">
        <im-icon name="plus"></im-icon> {{ buttonTextCreate }}
      </ng-container>

      <ng-container *ngIf="creationFormActive">
        <im-icon name="arrow-left"></im-icon> {{ buttonTextBackToOverview }}
      </ng-container>
    </button>
  </div>
</div>

<!-- Content -->
<ng-container *ngTemplateOutlet="activeTemplate"></ng-container>
