import {
  Attribute,
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  Renderer2,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { IconComponent } from '../generic/icon/icon.component';
import { BaseModel } from '../api/BaseModel';

/**
 * Ein Directive zum Anzeigen eines Detailbuttons.
 * Dieser bekommt ein Model übergeben und leitet den Nutzer automatisch auf die Detailansicht
 * unter /route/{id} weiter. Dabei wird davon ausgegangen, dass das Model ein Attribut `Id` besitzt. Dieses
 * Verhalten kann durch den Parameter `getLink` gesteuert werden.
 * Es wird zudem ein Icon vor den Text des Buttons gesetzt. Dieses ist standardmäßig eine Lupe.
 */
@Directive({
  selector: '[imDetailButton]',
})
export class DetailButtonDirective extends RouterLink implements OnInit {
  @HostBinding('class.btn') btn = true;
  @HostBinding('class.btn-outline-dark') btnDark = true;

  /**
   * Das Model, auf dessen Detailansicht weitergeleitet werden soll.
   */
  @Input() model: BaseModel;
  /**
   * Anzuzeigendes Icon von FontAwesome.
   */
  @Input() icon = 'search';
  /**
   * Funktion die angibt, wie die Detailroute aufgebaut ist.
   * @param model
   */
  @Input() getLink = (model: BaseModel) => model.Id;
  constructor(
    router: Router,
    route: ActivatedRoute,
    @Attribute('tabindex') tabIndex: string,
    renderer: Renderer2,
    el: ElementRef,
    private viewContainerRef: ViewContainerRef,
  ) {
    super(router, route, tabIndex, renderer, el);
    const ref = this.viewContainerRef.createComponent(IconComponent);
    ref.instance.name = this.icon;
    (ref.location.nativeElement as HTMLElement).classList.add('me-1');

    // Icon einsetzen
    renderer.insertBefore(
      this.viewContainerRef.element.nativeElement,
      ref.location.nativeElement,
      this.viewContainerRef.element.nativeElement.firstChild,
    );
  }

  ngOnInit() {
    this.routerLink = this.getLink(this.model);
  }
}
