import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FromDB } from '../../../../common/api/FromDB';
import { ExpandedAugmentedAppointment } from '../../ical/ical-query.service';
import { AugmentedAppointment } from '../../ical/i-cal-appointment.service';
import { BaseModel } from '../../../../common/api/BaseModel';
import { DATE_QUERY_PARAM_FORMAT } from '../../../../config/dates';
import * as moment from 'moment';

@Component({
  selector: 'im-appointment-list-item',
  template: `
    <div class="row">
      <div class="col-sm-2 d-flex align-items-center justify-content-center text-center text-md-left">
        <input type="checkbox" class="form-control form-check-input" *ngIf="isSelectionMode"
               [checked]="selected" (change)="selectionToggled()"/>
        <span style="margin-left: 10px; font-size: 1.15em;">
          {{displayTime(appointment)}}
        </span>
      </div>
      <div class="col-sm-8">
        <div>
          <h5>
            {{appointment.title}}
            <small class="text-muted">{{appointment?.appointmentType?.name}}</small>
            <ng-container *ngIf="appointment.completed">
              (Erledigt)
            </ng-container>
          </h5>
          <p class="mb-0">
            Kunde: {{appointment.customerName}}
            <br>
            Ort: {{appointment.location}}
          </p>
        </div>
      </div>
      <div class="col-sm-2 d-flex align-items-center justify-content-center" *ngIf="!isSelectionMode">
        <button class="btn btn-outline-dark ms-auto"
                [routerLink]="'/calendar/' + appointment.Id"
                [queryParams]="{instance: appointment.recurrence.toICALString(), day: getDateStringForQuery()}">
          <im-icon name="search"></im-icon>
          Detail
        </button>
      </div>
    </div>
  `,
  styles: [],
})
export class AppointmentListItemComponent {
  @Input() appointment: FromDB<ExpandedAugmentedAppointment>;
  @Input() date: Date;
  @Input() isSelectionMode: boolean = false;
  @Input() selected: boolean = false;
  @Input() checkboxToggle: Function | null = null;
  @Output() selectionToggledEvent = new EventEmitter<ExpandedAugmentedAppointment>();

  displayTime(appointment: AugmentedAppointment & BaseModel) {
    if (appointment.allDay) {
      return 'Ganztägig';
    }

    const start = appointment.start;
    const end = appointment.end;

    return moment(start).format('HH:mm') + ' - ' +
      moment(end).format('HH:mm');
  }

  getDateStringForQuery() {
    return moment(this.date).format(DATE_QUERY_PARAM_FORMAT);
  }

  selectionToggled() {
    this.selectionToggledEvent.emit(this.appointment);
  }
}
