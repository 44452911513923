<form [formGroup]="form" (submit)="onSubmit()">
  <div class="form-group">
    <label for="category">Kategorie</label>
    <select class="form-control form-select" id="category" [formControl]="category"
            [ngClass]="displayFieldCss('category')" [attr.disabled]="categoriesLoading ? true : null">
      <option *ngFor="let category of allCategories" [value]="category.Id">
        {{category.name}}
      </option>
    </select>
    <im-field-error-display
      [displayError]="isFieldInValid('category')"
      errorMsg="Bitte wählen sie eine Kategorie aus!">
    </im-field-error-display>
  </div>

  <div class="form-group">
    <label for="title">Mitteilung</label>
    <textarea class="form-control" id="title" [formControl]="title"
              [ngClass]="displayFieldCss('title')">
    </textarea>
    <im-field-error-display
      [displayError]="isFieldInValid('title')"
      errorMsg="Bitte geben sie eine Mitteilung ein!">
    </im-field-error-display>
  </div>

  <div class="form-group">
    <label>Empfänger</label>
    <im-multiple-choice-list [formControl]="users" [ngClass]="displayFieldCss('users')">
      <im-checkable-option *ngFor="let user of allUsers" [model]="user"
                            class="list-group-item list-group-item-action">
        {{displayUser(user)}}
      </im-checkable-option>
    </im-multiple-choice-list>
    <im-field-error-display
      [displayError]="isFieldInValid('users')"
      errorMsg="Die Liste der Empfänger sollte mindestens einen Empfänger beinhalten!">
    </im-field-error-display>
  </div>

  <div class="form-group">
    <label>Versenden am <span class="text-muted">(optional)</span></label>
    <p-calendar
      class="ui-fluid" [formControl]="activationDateTime"
      [locale]="de" dateFormat="dd.mm.yy"
      showTime="true" hourFormat="24"
    ></p-calendar>
  </div>

  <div class="form-group">
    <label>Löschen am <span class="text-muted">(optional)</span></label>
    <p-calendar
      class="ui-fluid" [formControl]="endDate"
      [minDate]="today"
      [locale]="de" dateFormat="dd.mm.yy"
    ></p-calendar>
  </div>

  <div class="form-group">
    <button class="btn btn-primary" type="submit" imDisabledWhileLoading>
      <im-icon name="plus"></im-icon>
      Erstellen
    </button>
  </div>
</form>
