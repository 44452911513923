import { Component, ViewChild } from '@angular/core';

import { CUSTOMER_DOCUMENTS_ENDPOINT } from './customer-document-endpoints';
import { CustomerDetailService } from '../../customer-detail.service';
import { ApiClient } from '../../../../../common/api/ApiClient';
import { FromDB } from '../../../../../common/api/FromDB';
import { Customer } from '../../../models/Customer';
import {
  Blob,
  BLOB_ENDPOINT,
} from '../../../../../im-modules/blob/models/Blob';
import { DataTableComponent } from '../../../../../common/api/data-table/data-table.component';
import { FileDownloadService } from '../../../../../common/api/file-download.service';
import { AuthService } from '../../../../../im-modules/user/auth/auth.service';
import { hasRole } from '../../../../../im-modules/user/users/User';
import { Roles } from '../../../../../im-modules/user/users/Role';

interface BlobFromCustomerDocument extends Blob {
  pivot: {
    Id: string;
    customer_id: string;
    blob_id: string;
  };
}

@Component({
  selector: 'sebu-customer-documents',
  templateUrl: './customer-documents.component.html',
  styles: [],
})
export class CustomerDocumentsComponent {
  customer: FromDB<Customer>;
  canUpload: boolean;
  public documents: FromDB<BlobFromCustomerDocument>[] = [];
  @ViewChild(DataTableComponent) dataTable: DataTableComponent;

  constructor(
    detailService: CustomerDetailService,
    public api: ApiClient,
    public fileDownload: FileDownloadService,
    auth: AuthService,
  ) {
    this.customer = detailService.customer;
    this.canUpload = hasRole(auth.user, Roles.AUSSENDIENST)
                      || hasRole(auth.user, Roles.BACKEND_ADMIN)
                      || hasRole(auth.user, Roles.SUPER_ADMIN);
  }

  get endpoint() {
    return `${CUSTOMER_DOCUMENTS_ENDPOINT}?customer=${this.customer.Id}`;
  }

  get endpointWithoutQuery() {
    return CUSTOMER_DOCUMENTS_ENDPOINT;
  }

  extractPivotId(blob: BlobFromCustomerDocument) {
    return blob.pivot.Id;
  }

  public download(blob: FromDB<BlobFromCustomerDocument>) {
    this.fileDownload.download(`${BLOB_ENDPOINT}/raw/${blob.Id}`);
  }

  public reloadDataTable() {
    this.dataTable.loadPage();
  }
}
