import { Contact } from './Contact';
import { Vertreter } from '../../../im-modules/user/users/models/Vertreter';
import { FromDB } from '../../../common/api/FromDB';
import { CUSTOMERS } from '../../../sebu.paths';

export interface Pivot {
  id: string;
  user_id: string;
  customer_id?: string;
}

export interface Customer {
  Hauptkundennummer: number;
  Filialnummer: number;
  ansprechpartnerId: number | null | undefined;
  Hauptansprechpartner: Contact | null | undefined;
  Matchcode: string;
  Kundenname1: string;
  Kundenname2: string;
  Kundenname3: string;
  Strasse: string;
  Nation: string;
  PLZ: string;
  Ort: string;
  Telefon: string;
  Fax: string;
  Ansprechpartner: string;
  Einkaeufer: string;
  Mehrwertsteuer: number;
  UmsatzsteuerIdentifikationsNr: string;
  Mahnstufe: number;
  Liefersperre: number;
  InternerHinweis: string;
  Kundenkennziffer: number;
  Kreditlimit: number;
  Auftragslimit: number;
  Mindestwert: number;
  MWUnterschreitung: number;
  Mindestroherloes: number;
  PreisaufschlagKz: number;
  PreisaufschlagsteuerungNurBeiPreisaufschlagsKz2: number;
  PreisaufschlagProzent: number;
  Anbruchaufschlag: number;
  Eurokunde: number;
  BalmBerechtigung: number;
  BalmzulassungsNr: number;
  VertreterNr: number;
  Kontraktkunde: number;
  Bestpreis: number;
  Ordersatz: number;
  Sonderangebote: number;
  AngebotGruppeNr1: number;
  AngebotGruppeNr2: number;
  Preisgruppe: number;
  MengenstaffelKz: number;
  Rueckstandsbildung: number;
  AlternativArtikel: number;
  RabattKz: number;
  Rabatt: number;
  SkontoKz: number;
  SkontoTage: number;
  Skonto: number;
  BonusKz: number;
  BonusProzent: number;
  ServisaKz: number;
  Rechnungstermine: string;
  Zahlungsart: number;
  VersandArt: string;
  VersandHinweis: string;
  Auslieferungstour: string;
  RuhetageBesuch: string;
  RuhetageAuslieferung: string;
  AuftragsbestaetigungPerFax: number;
  InnendienstVerkaeufer: number;
  SBNationalKZ: number;
  SBKundenGruppe: number;
  KundenBestellNr: number;
  KundenKategorie: number;
  LIKundenNr: number;
  Notiz: string;
  ZweitKundenNr: number;
  ZAKennzeichen: number;
  ZAKundennummer: string;
  InnendienstMitar: number;
  Handynummer: string;
  Rechnungsadresse?: CustomerAddress;
  /** Zugehöriger Aussendienstler, basierend auf Vertreternummer */
  Aussendienstler?: Vertreter;
  /** Zugehöriger Innendienstler, basierend auf InnendienstVerkaeufer */
  Innendienstler?: Vertreter;
  EMail: string;
  Zahlungsbedingung: string;
  pivot?: Pivot | null | undefined;
}

export const CUSTOMER_ENDPOINT = '/kunden';


export type CustomerAddress = Pick<Customer,
  'Kundenname1' | 'Kundenname2' | 'Kundenname3' |
  'Matchcode' | 'Ansprechpartner' |
  'Strasse' | 'PLZ' | 'Ort' |
  'Telefon' | 'Fax'| 'Handynummer' | 'EMail'>;

export type CustomerWithCRMAnsprechpartner = FromDB<Customer> & {
  AnsprechpartnerCRM: FromDB<Contact>[],
};

export function showCustomerName(kunde: CustomerAddress) {
  return [[
    kunde.Kundenname1,
    kunde.Kundenname2,
  ].filter(x => x).join(' '),
    kunde.Kundenname3].filter(x => x).join(' ');
}

export function linkToCustomer(kunde: FromDB<Customer>) {
  return `/${CUSTOMERS}/${kunde.Id}`;
}

export function showCustomerNumber(kunde: Customer) {
  return `${kunde.Hauptkundennummer}`.padStart(5, '0') + `${kunde.Filialnummer}`.padStart(2, '0');
}

export function showOrt(kunde: Customer) {
  return [
    kunde.Strasse,
    `${kunde.PLZ} ${kunde.Ort}`,
  ].filter(x => x).join(', ');
}

export function showAnsprechPartner(kunde: Customer) {
  if (!kunde.Hauptansprechpartner) {
    return kunde.Ansprechpartner;
  }

  return kunde.Hauptansprechpartner.firstName + ' ' + kunde.Hauptansprechpartner.lastName;
}

export function showContactInformation(kunde: Customer) {
  if (!kunde.Hauptansprechpartner) {
    return '';
  }

  return kunde.Hauptansprechpartner.email;
}
