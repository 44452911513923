import { Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LogFactory } from '../../../generic/LogFactory';

let log;

@Component({
  selector: 'im-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileInputComponent),
      multi: true,
    },
  ],
})
export class FileInputComponent implements ControlValueAccessor {
  @Input() id: string;
  @Input() inputClasses = {};
  @Input() accept = '';
  @Input() deletable = false;
  @Input() placeholder = 'Datei auswählen...';
  private _shouldDelete = false;

  @Output() private delete = new EventEmitter();
  @Output() private cancelDelete = new EventEmitter();

  @ViewChild('fileInput') _input: ElementRef;

  @Input() onChange = (obj: any) => {
  }
  @Input() onTouch = (obj: any) => {
  }

  set shouldDelete(val: boolean) {
    this._shouldDelete = val;
    val ? this.delete.emit() : this.cancelDelete.emit();
  }

  get shouldDelete() {
    return this._shouldDelete;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (this._input){
      this._input.nativeElement.disabled = isDisabled;
    }
  }

  writeValue(obj: any): void {
    if (this._input) {
      this._input.nativeElement.value = obj;
      this.onChange(obj);
    } else {
      log('Achtung: Es wurde versucht ein Wert in das Input-Feld zu schreiben, obwohl dies noch nicht initialisiert wurde!');
    }
  }

  get innerText() {
    if (!this._input || (this._input.nativeElement.files && this._input.nativeElement.files.length === 0)) {
      return this.placeholder;
    }

    return Array.from(this._input.nativeElement.files as FileList)
      .map(file => file.name)
      .join(', ');
  }
}

log = LogFactory.create(FileInputComponent);
