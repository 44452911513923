import { Component, ElementRef, Input } from '@angular/core';
import { emitLoadingError } from '../../../../common/api/loading-error-container/loading-error-container.component';
import { LaravelPaginationMetadata } from '../../../../common/api/paginator/LaravelPaginationMetadata';
import { PaginationMetadata } from '../../../../common/api/paginator/PaginationMetadata';
import { LaravelPaginatedResponse } from '../../../../common/api/LaravelPaginatedResponse';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import {ORIGIN_ENDPOINT, OrderOrigin} from "../modals/OrderOrigin";

@Component({
    selector: 'im-origin-table',
    templateUrl: './origin-table.component.html',
})
export class OriginTableComponent {
    data: Array<OrderOrigin> = [];
    meta: PaginationMetadata;
    endpoint = ORIGIN_ENDPOINT;
    @Input() showDelete = false;

    constructor(
        private element: ElementRef,
        private http: HttpClient,
    ) {
        this.loadPage = this.loadPage.bind(this);
    }

    loadPage(pageUrl: string) {
        this.http
            .get<LaravelPaginatedResponse<OrderOrigin>>(pageUrl)
            .pipe(catchError(err => emitLoadingError(this.element, err)))
            .subscribe((response: LaravelPaginatedResponse<OrderOrigin>) => {
                this.meta = new LaravelPaginationMetadata(response);
                this.data = response.data;
            });
    }
}
