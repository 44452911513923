import { FromDB } from '../../../common/api/FromDB';
import { Matrix } from './Matrix';

export interface Artikel {
  Artikelnummer: number;
  SortimentWgrNr: number;
  Ersatzartikel: number;
  Matchcode: string;
  Bezeichnung1: string;
  Bezeichnung2: string;
  Kurzbezeichnung: string;
  Stringbezeichnung: string;
  Verkaufseinheit: string;
  Verrechnungseinheit: string;
  ArtikelGruppe: string;
  BesorgDiversKz: number;
  Wiegeartikel: number;
  Saisonartikel: number;
  Saison1VonTag: number;
  Saison1VonMonat: number;
  Saison1BisTag: number;
  Saison1BisMonat: number;
  Saison2VonTag: number;
  Saison2onMonat: number;
  Saison2BisTag: number;
  Saison2BisMonat: number;
  SperrKz: number;
  SperreSeit: string;
  SperreGrund: string;
  MehrwertsteuerSchluessel: number;
  SozialbutterKz: number;
  WareKosten: string;
  ServiceBundEigenmarke: number;
  ServiceBundNationalArt: number;
  EigenproduktVomMitglied: number;
  Kundenordersatzartikel: number;
  RabattKz: number;
  Rabatt: number;
  Mindestverkaufsmenge: number;
  Hektoliter: number;
  Hauptlieferant: number;
  ArtikelNrHauptLieferant: string;
  Umschlaganzeige: number;
  FettKz: number;
  MHDKZ: number;
  MHDResttage: number;
  Preislistenkennzeichen: number;
  Tagespreisartikel: number;
  ServiceArtikel: number;
  DirektFrisch: string;
}

export type ArtikelWithNormierungseinheit = FromDB<Artikel> & {
  Normierungseinheit: FromDB<Matrix>;
};

/**
 * Zeigt beide Bezeichnungen des Artikels an.
 * Der Code hier ist ein wenig unschön, da beide Bezeichnungen null seien können.
 *
 * @param artikel
 */
export function displayBezeichnung(artikel: Artikel) {
  let bezeichnung = '';

  //  Artikel kann null sein wenn diese gelöscht wurde
  if (artikel === null) {
    return 'unbekannt';
  }

  if (artikel.Bezeichnung1) {
    bezeichnung += artikel.Bezeichnung1;
  }

  if (artikel.Bezeichnung1 && artikel.Bezeichnung2) {
    bezeichnung += ' ';
  }

  if (artikel.Bezeichnung2) {
    bezeichnung += artikel.Bezeichnung2;
  }

  return bezeichnung;
}
