import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, merge } from 'rxjs';
import { GlobalLoadingIndicatorService } from './global-loading-indicator.service';
import { tap } from 'rxjs/operators';

/**
 * Ein Interceptor, der für den globalen Ladebalken alle Requests und Responses
 * mit dem {@link GlobalLoadingIndicatorService} mitzählt.
 *
 * **Wichtig:** Er muss als **erster** Interceptor registriert werden, da er als
 * letzter Interceptor ausgeführt werden muss (Interceptors werden in
 * umgekehrter Registrierunsreihenfolge ausgeführt). Ansonsten werden nicht alle
 * Interceptors korrekt durchlaufen!
 */
@Injectable()
export class GlobalLoadingIndicatorInterceptor implements HttpInterceptor {
  constructor(private loading: GlobalLoadingIndicatorService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loading.startLoading();

    let didHandle = false;

    return merge(
      // Dieser merge ist nötig, da Requests abgebrochen werden können. Es wird
      // hier quasi ein Abräumcallback erstellt, der auf jeden Fall runterzählt,
      // wenn die Observable unsubscribed wurde.
      Observable.create((observer: any) => {
        return () => {
          if (!didHandle) {
            didHandle = true;
            this.loading.loadingFinished();
          }
        };
      }),

      next.handle(req).pipe(
        // Läuft die Request durch, egal ob  Error oder Success, einmal runterzählen,
        // allerdings nicht bei Fortschrittsevents
        tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (!didHandle) {
              didHandle = true;
              this.loading.loadingFinished();
            }
          }
        }),
      ),
    ) as any;
  }
}
