import { Component, ViewChild} from '@angular/core';
import { ApiClient } from '../../../../common/api/ApiClient';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import autobind from 'autobind-decorator';
import { DetailContainerComponent } from '../../../../common/layout/detail-container/detail-container.component';
import {OrderOrigin, ORIGIN_ENDPOINT} from "../modals/OrderOrigin";
import {BaseModel} from "../../../../common/api/BaseModel";

@Component({
  selector: 'im-origin-detail',
  templateUrl: './origin-detail.component.html',
})
export class OriginDetailComponent {
  public originBackup: OrderOrigin;
  public isEditable = false;
  public originEndpoint = ORIGIN_ENDPOINT;
  @ViewChild('detailContainer') detailContainer: DetailContainerComponent;
  showEditButtons: boolean = true;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiClient,
    private toastr: ToastrService,
  ) {}


  @autobind
  transformOrigin(origin: OrderOrigin & BaseModel) {
    if(origin.editable == false) {
      this.showEditButtons = false;
    }
    this.originBackup = JSON.parse(JSON.stringify(origin));
    return origin;
  }

  @autobind
  deleteOrigin() {
    if (confirm('Wollen Sie die Auftragsherkunft wirklich löschen?')) {
      this.api.destroy(ORIGIN_ENDPOINT, this.origin.Id).subscribe(
        () => {
          this.toastr.success('Auftragsherkunft erfolgreich gelöscht!');
          this.router.navigate(['../'], { relativeTo: this.route });
        },
      );
    }
  }

  isValid(value:string, isKey:boolean) :boolean {
    const re = new RegExp("^[0-9]*$");
    let valid: boolean;
    if(value!='') {
      valid = true;
      if(isKey==true) {
        if(re.test(value)) {
          valid = true;
        } else {
          valid = false;
        }
      }
    } else {
      valid = false;
    }
    return valid;
  }

  @autobind
  editOrigin() {
    this.isEditable = true;
  }

  @autobind
  abortEdit() {
    this.origin = JSON.parse(JSON.stringify(this.originBackup));
    this.isEditable = false;
  }

  @autobind
  saveOrigin() {
  if(this.isValid(this.origin.Key, true) && this.isValid(this.origin.Name, false)) {
  this.api
    .update(ORIGIN_ENDPOINT, this.origin.Id, {
      Key: this.origin.Key,
      Name: this.origin.Name,
    })
    .subscribe(response => {
        this.toastr.success('Bearbeiten erfolgreich!');
        this.isEditable = false;
        this.detailContainer.refresh();
      }, error => {
        this.toastr.error("Auftragsherkunft kann nicht gespeichert werden. Herkunfts-ID darf nicht doppelt vergeben werden", "Fehler beim Speichern");
      }
    );
  }
  }

  public get origin(): OrderOrigin {
    if (!this.detailContainer) {
      return null as any;
    }
    return this.detailContainer.entity;
  }

  public set origin(origin: OrderOrigin) {
    this.detailContainer.entity = origin;
  }
}
