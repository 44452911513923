import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { SearchComponent } from '../../../../common/forms/inputs/search/search-component';
import { ApiClient } from '../../../../common/api/ApiClient';

@Component({
  selector: 'im-kunde-search',
  template: `
    <input type="text" class="form-control"
      [(ngModel)]="value"
      [disabled]="disabled"
      [attr.disabled]="disabled ? true : null"
      (input)="changed($event)"
      [ngbTypeahead]="search"
      [resultFormatter]="formatter"
      [inputFormatter]="formatter"
      (selectItem)="onSelect($event)"
    />
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => KundeSearchComponent),
      multi: true,
    },
  ],
})
export class KundeSearchComponent extends SearchComponent {
  @Input() public endpoint = '';
  @Input() public disabled = false;
  @Input() public onSelect: (event: NgbTypeaheadSelectItemEvent) => void = () => {};
  @Input() public formatter: (result: any) => string = x => x;
  @Input()
  public set userId(val: string) {
    const vertreterFilterParam = 'filter-vertreter-by-user';
    if (val !== undefined) {
      this.queryParams[vertreterFilterParam] = val;
    } else if (this.queryParams.hasOwnProperty(vertreterFilterParam)) {
      delete this.queryParams[vertreterFilterParam];
    }
  }

  constructor(protected api: ApiClient) {
    super(api);
  }
}
