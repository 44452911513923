import { ControlValueAccessor } from '@angular/forms';
import { merge, Observable, of } from 'rxjs';
import { ApiClient } from '../../../api/ApiClient';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import autobind from 'autobind-decorator';

/**
 * Basis für ein Suchinputfeld was einen Endpunkt anspricht.
 *
 * Beispiel für ein Template:
 * ```
 * <input
 *    [(ngModel)]="value"
 *    (input)="changed($event)"
 *    [ngbTypeahead]="search"
 *    [resultFormatter]="formatter"                // Diese drei Funktionen
 *    [inputFormatter]="formatter"                 // sollte die erbende Klasse
 *    (selectItem)="onSelect($event)"              // bereitstellen
 * />
 * ```
 */
export abstract class SearchComponent implements ControlValueAccessor {
  abstract endpoint;

  protected isSearching = false;
  protected queryParamKey = 'filter-search';
  protected searchFailed = false;
  protected hideSearchingWhenUnsubscribed =
    new Observable(() => () => this.isSearching = false);

  public onChange: any;
  public value: any;

  public queryParams = {};

  constructor(protected api: ApiClient) { }

  @autobind
  sendQuery(term: string) {
    if (term === '') {
      return of([]);
    }

    this.onChange(term);

    this.queryParams[this.queryParamKey] = term;

    return this.api
      .get(this.endpoint, {
        params: this.queryParams,
        observe: 'response',
      })
      .pipe(
        map(response => {
          if (!response.body || !Array.isArray((response.body as any).data)) {
            return [];
          }

          const data = (response.body as any).data;

          if (data.length === 0) {
            return [
              'Es wurden keine zur Anfrage passenden Ergebnisse gefunden!',
            ];
          }

          return data;
        }),
      );
  }

  @autobind
  search(text$: Observable<string>) {
    return merge(
      text$.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => this.isSearching = true),
        switchMap(term =>
          this.sendQuery(term).pipe(
            tap(() => this.searchFailed = false),
            catchError(() => {
              this.searchFailed = true;
              return of([]);
            }),
          )),
        tap(() => this.isSearching = false),
      ),
      this.hideSearchingWhenUnsubscribed,
    );
  }

  @autobind
  changed(event: Event) {
    if (this.onChange &&
      typeof this.onChange === 'function') {
      this.onChange((event.target as HTMLInputElement).value);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(obj: any): void {
    this.value = obj;
    if (this.onChange && typeof this.onChange === 'function') {
      this.onChange(obj);
    }
  }
}
