import { Time } from 'ical.js';
import * as moment from 'moment';

export function createTime(date: Date, allDay: boolean): Time {
  return new Time({
    year: date.getFullYear(),
    month: date.getMonth() + 1, // Bei ICal.js beginnen die Monate bei 1
    day: date.getDate(),
    hour: date.getHours(),
    minute: date.getMinutes(),
    second: date.getSeconds(),
    isDate: allDay,
  });
}

/**
 * Konvertiert einen von Time.toICalString() erstellten String wieder
 * in ein Time Objekt.
 *
 * @returns {module:ical.js.Time}
 */
export function timeFromICalString(icalTime: string): Time {
  const date = moment(icalTime).toDate();
  const isDate = icalTime.length === 8;

  return createTime(date, isDate);
}
