import { DetailContainerComponent } from './detail-container.component';
import { BaseModel } from '../../api/BaseModel';
import autobind from 'autobind-decorator';
import { ApiClient } from '../../api/ApiClient';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';

export abstract class DetailComponentBase<T> {
  public abstract endpoint;
  protected redirectRoute = '../';

  public backup: T & BaseModel;
  public isEditable = false;
  abstract detailContainer: DetailContainerComponent;

  constructor(
    protected api: ApiClient,
    protected toastr: ToastrService,
    protected router: Router,
    protected route: ActivatedRoute,
  ) {
  }

  public saveEntity() {
  }

  @autobind
  public editEntity() {
    this.isEditable = true;
  }

  @autobind
  public transformEntity(entity: T & BaseModel) {
  }

  @autobind
  public editEntityAfterRetrieved(entity: T & BaseModel) {
    this.backup = JSON.parse(JSON.stringify(entity));
    this.transformEntity(entity);

    return entity;
  }

  @autobind
  public abortEdit() {
    this.entity = JSON.parse(JSON.stringify(this.backup));
    this.transformEntity(this.entity);

    this.isEditable = false;
  }

  @autobind
  public deleteEntity() {
    if (confirm('Wirklich löschen?')) {
      this.api.destroy(this.endpoint, this.entity.Id).subscribe(
        () => {
          this.toastr.success('Erfolgreich gelöscht!');
          this.router.navigate([this.redirectRoute], { relativeTo: this.route });
        },
      );
    }
  }

  public get entity(): T & BaseModel {
    if (!this.detailContainer) {
      return null as any;
    }

    return this.detailContainer.entity;
  }

  public set entity(message: T & BaseModel) {
    this.detailContainer.entity = message;
  }
}
