import { EMPTY, Observable, Subject, throwError as observableThrowError } from 'rxjs';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AuthService } from '../auth.service';
import { Injectable } from '@angular/core';
import { LogFactory } from '../../../../common/generic/LogFactory';
import { catchError, switchMap, tap } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';

let log;

/**
 * Interceptor für JSON-Web-Tokens. Ist ein Nutzer authentifiziert, wird automatisch der passende
 * Authorization-Header mitgeschickt. Ist ein Token ausgelaufen, so wird automatisch ein refresh_token
 * angefordert, gespeichert und die vorherige Anfrage wiederhohlt.
 */
@Injectable()
export class JWTInterceptor implements HttpInterceptor {
  // Um zu verhindern, dass 2 Refresh-Requests hintereinander abgeschickt werden
  isRefreshing = false;
  tokenRefreshedSource = new Subject();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  constructor(public auth: AuthService) {
  }

  addAuthIfNeeded(req: HttpRequest<any>) {
    if (this.auth.isAuthenticated) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${this.auth.currentToken.access_token}`,
        },
      });
    }

    return req;
  }

  tokenExpired(): boolean {
    try {
      // TODO: Prüfen ob das Object auf die Daten mitgibt
      const token = jwt_decode<{exp: bigint, iat: bigint}>(this.auth.currentToken.access_token);
      const current_time = Date.now().valueOf() / 1000;

      return token.exp !== null && current_time > token.exp;
    } catch (exception) {
      return true;
    }
  }

  isAuthRequest(res: HttpErrorResponse) {
    if (!res.url) {
      return false;
    }

    return res.url.endsWith('auth/login') || res.url.endsWith('auth/refresh');
  }

  private shouldTryToRefresh(res) {
    return res instanceof HttpErrorResponse
      && res.status === 401
      && !this.isAuthRequest(res);
  }

  refresh() {
    if (this.isRefreshing) {
      log('Warte darauf, dass sich das Token erneuert...');
      return new Observable(observer => {
        this.tokenRefreshed$.subscribe(() => {
          log('Token erneuert, starte neue Request!');
          observer.next(null);
          observer.complete();
        });
      });
    } else {
      this.isRefreshing = true;
      log('Token als abgelaufen erkannt, fordere neues Token an...');

      return this.auth.refresh()
        .pipe(
          tap(() => {
            this.isRefreshing = false;
            this.tokenRefreshedSource.next(null);
          }),
        );
    }
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    req = this.addAuthIfNeeded(req);

    return next.handle(req)
      .pipe(
        catchError(res => {
          if (this.shouldTryToRefresh(res)) {
            return this.refresh()
              .pipe(
                switchMap(() => {
                  req = this.addAuthIfNeeded(req);
                  return next.handle(req);
                }),
                catchError((error) => {
                  log('Fehler erkannt, logge aktuellen Nutzer aus...', error);
                  this.auth.logout();
                  return EMPTY;
                }),
              );
          }

          return observableThrowError(res);
        }),
      );
  }
}

log = LogFactory.create(JWTInterceptor);
