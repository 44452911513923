import { Component, ContentChild, Directive, Input, OnInit, TemplateRef } from '@angular/core';
import { AuthService } from '../../../im-modules/user/auth/auth.service';
import { User } from '../../../im-modules/user/users/User';

export interface NavRoute {
  label: string;
  path: string;
  routerLinkActiveOptions?: { exact: boolean };
  children?: Array<NavRoute>;
  canAccess?: (user: User) => boolean;
}

export type TopNavComponentConfig = Array<NavRoute>;

@Component({
  selector: 'im-top-nav-routes',
  template: `
    <div class="navbar-nav me-auto" *ngIf="auth.isAuthenticated" #routeList>
      <ng-container *ngFor="let route of _config">
        <im-top-nav-route *ngIf="showEntry(route)" [route]="route"></im-top-nav-route>
      </ng-container>
    </div>
  `,
})
export class TopNavRoutesComponent implements OnInit {
  /**
   * Die Routenkonfiguration, wird zum anzeigen der Routen benötigt!
   */
  @Input() config: TopNavComponentConfig = [];

  /**
   * Eine Version der Konfiguration, in der alle Pfade absolut sind.
   */
  _config: TopNavComponentConfig = [];

  constructor(public auth: AuthService) {
  }

  ngOnInit() {
    this._config = this.config.map(this.transformPathsToAbsolute());
  }

  /**
   * Ob der übergebene Eintrag angezeigt werden sollte, oder nicht. Wirkt sich auch auf alle Kinder aus!
   *
   * @param {NavRoute} entry
   * @returns {boolean}
   */
  showEntry(entry: NavRoute) {
    if (entry.canAccess) {
      return entry.canAccess(this.auth.user as User);
    }

    return true;
  }

  /**
   * Generiert eine Funktion, die zum Mappen von Pfaden rekursiv verwendet werden kann.
   *
   * @param parentPath
   */
  private transformPathsToAbsolute(parentPath = ''): (entry: NavRoute) => NavRoute {
    return (entry) => {
      const newPath = parentPath
        ? parentPath + '/' + entry.path
        : entry.path;

      const newChildren = Array.isArray(entry.children)
        ? entry.children.map(this.transformPathsToAbsolute(entry.path))
        : entry.children;

      const newEntry = {
        ...entry,
        path: newPath,
        children: newChildren,
        routerLinkActiveOptions: entry.routerLinkActiveOptions || { exact: false },
      };

      return newEntry;
    };
  }
}
