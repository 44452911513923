import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule as AngularCommonModule} from '@angular/common';
import {FileInputComponent} from './forms/inputs/file-input/file-input.component';
import {SelectableListComponent} from './forms/inputs/selectable-list/selectable-list.component';
import {AddableTextListComponent} from './forms/inputs/addable-text-list/addable-text-list.component';
import {MultipleChoiceListComponent} from './forms/inputs/multiple-choice-list/multiple-choice-list.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  FormGroupContainerComponent,
  FormGroupEditableDirective,
  FormGroupReadableDirective,
} from './forms/form-group-container/form-group-container.component';
import {FieldErrorDisplayComponent} from './forms/field-error-display/field-error-display.component';
import {DetailContainerComponent} from './layout/detail-container/detail-container.component';
import {TopNavRoutesComponent} from './layout/top-nav/top-nav-routes.component';
import {
  DataTableComponent,
  DataTableHeadersDirective,
  DataTableRowDirective,
} from './api/data-table/data-table.component';
import {GlobalLoadingIndicatorComponent} from './api/global-loading-indicator/global-loading-indicator.component';
import {LoadingErrorContainerComponent} from './api/loading-error-container/loading-error-container.component';
import {PaginatorComponent} from './api/paginator/paginator.component';
import {IconComponent} from './generic/icon/icon.component';
import {TopNavRouteComponent} from './layout/top-nav/top-nav-route.component';
import {RouterModule} from '@angular/router';
import {SBDatePipe} from './generic/SBDatePipe';
import {CheckableOptionComponent} from './forms/inputs/multiple-choice-list/checkable-option.component';
import {DetailButtonDirective} from './buttons/DetailButton';
import {DeleteButtonDirective} from './buttons/DeleteButton';
import {GlobalLoadingIndicatorService} from './api/global-loading-indicator/global-loading-indicator.service';
import {DataTableService} from './api/data-table/data-table.service';
import {ApiClient} from './api/ApiClient';
import {FilterBarComponent} from './api/filter-bar/filter-bar.component';
import {TreeViewComponent} from './layout/tree-view/tree-view.component';
import {TreeViewService} from './layout/tree-view/tree-view.service';
import {TopNavContainerComponent} from './layout/top-nav/top-nav-container.component';
import {TopNavCollapseComponent} from './layout/top-nav/top-nav-collapse.component';
import {SwitchComponent} from './forms/inputs/switch/switch.component';
import {TitleService} from './generic/title/title.service';
import {
  OverviewContainerComponent,
  OverviewContainerCreationFormDirective,
  OverviewContainerOverviewDirective,
} from './layout/overview-container/overview-container.component';
import {DisabledWhileLoadingDirective} from './buttons/disabled-while-loading.directive';
import {Nl2brPipe} from './nl2br.pipe';

const providers = [
  DataTableService,
  ApiClient,
  TreeViewService,
];

@NgModule({
  imports: [
    AngularCommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  declarations: [
    FileInputComponent,
    SelectableListComponent,
    AddableTextListComponent,
    MultipleChoiceListComponent,
    FormGroupContainerComponent,
    FieldErrorDisplayComponent,
    DetailContainerComponent,
    TopNavRoutesComponent,
    TopNavContainerComponent,
    TopNavRouteComponent,
    TopNavCollapseComponent,
    DataTableComponent,
    DataTableHeadersDirective,
    DataTableRowDirective,
    GlobalLoadingIndicatorComponent,
    LoadingErrorContainerComponent,
    PaginatorComponent,
    IconComponent,
    SBDatePipe,
    CheckableOptionComponent,
    DetailButtonDirective,
    DeleteButtonDirective,
    FormGroupReadableDirective,
    FormGroupEditableDirective,
    FilterBarComponent,
    TreeViewComponent,
    SwitchComponent,
    OverviewContainerComponent,
    OverviewContainerCreationFormDirective,
    OverviewContainerOverviewDirective,
    DisabledWhileLoadingDirective,
    Nl2brPipe
  ],
  exports: [
    FileInputComponent,
    SelectableListComponent,
    AddableTextListComponent,
    MultipleChoiceListComponent,
    FormGroupContainerComponent,
    FieldErrorDisplayComponent,
    DetailContainerComponent,
    TopNavRoutesComponent,
    TopNavContainerComponent,
    TopNavCollapseComponent,
    DataTableComponent,
    DataTableHeadersDirective,
    DataTableRowDirective,
    GlobalLoadingIndicatorComponent,
    LoadingErrorContainerComponent,
    PaginatorComponent,
    IconComponent,
    SBDatePipe,
    CheckableOptionComponent,
    DetailButtonDirective,
    DeleteButtonDirective,
    FormGroupReadableDirective,
    FormGroupEditableDirective,
    FilterBarComponent,
    TreeViewComponent,
    SwitchComponent,
    OverviewContainerCreationFormDirective,
    OverviewContainerOverviewDirective,
    OverviewContainerComponent,
    DisabledWhileLoadingDirective,
    Nl2brPipe,
  ],
  providers
})
export class CommonModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      providers: [
        GlobalLoadingIndicatorService,
        TitleService,
        ...providers,
      ],
      ngModule: CommonModule,
    };
  }
}
