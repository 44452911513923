import * as moment from 'moment';
import { Pipe, PipeTransform } from '@angular/core';
import {
  DATE_DB_FORMAT,
  DATE_DISPLAY_FORMAT,
  DATETIME_DISPLAY_FORMAT,
} from '../../config/dates';

@Pipe({ name: 'sbDate' })
export class SBDatePipe implements PipeTransform {
  transform(value: any = null, dayOnly: boolean = false): any {
    if (!value) {
      return '';
    }

    const m = moment(value, DATE_DB_FORMAT);

    if (dayOnly) {
      return m.format(DATE_DISPLAY_FORMAT);
    }

    return m.format(DATETIME_DISPLAY_FORMAT);
  }
}
