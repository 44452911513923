import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AUFTR_ENDPOINT, AuftrWithRelations } from '../../../models/Auftr';
import { ApiClient } from '../../../../../common/api/ApiClient';
import { ToastrService } from 'ngx-toastr';
import { FromDB } from '../../../../../common/api/FromDB';
import { AuftrPos, AuftrPosWithArtikel } from '../../../models/AuftrPos';
import { CustomerDetailService } from '../../customer-detail.service';
import { displayBezeichnung } from '../../../models/Artikel';
import { AuthService } from '../../../../../im-modules/user/auth/auth.service';
import { hasRole } from '../../../../../im-modules/user/users/User';
import { Roles } from '../../../../../im-modules/user/users/Role';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'sebu-customer-order-detail',
  template: `
    <ng-container *ngIf="order">
      <div *ngIf="isAdmin()" class="row">
        <div class="col-md-12">
          <sebu-order-status
            [order]="order"
          ></sebu-order-status>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <h4>Auftrag Nr. {{ order.NotepadAuftragsNr }}</h4>
          <sebu-customer-attribute label="Auftragsdatum">{{ order.Auftragsdatum | sbDate:true }}</sebu-customer-attribute>
          <sebu-customer-attribute label="Lieferdatum">{{ order.Lieferdatum | sbDate:true }}</sebu-customer-attribute>
        </div>
        <div class="col-md-6">
          <h4>Lieferadresse</h4>
          <sebu-customer-address *ngIf="order" [address]="address"></sebu-customer-address>
        </div>
      </div>

      <section class="mb-3 mt-3">
        <h4>Notizen</h4>
        <ul class="list-group" *ngIf="order?.Kommentare?.length > 0">
          <li class="list-group-item" *ngFor="let comment of comments">
            {{comment.ZeilenPos}}. {{ comment.Zusatztext }}
          </li>
        </ul>
      </section>

      <h4 class="mb-3">Artikel</h4>
      <div class="w-100 overflow-auto">
        <table class="table table-striped table-hover w-100">
          <thead>
          <tr>
            <th class="text-right">Artikel-Nr.</th>
            <th>Artikelbezeichnung</th>
            <th class="text-right">Preis</th>
            <th class="text-right">Menge</th>
            <th>VKE</th>
            <th class="text-right">Handpreis</th>
            <th class="text-right">HP-Rabatt</th>
            <th class="text-right">Rabatt</th>
            <th class="text-right">NP-Rabatt</th>
            <th class="text-right">Wert</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let position of positionen">
            <td class="text-right">{{ position?.Artikel?.Artikelnummer }}</td>
            <td>{{ displayBezeichnung(position?.Artikel) }}</td>
            <td class="text-right">{{ position?.Preis | currency:'EUR':'symbol' }}</td>
            <td class="text-right">{{ position?.Menge | number:'1.2-4' }}</td>
            <td>{{ position.Artikel?.Normierungseinheit?.Gebindetext }}</td>
            <td *ngIf="hasManuallySetPrice(position)" class="text-right">{{ position?.ManuellerPreis | currency:'EUR':'symbol'}}</td>
            <td *ngIf="!hasManuallySetPrice(position)" class="text-center">-</td>
            <td *ngIf="hasManuallySetPrice(position)" class="text-right">{{ calculateManualDiscount(position).toFixed(2) }} %</td>
            <td *ngIf="!hasManuallySetPrice(position)" class="text-center">-</td>
            <td class="text-right">{{ hasManuallySetPrice(position) || !positionHasDiscount(position) ? "0.00" : position?.Rabatt }} %</td>
            <td class="text-right">{{ isNaturalRabattPosition(position) ? 'Ja' : 'Nein' }}</td>
            <td class="text-right">{{ calculateValue(position) | currency:'EUR':'symbol' }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
  `,
})
export class CustomerOrderDetailComponent implements OnInit {
  endpoint = AUFTR_ENDPOINT;
  order: FromDB<AuftrWithRelations>;
  displayBezeichnung = displayBezeichnung;

  constructor(
    protected api: ApiClient,
    protected http: HttpClient,
    protected toastr: ToastrService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected customerService: CustomerDetailService,
    protected auth: AuthService,
  ) { }

  isAdmin() {
    return hasRole(this.auth.user, Roles.BACKEND_ADMIN)
      || hasRole(this.auth.user, Roles.SUPER_ADMIN);
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.fetchEntity(params.id);
    });
  }

  fetchEntity(id: string) {
    this.api
      .show<AuftrWithRelations>(this.endpoint, id)
      .subscribe(order => this.order = order);
  }

  /** Die Bestellinformationen zu jedem Artikel der Bestellung */
  get positionen(): FromDB<AuftrPosWithArtikel>[] {
    return this.order
      ? this.order.Positionen
      : [];
  }

  get address() {
    return this.order.LieferanschriftKz === 1
      ? this.order.Lieferadresse
      : this.customerService.customer;
  }

  get comments() {
    return this.order.Kommentare
      .sort((a, b) => {
        return a.ZeilenPos - b.ZeilenPos;
    });
  }

  /**
   * Berechnet den Wert der Position, also den Gesamtwert den der Kunde für die Artikel der Reihe bezahlt.
   *
   * @param position
   */
  calculateValue(position: FromDB<AuftrPosWithArtikel>) {
    return this.hasManuallySetPrice(position) ? position.Menge * parseFloat(position.ManuellerPreis)
      : position.Menge * this.calculatePriceWithOptionalDiscount(position);
  }

  /**
   * Berechnet den Preis pro Einheit, den der Kunde letztendlich bezahlt. Hier ist der Rabatt also schon einberechnet.
   *
   * @param position
   */
  calculatePriceWithOptionalDiscount(position: FromDB<AuftrPosWithArtikel>) {
    if (this.positionHasDiscount(position)) {
      return parseFloat(position.Preis) - (position.Rabatt / 100 * parseFloat(position.Preis));
    } else {
      return parseFloat(position.Preis);
    }
  }

  /**
   * Berechnet den Rabatt, den der Kunde pro Einheit erhält.
   *
   * @param position
   */
  calculatePriceDifference(position: FromDB<AuftrPosWithArtikel>) {
    return parseFloat(position.Preis) - this.calculatePriceWithOptionalDiscount(position);
  }

  /**
   * Schaut nach, ob das die Position Rabatt erhält. Hierfür wird das entsprechende Kennzeichen ausgewertet.
   * @param position
   */
  positionHasDiscount(position: FromDB<AuftrPos>) {
    /** Die Werte hier nicht mit dem RabattKZ aus den DisplayEnums verwechseln, sind andere */
    return position.RabattKz === 1 || position.RabattKz === 2;
  }

  /**
   * Gibt an, ob es sich bei der Position um eine Naturalrabattposition handelt.
   */
  isNaturalRabattPosition(position: AuftrPos) {
    return parseFloat(position.Preis) === 0 && position.Preisherkunft === 'N';
  }

  /**
   * Gibt an, ob bei der Position Manueller Preis gesetzt ist.
   * @param position
   */
  hasManuallySetPrice(position: AuftrPos) {
    if (!position) {
      return false;
    }
    return position.ManuellerPreis && parseFloat(position.ManuellerPreis) !== 0;
  }


  /**
   * Berechnet den handpreisrabatt anhand von Preis vs Manuellerpreis.
   * @param position
   * @return float
   */
  calculateManualDiscount(position: AuftrPos) {
    return 100 - (parseFloat(position.ManuellerPreis) * 100.0 / parseFloat(position.Preis));
  }
}
