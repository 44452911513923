<div>
  <h2>Kundeninfos</h2>
  <textarea class="w-100 form-control" rows="10" [formControl]="notice" maxLength="1000"></textarea>
  <p *ngIf="this.isEditable" [ngClass]="{'text-danger': !this.notice.valid}">{{ this.notice.value?.length}} / 1000 Zeichen</p>
  <button *ngIf="this.isEditable" class="btn btn-primary" (click)="saveCustomerInfo()">Speichern</button>
  <hr/>
  <div class="form-group d-flex justify-content-between align-items-center">
    <h3>Notizen</h3>
    <button class="btn btn-primary" type="submit" imDisabledWhileLoading (click)="createNote()">
      <im-icon name="plus"></im-icon>
      Erstellen
    </button>
  </div>
  <im-data-table #notesHistoryTable [endpoint]="notesHistoryEndpoint">
    <ng-template imDataTableHeaders>
      <th>Ersteller</th>
      <th>Datum</th>
      <th class="w-max">Inhalt</th>
      <th>Aktionen</th>
    </ng-template>

    <ng-template imDataTableRow let-row>
      <td>{{ row.creatorName }}</td>
      <td>{{ row.CreatedAt | date: 'dd.MM.yyyy HH:mm' }}</td>
      <td>{{ row.content }}</td>
      <td>
        <div class="d-flex">
          <button class="btn btn-primary me-2" type="submit" imDisabledWhileLoading (click)="editNote(row)">
            <im-icon name="edit"></im-icon>
          </button>
          <button class="btn btn-danger" type="submit" imDisabledWhileLoading (click)="deleteNote(row)">
            <im-icon name="trash"></im-icon>
          </button>
        </div>
      </td>
    </ng-template>
  </im-data-table>
  <sebu-customer-note-modal #noteModal
    [customerId]="customer.Id"
    [noteId]="selectedNote?.Id"
    [content]="selectedNote?.content ?? ''"
    (save)="onNoteSaved()">
  </sebu-customer-note-modal>
</div>
