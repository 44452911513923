<im-data-table [endpoint]="endpoint">
    <ng-template imDataTableHeaders>
        <th>Position</th>
        <th style="width:200px">Aktionen</th>
    </ng-template>

    <ng-template imDataTableRow let-row>
        <td>{{ row.position }}</td>
        <td>
            <div class="btn-group">
                <button imDetailButton [model]="row">Details</button>
                <button imDeleteButton [model]="row" [endpoint]="endpoint" [disabled]="row.position === CONTACT_POSITION_UNKNOWN">Löschen</button>
            </div>
        </td>
    </ng-template>
</im-data-table>
