<im-detail-container
 [save]="saveOrigin"
 [edit]="editOrigin"
 [abort]="abortEdit"
 [delete]="deleteOrigin"
 [isEditable]="isEditable"
 [transformEntity]="transformOrigin"
 [endpoint]="originEndpoint"
 [showEditButtons]="showEditButtons"
 #detailContainer
>
  <ng-template>
    <h1 class="card-title">
      Auftragsherkunft: {{originBackup?.Name}}
      <small>
        <br/>
        Erstellt am {{origin.CreatedAt | sbDate}}
      </small>
    </h1>

    <div class="form-group row">
      <label for="Key" class="col-sm-2 col-form-label">Herkunfts-ID</label>
      <div class="col-sm-10">
        <p class="form-control-plaintext" *ngIf="!isEditable">
          {{origin.Key}}
        </p>
        <input type="text" class="form-control" id="Key" *ngIf="isEditable" [(ngModel)]="origin.Key" />
        <im-field-error-display
          [displayError]="!isValid(origin.Key, true) && isEditable"
          errorMsg="Bitte einen gültigen Wert eintragen (Nur Nummern erlaubt)">
        </im-field-error-display>
      </div>
    </div>

    <div class="form-group row">
      <label for="Name" class="col-sm-2 col-form-label">Bezeichnung</label>
      <div class="col-sm-10">
        <p class="form-control-plaintext" *ngIf="!isEditable">
          {{origin.Name}}
        </p>
        <input type="text" class="form-control" id="Name" *ngIf="isEditable" [(ngModel)]="origin.Name" />
        <im-field-error-display
          [displayError]="!isValid(origin.Name, false) && isEditable"
          errorMsg="Bitte einen gültigen Namen eintragen">
        </im-field-error-display>
      </div>
    </div>

  </ng-template>
</im-detail-container>
