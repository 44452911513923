import {Component, OnInit} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup, ValidatorFn,
  Validators,
} from '@angular/forms';
import {BaseForm} from "../../../common/forms/base-form";
import {ApiClient} from '../../../common/api/ApiClient';
import {ToastrService} from "ngx-toastr";
import {HttpClient} from "@angular/common/http";
import {FileDownloadService} from "../../../common/api/file-download.service";

@Component({
  selector: 'sebu-order-comments',
  templateUrl: 'newCustomer-pdf-upload.html',
  styles: [],
})
export class NewCustomerPdfUploadComponent extends BaseForm implements OnInit {

  currentPDF: string;

  constructor(public api: ApiClient, private toastr: ToastrService, private http: HttpClient, private fileDownloader: FileDownloadService) {
    super();
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      pdfFile: new UntypedFormControl('', [Validators.required, this.pdfValidator()]),
    });
    this.http.get('/api/appsettings/pdftemplate', {responseType: 'text'})
      .subscribe(response => this.currentPDF = response);
  }

  onFileChange() {
    (this.pdfFile as AbstractControl).setValidators([Validators.required, this.pdfValidator()]);

  }

  download() {
    if (this.currentPDF != '') {
      return this.fileDownloader.download(`/blobs/raw/` + this.currentPDF);
    } else {
      this.toastr.warning('Noch keine Pdf Vorlage vorhanden');
    }
  }

  pdfValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value || control.value.length === 0) {
        return null;
      }
      const files = control.value as FileList;
      const file = files[0];
      const mimeTypePDF = file.type === 'application/pdf';
      const endsWithPDF = file.name.endsWith('.pdf');
      const couldBePDF = mimeTypePDF || endsWithPDF;

      return !couldBePDF ? {'pdf': 'Datei ist keine PDF-Datei!'} : null;
    };
  }

  public onSubmit() {
    super.onSubmit();
    if (this.form.valid) {
      const formData = new FormData();
      formData.append('blob', this.form.value.pdfFile[0]);
      return this.api.store('/appsettings/pdftemplate', formData).subscribe(ret => {
          this.toastr.success('Erfolgreich hochgeladen!');
          this.ngOnInit();
        }, error => {
          this.toastr.error(error.error, "Fehler beim Anlegen");
        }
      );
    } else {
      this.validateAllFormFields(this.form);
    }
  }

  public get pdfFile() {
    return this.form.get('pdfFile');
  }

}
