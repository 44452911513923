import { Injectable } from '@angular/core';
import { TreeBaseModel } from './TreeViewBaseModel';
import { TreeViewNode } from './TreeViewNode';

@Injectable({
  providedIn: 'root',
})
export class TreeViewService {
  /**
   * Erstellt aus dem übergebenen Array von Kategorien eine Baumstruktur,
   * sortiert diese nach der angegebenen `sortOrder` und gibt die erste
   * Ebene dieser Baumstruktur zurück.
   */
  public buildTree<T extends TreeBaseModel>(data: T[]) {
    // Kind, Vater Beziehungen finden
    const cloneWithRelations = data.map(this.mapRelations<T>(data));
    const rootNodes = cloneWithRelations.filter(node => !node.parent);

    // Temporären Wurzelknoten erstellen
    const root = new TreeViewNode<T>(true);
    root.children = rootNodes;

    // Mit Informationen zur Baumtiefe versehen
    root.children.forEach(this.applyLevelInformationRecursive());

    return root;
  }

  /**
   * Erstellt eine Map aus Id => Container.
   *
   * @param data
   */
  private getContainerMap<T extends TreeBaseModel>(data: Array<T>) {
    const containerMap = new Map<string, TreeViewNode<T>>();

    data.forEach(entry => {
      containerMap.set(entry.Id, new TreeViewNode<T>());
    });

    return containerMap;
  }

  /**
   * Gibt eine Funktion zurück, die einem Array von Kategorien Metainformationen zu den
   * Beziehungen im Baum gibt.
   */
  private mapRelations<T extends TreeBaseModel>(data: T[]) {
    const containerMap = this.getContainerMap<T>(data);

    return (item: T) => {
      const container = containerMap.get(item.Id) as TreeViewNode<T>;
      container.data = item;

      if (!item.parentId) {
        // category befindet sich auf root-Ebene, nur container zurückgeben
        return container;
      }

      // Item besitzt einen Parent
      const parent = containerMap.get(item.parentId) as TreeViewNode<T>;

      if (!parent) {
        // Das Item besitzt einen Parent, der nicht mit ausgeliefert wurde (ansonsten wäre dieser in der containerMap
        // definiert). Das Backend liefert also fehlerhafte Daten!
        console.warn(
          `[TreeViewService] Die Kategorie ${item.parentId} wurde als Oberkategorie von der ` +
          `Kategorie ${item.Id} angegeben. Diese Oberkategorie wurde allerdings nicht von der Schnittstelle ausgeliefert, ` +
          `daher ist die Anzeige hier unvollständig!`);
        return container;
      }

      // Relationen zuweisen
      container.parent = parent;
      parent.children.push(container);

      return container;
    };
  }

  /**
   * Annotiert die Nodes im Tree mit der Information, wie tief im Baum sie sich befinden.
   */
  private applyLevelInformationRecursive(level = 0) {
    return (node: TreeViewNode) => {
      node.level = level;

      if (node.children.length > 0) {
        node.children.forEach(this.applyLevelInformationRecursive(level + 1));
      }
    };
  }
}
