import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { CATEGORY_ENDPOINT } from '../../models/Category';
import { CategoriesOverviewService } from '../categories-overview/categories-overview.service';
import autobind from 'autobind-decorator';
import { CategoryFormComponent } from '../category-form/category-form.component';
import { ApiClient } from '../../../../common/api/ApiClient';

@Component({
  selector: 'im-create-category',
  templateUrl: './create-category.component.html',
})
export class CreateCategoryComponent {
  @Output()
  public categoryCreated = new EventEmitter();

  @ViewChild(CategoryFormComponent) form: CategoryFormComponent;

  constructor(
    private overview: CategoriesOverviewService,
    private api: ApiClient,
  ) {
  }

  @autobind
  submit(data: FormData) {
    this.api
      .store(CATEGORY_ENDPOINT, data)
      .subscribe(() => {
        this.form.onSuccessfulSubmit('Kategorie erfolgreich erstellt!');
        this.categoryCreated.emit();
        this.overview.refresh();
      });
  }
}
