import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from '../user/auth/auth.guard';
import { ProductDetailComponent } from './products/product-detail/product-detail.component';
import { ProductsComponent } from './products/products.component';
import { CategoriesComponent } from './categories/categories.component';
import { CategoryDetailsComponent } from './categories/category-details/category-details.component';
import { UpdateTitle } from '../../common/generic/title/title.service';

export const PATHS = {
  products: '',
  categories: 'categories',
};

export const TITLES = {
  categories: new UpdateTitle('Kategorien'),
  products: new UpdateTitle('Produkte'),
};

const routes: Routes = [
  {
    path: `${PATHS.categories}/:id`,
    component: CategoryDetailsComponent,
    canActivate: [AuthGuard],
    data: { title: TITLES.categories },
  },

  {
    path: `${PATHS.categories}`,
    component: CategoriesComponent,
    canActivate: [AuthGuard],
    data: { title: TITLES.categories },
  },

  {
    path: ':id',
    component: ProductDetailComponent,
    canActivate: [AuthGuard],
    data: { title: TITLES.products },
  },
  {
    path: PATHS.products,
    component: ProductsComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full',
    data: { title: TITLES.products },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PresentationRoutingModule {
}
