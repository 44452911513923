<div class="row" *ngIf="contact">
  <!-- Bild usw. -->
  <div class="col-md-4">
    <!-- Bild usw. -->
    <img src="assets/{{ avatar }}" height="350px" width="350px"
         alt="testing" class="avatar">

    <div class="form-group d-flex align-items-center">
      <label for="mainContact" class="me-4">Ansprechpartner</label>
      <im-switch [readOnly]="true" inputId="mainContact" [ngModel]="isMainContact"></im-switch>
    </div>

    <div class="form-group">
      <label for="salutation">Anrede</label>
      <span id="salutation" class="form-control-plaintext">{{ contact?.salutation?.salutation }}</span>
    </div>

    <div class="form-group">
      <label for="firstName">Vorname</label>
      <span id="firstName" class="form-control-plaintext">{{ contact?.firstName }}</span>
    </div>

    <div class="form-group">
      <label for="lastName">Nachname</label>
      <span id="lastName" class="form-control-plaintext">{{ contact?.lastName }}</span>
    </div>
  </div>

  <!-- Informationen -->
  <div class="col-md-4">
    <div class="form-group">
      <label for="mobilePhone">Mobil</label>
      <span id="mobilePhone" class="form-control-plaintext">{{ contact?.mobilePhone }}</span>
    </div>

    <div class="form-group">
      <label for="phone">Telefon</label>
      <span id="phone" class="form-control-plaintext">{{ contact?.phone }}</span>
    </div>

    <div class="form-group">
      <label for="fax">Fax</label>
      <span id="fax" class="form-control-plaintext">{{ contact?.fax }}</span>
    </div>

    <div class="form-group">
      <label for="email">E-Mail</label>
      <a id="email" class="form-control-plaintext" [href]="'mailto:' + contact?.email">{{ contact?.email }}</a>
    </div>

    <div class="form-group">
      <label for="position">Position</label>
      <span id="position" class="form-control-plaintext">{{ contact?.position?.position }}</span>
    </div>

    <div class="form-group">
      <label for="note">Kontaktnotiz</label>
      <p id="note" class="form-control-plaintext">{{ contact?.note }}</p>
    </div>
  </div>

</div>
