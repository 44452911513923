import { Component } from '@angular/core';
import { FileDownloadService } from '../../common/api/file-download.service';

@Component({
  selector: 'sebu-new-customers',
  template: `
      <h1 class="page-title">Neukunden</h1>

      <im-data-table [endpoint]="endpoint">
          <ng-template imDataTableHeaders>
              <th>Datum</th>
              <th>Name</th>
              <th>Vertreter</th>
              <th class="actions-column">Aktionen</th>
          </ng-template>

          <ng-template imDataTableRow let-row>
              <td>{{row.Datum | sbDate}}</td>
              <td>{{row.RechnungsanschriftName}}</td>
              <td>{{row.Vertreter}}</td>
              <td>
                  <div class="btn-group">
                      <button class="btn btn-outline-dark"
                              (click)="download(row.Id)">
                          <im-icon name="download"></im-icon>
                          Herunterladen
                      </button>
                    <button imDeleteButton [confirmationText]="'Wollen Sie den Neukunden '+ row.RechnungsanschriftName+' wirklich löschen?'" [model]="row" [endpoint]="endpoint">Löschen</button>
                  </div>
              </td>
          </ng-template>
      </im-data-table>
  `,
})
export class NewCustomersComponent {
  public endpoint = '/new-customers';

  constructor(private fileDownloader: FileDownloadService) {}

  download(id: string) {
    this.fileDownloader.download(`${this.endpoint}/pdfDownload/${id}`);
  }
}
