import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Injectable, isDevMode } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

interface LaravelValidationErrorResponse {
  /**
   * Jeweils pro Attribut eine Liste von Fehlernachrichten.
   */
  errors: { [attribute: string]: string[] };

  /**
   * Generische Fehlermeldung, dass die Daten nicht valide sind.
   */
  message: string;
}

/**
 * Ein Interceptor der im Falle einer fehlerhaften Anfrage eine Hinweisemeldung liefert.
 * Im devMode wird zudem die Nachricht vom Backend inklusive Stacktrace angezeigt, sodass
 * Fehler schnell identifiziert werden können.
 */
@Injectable()
export class AjaxErrorToastrInterceptor implements HttpInterceptor {
  /**
   * Array von Pfaden, für die **keine** Nachricht angezeigt werden soll.
   */
  private ignoredPaths: string[] = [];

  constructor(
    private toastr: ToastrService,
  ) {
  }

  public ignore(path: string) {
    this.ignoredPaths.push(path);
  }

  public unIgnore(path: string) {
    this.ignoredPaths = this.ignoredPaths.filter(ignoredPath => {
      return path.startsWith(ignoredPath);
    });
  }

  private formatErrorMessage(err: HttpErrorResponse) {
    if (!isDevMode()) {
      return '';
    }

    return `<pre>
      ${JSON.stringify(err.error, null, 2).replace('\\n', '<br>')}
    </pre>`;
  }

  private ignores(path: string) {
    return this.ignoredPaths.filter(ignoredPath => {
      return path.startsWith(ignoredPath);
    }).length > 0;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        catchError(err => {
          // Fehler bei der Authorisierung sollten automatisch aufgelöst werden
          // Gibt es einen 401er, so sollte der Nutzer erst gar nicht in der Lage sein, diese
          // Aktion durchzuführen, daher werden solche Fehler hier nicht behandelt (außer im devMode)!
          if (err instanceof HttpErrorResponse && (err.status !== 401 || isDevMode())) {
            let errorMessage = 'Bitte laden Sie die Seite neu oder versuchen Sie es später noch einmal.';
            const url = new URL(err.url || '');

            if (this.ignores(url.pathname)) {
              return throwError(err);
            }

            if (err.error.errors && err.error.message) {
              const validationErrors = err.error as LaravelValidationErrorResponse;

              errorMessage = `
                <div class="mt-3">
                  ${Object.entries(validationErrors.errors).map(([attribute, messages]) => `
                    <!-- ${attribute} -->
                    <ul class="list-group">
                      ${messages.map(message => `
                        <li class="list-group-item list-group-item-danger">
                          ${message}
                        </li>
                      `)}
                    </ul>
                  `).join('')}
                </div>
              `;
            }

            this.toastr.error(`
                ${errorMessage}
                <br>
                <small>${err.status} ${err.statusText}</small>
                ${this.formatErrorMessage(err)}
              `,
              `Fehler bei der Anfrage`,
              {
                enableHtml: true,
                disableTimeOut: true,
                closeButton: true,
                toastClass: isDevMode() ? 'toast toast-develop' : 'toast',
              },
            );
          }
          return throwError(err);
        }),
      );
  }
}
