import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';

/**
 * Ein Service zum anzeigen einer Benachrichtigung, falls
 * von einer zu Löschenden Entität noch weitere Entitäten abhängen.
 *
 * An dieser Stelle sollte eventuell eine Benachrichtigung auftauchen,
 * die den Nutzer darauf hinweist, dass noch X Entitäten von dieser
 * Entität abhängen, und sie daher nicht gelöscht werden kann.
 */
@Injectable({
  providedIn: 'root',
})
export class EntityDependantsService {
  constructor(
    protected toastr: ToastrService,
  ) {
  }

  /**
   * Zeigt einen Toast mit einer Nachricht an, da die Löschanfrage nicht
   * erfolgreich war, da noch Entitäten von der zu Löschenden abhängen.
   *
   * @param response
   * @param formatDependant
   * @param messageTitle
   */
  public notify<T = any>(
    response: HttpErrorResponse,
    formatDependant: (dependant: T) => string,
    messageTitle: string,
  ): void {
    const dependants = response.error.errors.dependants;

    const messageBody = dependants
      .slice(0, 10)
      .map(formatDependant).join(', ');

    this.toastr.error(
      messageBody,
      messageTitle,
      {
        enableHtml: true,
        disableTimeOut: true,
        closeButton: true
      },
    );
  }
}
