/**
 * Vergeleichsfunktion, um zwei Models miteinander auf Gleichheit zu überprüfen.
 * Hilfreich, um bei <select>-Elementen [compareWith] zu setzen, sodass auch
 * das richtige Element ausgewählt wird.
 *
 * @param a
 * @param b
 * @returns {boolean}
 */
export function identityComparator(a: any, b: any) {
  return a && b && a.Id === b.Id;
}
