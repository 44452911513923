import { Injectable } from '@angular/core';
import { ApiClient } from '../../../common/api/ApiClient';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { LogFactory } from '../../../common/generic/LogFactory';
import { of, throwError } from 'rxjs';
import { KeyedText } from './KeyedText';

export enum SebuKeyedTextsKey {
  IMPRINT = 'imprint',
  DATA_PROTECTION = 'dataprotection',
}

export const KEYED_TEXTS_ENDPOINT = '/keyvalue/keyedtexts';

let log;

@Injectable({
  providedIn: 'root',
})
export class KeyedTextsService {
  constructor(
    private api: ApiClient,
  ) { }

  public save(key: SebuKeyedTextsKey, text: string) {
    return this.api.update<KeyedText>(KEYED_TEXTS_ENDPOINT, key, { key, text });
  }

  public get(key: SebuKeyedTextsKey) {
    return this.api
      .show<KeyedText>(KEYED_TEXTS_ENDPOINT, key)
      .pipe(
        catchError(error => {
          if (error instanceof HttpErrorResponse && error.status === 404) {
            // Es wird angenommen, dass der Key noch nicht existiert, daher
            // einen leeren String verwenden
            log(`Für Key ${key} kam 404 zurück. Es wird ein leerer String verwendet...`);
            return of({
              key: key,
              text: '',
            });
          }

          return throwError(error);
        }),
      );
  }
}

log = LogFactory.create(KeyedTextsService);
