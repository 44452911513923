<div class="row">
    <div class="col-md-6">
      <h2>Termine</h2>
    </div>
    <div class="col-md-6" *ngIf="allUsers.length > 0">
        <div class="form-group row" *ngIf="showUserDropDown">
            <label for="user-selector"
                   class="col-sm-4 col-form-label text-right">
               Nutzer
            </label>
            <div class="col-sm-8">
                <select class="form-control form-select" id="user-selector"
                      (change)="onUserChanged(selectedUser)"
                      [disabled]="allUsers.length === 1"
                      [(ngModel)]="selectedUser"
                      [attr.disabled]="allUsers.length === 0 ? true : null">
                    <option *ngFor="let user of allUsers" [ngValue]="user">
                        {{displayUser(user)}}
                    </option>
                </select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-4 col-form-label  text-right">
                Zeitraum
            </label>
            <div class="col-sm-8">
                <p-calendar
                  selectionMode="range"
                  [(ngModel)]="selectedDates"
                  (onSelect)="onSelectedDayChange()"
                  [locale]="de" dateFormat="dd.mm.yy" class="ui-fluid"
                  showButtonBar="true"
                ></p-calendar>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!canDisplayOverview"
     class="w-100 text-muted text-center"
     style="user-select: none; margin-top: 1em"
>
  Bitte Zeitraum auswählen
</div>

<im-appointment-list
  *ngIf="canDisplayOverview"
  [user]="selectedUser"
  [from]="from"
  [to]="to"
  [customer]="customer"
></im-appointment-list>
