import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlobalLoadingIndicatorService {
  private loadingCounter = 0;
  public loading$ = new BehaviorSubject<boolean>(false);

  public startLoading() {
    this.updateLoadingState(() => this.loadingCounter++);
  }

  public loadingFinished() {
    this.updateLoadingState(() => this.loadingCounter--);
  }

  private updateLoadingState(action: () => any) {
    const oldLoadingState = this.isLoading;
    action();
    const newLoadingState = this.isLoading;

    if (newLoadingState !== oldLoadingState) {
      this.loading$.next(newLoadingState);
    }
  }

  private get isLoading() {
    return this.loadingCounter > 0;
  }
}
