import { ICalAppointment } from './ICalAppointment';
import { AppointmentType } from './AppointmentType';
import { AppointmentCategory } from './AppointmentCategory';
import { AppointmentContactPerson } from './AppointmentContactPerson';
import { User } from '../../user/users/User';
import { BaseModel } from '../../../common/api/BaseModel';
import { Customer } from '../../../sebu/customers/models/Customer';
import {PotentialCustomer} from "../../../sebu/customers/models/PotentialCustomer";

export interface Appointment {
  icalAppointment: ICalAppointment;
  appointmentType: AppointmentType & BaseModel;
  appointmentCategory: AppointmentCategory;
  appointmentContactPersons: AppointmentContactPerson[];
  user: User;
  customer: Customer |PotentialCustomer| string;
  customerId: string;
  customerName: string;
  SkipVisitReport: boolean;
  season: string | null;
}

export const APPOINTMENT_ENDPOINT = 'appointments';
