<div class="form-group">
  <label [for]="id">{{label}}</label>

  <ng-container *ngIf="editable">
    <ng-container *ngTemplateOutlet="editableTemplate; context: { $implicit: { id: id } }"></ng-container>
    <im-field-error-display
      [displayError]="isValid"
      [errorMsg]="errorMessage">
    </im-field-error-display>
  </ng-container>

  <ng-container *ngIf="!editable">
    <ng-container *ngTemplateOutlet="readableTemplate; context: { $implicit: { id: id } }"></ng-container>
  </ng-container>
</div>
