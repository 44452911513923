import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/sebu.module';
import { environment } from './environments/environment';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';

if (environment.production) {
  enableProdMode();
}

registerLocaleData(localeDe);

platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.error(err));
