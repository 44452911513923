import { FromDB } from '../../common/api/FromDB';
import { Category } from '../../im-modules/presentation/models/Category';
import { TreeViewNode } from '../../common/layout/tree-view/TreeViewNode';

/**
 * Sortiert den Baum ab dem angegebenen Knorten rekursiv nach der Sortorder.
 */
export function applySortOrderRecursive(node: TreeViewNode<FromDB<Category>>) {
  if (Array.isArray(node.children) && node.children.length > 0) {
    node.children = node.children.sort((a, b) => a.data.sortOrder - b.data.sortOrder);
    node.children.forEach(child => {
      applySortOrderRecursive(child);
    });
  }

  return node;
}
