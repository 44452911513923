import { BaseForm } from './base-form';
import { UntypedFormGroup } from '@angular/forms';
import { BaseModel } from '../api/BaseModel';
import { ToastrService } from 'ngx-toastr';
import { ApiClient } from '../api/ApiClient';
import { Injectable } from '@angular/core';

@Injectable()
export abstract class BaseEntityForm<T, V> extends BaseForm {
  /** @Input() */ public showSubmit = false;
  /** @Input() */ public isEditable = false;
  public entity: T | (BaseModel & T);
  protected endpoint: string;

  constructor(
    protected toastr: ToastrService,
    protected api: ApiClient,
  ) {
    super();
  }

  public onSuccessfulSubmit(message: string) {
    this.toastr.success(message);
    this.reset();
    window.scrollTo(0, 0);
  }

  abstract getDataToSubmit(): V;

  abstract submit(data: V);

  public onSubmit() {
    super.onSubmit();

    if (this.form.valid) {
      this.submit(this.getDataToSubmit());
    } else {
      this.validateAllFormFields(this.form);
    }
  }
}
