<div class="row justify-content-center loginComponnet">
  <div class="col-md-8">
    <div class="card">
      <div class="card-header">Anmelden</div>

      <div class="card-body">
        <form #loginForm="ngForm" (ngSubmit)="onSubmit()">
          <div class="form-group row">
            <label for="email" class="col-sm-4 col-form-label text-md-right">
              Vertreter-ID
            </label>

            <div class="col-md-6">
              <input id="email" type="text" class="form-control" name="email" [(ngModel)]="user.email" required autofocus>
            </div>
          </div>

          <div class="form-group row">
            <label for="password" class="col-md-4 col-form-label text-md-right">Passwort</label>

            <div class="col-md-6">
              <input id="password" type="password" class="form-control" name="password" [(ngModel)]="user.password" required>

            </div>
          </div>

          <div class="form-group row mb-0">
            <div class="col-md-8 offset-md-4">
              <button type="submit" class="btn btn-primary" [disabled]="!loginForm.form.valid">
                <im-icon name="sign-in"></im-icon>
                Anmelden
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
