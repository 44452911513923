import { Component } from '@angular/core';
import { SebuKeyedTextsKey } from './keyed-texts.service';

@Component({
  selector: 'sebu-data-protection',
  template: `
    <h2>Datenschutz</h2>

    <sebu-html-editor
      [key]="key"
    ></sebu-html-editor>
  `,
  styles: [],
})
export class DataProtectionComponent {
  key = SebuKeyedTextsKey.DATA_PROTECTION;
}
