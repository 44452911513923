import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as moment from 'moment';
import { DATE_QUERY_PARAM_FORMAT } from '../../../config/dates';

@Injectable({
  providedIn: 'root',
})
export class FilterBarService {
  /** Hier werden Serioalisierungsfunktionen gespeichert, die ein Objekt zu einem Query-Parameter serialisieren */
  private keySerializer: { [key: string]: (obj: any) => string } = {};
  private keyDeserializer: { [key: string]: (obj: string) => any } = {};
  private params: Params;

  constructor(private route: ActivatedRoute, private router: Router) {
    this.route.queryParams.subscribe(params => {
      this.params = params;
    });
  }

  /**
   * Registriert eine Serialisierungsfunktion für einen Key. Hilfreich, falls ein komplexes Objekt
   * in einen Query-Parameter umgewandelt werden soll.
   *
   * @param {string} key
   * @param {(obj: any) => string} serializer
   */
  public registerSerializerForKey(key: string, serializer: (obj: any) => string) {
    this.keySerializer[key] = serializer;
  }

  /**
   * Registriert eine Deserialisierungsfunktion für einen Key. Hilfreich, falls ein Objekt wie ein Datum
   * aus einem Query-Parameter geparsed werden soll.
   *
   * @param {string} key
   * @param {(obj: string) => any} deserializer
   */
  public registerDeserializerForKey(key: string, deserializer: (obj: string) => any) {
    this.keyDeserializer[key] = deserializer;
  }

  /**
   * Gibt alle Filterqueryparameter mit entferntem Prefix aus dem gegebenen Objekt zurück.
   */
  public get deserializedFilterParams() {
    return Object.keys(this.params).reduce((sanitized, key) => {
      if (!this.isFilterParam(key)) {
        // Kein Filterparameter, ignorieren
        return sanitized;
      }

      const deserializer = this.getDeserializer(this.removeFilterPrefix(key));
      sanitized[this.removeFilterPrefix(key)] = deserializer(this.params[key]);
      return sanitized;
    }, {});
  }

  /**
   * Teilt die aktuellen Filter Werte der DataTable mit, indem die Query-Parameter aktualisiert werden.
   */
  public propagateFilterChanges(values: { [key: string]: string }) {
    return this.router.navigate([], {
      queryParams: values,
    });
  }

  /**
   * Serialisiert die Werte der Filter zu einem Objekt, in dem als Werte nur
   * Strings stehen. Dazu werden Standardserialiserer verwenedt, es können via registerSerializerForKey
   * eigene Serialisierungsfunktionen verwendet werden.
   */
  public serializeFilterParams(values: { [key: string]: any }): { [key: string]: string } {
    const newFilterQueryParams = Object.keys(values).reduce((sanitized, key) => {
      const serializer = this.getSerializer(key);

      const serialized = serializer(values[key]);
      const valueIsValid = !(typeof serialized === 'undefined' || serialized === null || serialized === '');

      // Diese Abfrage ermöglicht ein Zurücksetzen der Filter
      if (valueIsValid) {
        sanitized[this.addFilterPrefix(key)] = serialized;
      }

      return sanitized;
    }, {});

    return {
      // Vorserst auskommentiert, da so parameter wie `page=9` gespeichert werden würden.
      // Vielleicht sollte man hier eine `ParameterReplacementStrategy` oder sowas erstellen, die angibt,
      // ob alle alten Parameter übernommen werden sollen, nur bestimmte oder gar keine?
      // this.getNonFilterParams(),
      ...newFilterQueryParams,
    };
  }

  private getSerializer(key: string) {
    return this.keySerializer.hasOwnProperty(key)
      ? this.keySerializer[key]
      : this.defaultSerializer;
  }

  private defaultSerializer(obj: any): string {
    if (!obj) {
      return ''; // wird von serializeFilterParams entfernt
    }

    if (typeof obj === 'object' && obj.hasOwnProperty('Id')) {
      return obj.Id;
    }

    if (obj instanceof Date || moment.isMoment(obj)) {
      return moment(obj).format(DATE_QUERY_PARAM_FORMAT);
    }

    return obj.toString();
  }

  private getDeserializer(key: string) {
    return this.keyDeserializer.hasOwnProperty(key)
      ? this.keyDeserializer[key]
      : x => x;
  }

  private getNonFilterParams() {
    return Object.keys(this.params).reduce((newParams, key) => {
      if (!this.isFilterParam(key)) {
        newParams[key] = this.params[key];
      }

      return newParams;
    }, {});
  }

  /**
   * Entfernt den Filterprefix vom Key.
   *
   * @param {string} key
   * @returns {string}
   */
  removeFilterPrefix(key: string) {
    return key.replace(this.filterPrefix, '');
  }

  /**
   * Fügt den Filterprefix vor den Key an.
   *
   * @param {string} key
   * @returns {string}
   */
  addFilterPrefix(key: string) {
    return this.filterPrefix + key;
  }

  /**
   * Ermittelt, ob der gegebene Key ein Filter-Query-Parameter ist.
   *
   * @param {string} key
   * @returns {"" | boolean}
   */
  public isFilterParam(key: string) {
    return key && key.startsWith(this.filterPrefix);
  }

  /**
   * Der Filterprefix, der für die Serialisierung und Deserialisierung der Werte der Filter aus den
   * Query-Parametern verwendet wird.
   *
   * @returns {string}
   */
  get filterPrefix() {
    return 'filter-';
  }
}
