<im-filter-bar [filterGroup]="filters" [wrap]="false">
  <label class="me-2" for="product-category-filter">Kategorie</label>
  <im-category-selector
    class="me-2" id="product-category-filter"
    [formControl]="filters.get('category')">
  </im-category-selector>

  <label class="me-2" for="product-name">Name</label>
  <input type="text" class="form-control" id="product-name" [formControl]="filters.get('product-name')">
</im-filter-bar>

<im-data-table [endpoint]="endpoint">
  <ng-template imDataTableHeaders>
    <th>Name</th>
    <th>Kategorie</th>
    <th class="actions-column">Aktionen</th>
  </ng-template>

  <ng-template imDataTableRow let-row>
    <td>
      {{ displayName(row) }}
      <im-new-product-badge [product]="row"></im-new-product-badge>
    </td>
    <td>{{ displayCategories(row) }}</td>
    <td>
      <div class="btn-group">
        <button imDetailButton [model]="row">Details</button>
        <button imDeleteButton [model]="row" [endpoint]="endpoint">Löschen</button>
      </div>
    </td>
  </ng-template>
</im-data-table>
