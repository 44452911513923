import { Component, HostBinding } from '@angular/core';
import { TitleService } from './common/generic/title/title.service';
import data from '../../package.json';
import { environment } from '../environments/environment';

@Component({
  selector: 'sebu-root',
  template: `
    <sebu-top-nav></sebu-top-nav>
    <div class="container app-container">
      <router-outlet></router-outlet>
    </div>
  `,
  styleUrls: ['./sebu.component.scss'],
})
export class AppComponent {
  constructor(titleService: TitleService) {
    titleService.startListening();
  }

  @HostBinding('attr.data-app-version')
  get version() {
    if (!data) {
      return 'unknown';
    }

    return (data as any).version;
  }

  @HostBinding('attr.data-commit')
  get commit() {
    return environment.commit;
  }
}
