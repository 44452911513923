import {Role, roleNames, Roles} from './Role';
import {USERS} from '../../../sebu.paths';
import {LogFactory} from '../../../common/generic/LogFactory';
import {BaseModel} from '../../../common/api/BaseModel';
import {Vertreter} from './models/Vertreter';
import {FromDB} from '../../../common/api/FromDB';
import {Customer} from '../../../sebu/customers/models/Customer';

const log = LogFactory.create('User');

export interface User extends BaseModel {
  name: string;
  email: string;
  password: string;
  newPassword: string;
  outlookPassword: string;
  newOutlookPassword: string;
  roles: Role[];
  vertreter: FromDB<Vertreter> | null | undefined;
  handleCustomers: [Customer] | [];
  handleRepresentatives: [Vertreter] | [];
  lastSync: Date;
  AppVersion: string;
  AppDataDeletion: boolean;
}

export const USER_ENDPOINT = '/users';

export function displayUser(user: User, showVertreterNummer = false) {
  if (!user) {
    log('displayUser: Es wurde kein Nutzer übergeben!');
    return '';
  }

  let userString = user.name;
  let userStringHasRoles = Array.isArray(user.roles) && user.roles.length > 0
  let userStringHasVertreterNummer = showVertreterNummer && user.vertreter?.Vertreternummer

  if (userStringHasRoles || userStringHasVertreterNummer) {
    userString += ' ('

    if (userStringHasRoles) {
      userString += user.roles.map(role => roleNames[role.name]).join(', ');
    }

    if (userStringHasVertreterNummer) {
      if (userStringHasRoles) {
        userString += ', '
      }
      userString += user.vertreter!.Vertreternummer;
    }

    userString += ')'
  }

  return userString;
}

export function getUserLink(user: User) {
  if (!user) {
    log('getUserLink: Es wurde kein Nutzer übergeben!');
    return '';
  }

  return `/${USERS}/${user.Id}`;
}

const rolesThatCanEdit = [
  'backend_admin',
  'super_admin',
];

export function canEdit(user: User | null) {
  if (!user) {
    return false;
  }

  for (const role of user.roles) {
    if (rolesThatCanEdit.indexOf(role.name) !== -1) {
      return true;
    }
  }

  return false;
}

export function hasRole(user: User | null, role: Roles) {
  return user !== null && Array.isArray(user.roles) &&
    user.roles.filter(r => r.name === role).length > 0;
}
