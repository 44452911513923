import {Injectable} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SortTableService {
  private filterIsDesc: boolean;
  private selectedSortField: string | null;
  private paramsWithoutSortData: Params;

  constructor(private route: ActivatedRoute, private router: Router) {
    this.route.queryParams.subscribe(params => {
      const { sort, ...restParams } = params;

      if (sort) {
        if (sort.startsWith('-')) {
          this.selectedSortField = sort.substring(1);
          this.filterIsDesc = true;
        } else {
          this.selectedSortField = sort;
          this.filterIsDesc = false;
        }
      } else {
        this.selectedSortField = null;
        this.filterIsDesc = false;
      }
      this.paramsWithoutSortData = restParams;
    });
  }

  sort(clickedColumn: string) {
    if (this.selectedSortField == clickedColumn) {
      this.filterIsDesc = !this.filterIsDesc;
    } else {
      this.filterIsDesc = false;
    }

    let newParams = {...this.paramsWithoutSortData};
    let sortingPrefix = this.filterIsDesc ? '-' : '';
    newParams.sort = sortingPrefix + clickedColumn;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: newParams,
      queryParamsHandling: 'merge'
    });
  }

}
