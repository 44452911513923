<div class="card" *ngIf="hasEntity">
  <div class="card-body">
    <ng-container *ngIf="hasEntity">
      <ng-container  *ngTemplateOutlet="detailTemplate"></ng-container>
    </ng-container>
  </div>
  <div class="card-footer d-flex">
    <div class="me-auto">
      <button class="btn btn-outline-secondary-transparent" *ngIf="!isEditable" (click)="goBack()">
        <im-icon name="arrow-left"></im-icon>
        Zurück
      </button>
      <button class="btn btn-outline-secondary-transparent" (click)="abort()" *ngIf="isEditable">
        <im-icon name="times"></im-icon>
        Abbrechen
      </button>
    </div>

    <div class="ms-auto" *ngIf="userIsAllowedToEdit && showEditButtons">
      <button class="btn btn-primary me-1" (click)="edit()" *ngIf="!isEditable">
        <im-icon name="pencil"></im-icon>
        Bearbeiten
      </button>
      <button class="btn btn-primary" (click)="save()" *ngIf="isEditable">
        <im-icon name="check"></im-icon>
        Speichern
      </button>
      <button class="btn btn-danger" (click)="delete()"
              *ngIf="(!isEditable && userIsAllowedToDelete) && !hideDeleteButton">
        <im-icon name="trash"></im-icon>
        Löschen
      </button>
    </div>
  </div>
</div>

<div class="alert alert-danger not-found-message" role="alert" *ngIf="notFound">
  <h4 class="alert-heading">Objekt nicht gefunden</h4>
  <p>
    Zu Ihrer Anfrage konnte kein passendes Objekt gefunden werden.
    Entweder existiert das gesuchte Objekt nicht oder es wurde bereits gelöscht.
  </p>
</div>
