import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {FrequencyLabels, NO_REPETITION, CUSTOM_REPETITION, Frequency} from '../../models/SupportedRepetitions';
import {NgbTypeaheadSelectItemEvent} from '@ng-bootstrap/ng-bootstrap';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {BaseForm} from '../../../../common/forms/base-form';
import {DATEPICKER_DE} from '../../../../config/locales';
import {AppointmentType} from '../../models/AppointmentType';
import {AugmentedAppointment} from '../../ical/i-cal-appointment.service';
import {AppointmentContactPerson} from '../../models/AppointmentContactPerson';
import autobind from 'autobind-decorator';
import * as moment from 'moment';
import { identityComparator } from '../../../../common/forms/identityComparator';
import { BaseModel } from '../../../../common/api/BaseModel';
import {
  Customer,
  CustomerWithCRMAnsprechpartner,
  linkToCustomer,
  showCustomerName,
  showCustomerNumber,
  showOrt,
} from '../../../../sebu/customers/models/Customer';
import {
  PotentialCustomer,
  PotentialCustomerWithCRMAnsprechpartner,
  showPotentialCustomerName, showPotentialCustomerOrt
} from "../../../../sebu/customers/models/PotentialCustomer";
import {OccurrenceDetails} from 'ical.js';
import {ActivatedRoute} from '@angular/router';
import {DATE_QUERY_PARAM_FORMAT} from '../../../../config/dates';
import {Contact} from '../../../../sebu/customers/models/Contact';
import {FromDB} from '../../../../common/api/FromDB';
import {SeasonHelper} from "../Helper/SeasonHelper";
import {AppointmentSeasons} from "../../enums/AppointmentSeasons";
import {ToastrService} from "ngx-toastr";

export enum ICalEventType {
  Instance = 'instance',
  Series = 'series',
}

@Component({
  selector: 'im-appointment-form',
  templateUrl: './appointment-form.component.html',
  styleUrls: ['./appointment-form.component.scss'],
})
export class AppointmentFormComponent extends BaseForm implements OnInit, OnChanges {
  @Input() editMode = ICalEventType.Series;
  @Input() isCreationForm = false;
  @Input() isEditable = false;
  @Input() appointment: AugmentedAppointment & BaseModel;
  @Input() appointmentTypes: Array<AppointmentType & BaseModel> = [];
  @Input() userId: string;
  @Input() isCustomer: boolean;
  @Output() isCustomerSelected = new EventEmitter<boolean>;
  /**
   * Möglicherweise verfügbare Information, zum stattfinden der Reihe und der Instany usw.
   * Da die Form auch zum Erstellen verwendet wird, können diese auch null sein!
   */
  @Input() occurrrenceDetails: OccurrenceDetails | null | undefined;
  identityComparator = identityComparator;
  showCustomerNumber = showCustomerNumber;
  linkToCustomer = linkToCustomer;
  editModes = ICalEventType;
  /**
   * Da der Wert von allDay nicht directiven wie *ngIf automatisch aktualisiert,
   * wird sich dieser Wert noch einmal gesondert hier gemerkt.
   */
  public _allDay = false;
  public de = DATEPICKER_DE;
  public frequencyLabels = FrequencyLabels;
  public repetitions = Object.entries(FrequencyLabels);
  protected readonly AppointmentSeasons = AppointmentSeasons;
  @Input() submit: () => void = () => {
  }
  // Vorherige Daten speichern, um Zeitraum verschieben zu können (SB-890)
  private oldStartDate: Date | null = null;
  private oldEndDate: Date | null = null;
  protected dateOfToday: Date;
  protected nextSeasonEnd: Date;

  /** @see updateMinMaxDates */
  minMaxDates = {
    timeRangeEnd: {
      min: null as null | Date,
    },
    repeatEnd: {
      min: null as null | Date,
      max: null as null | Date,
    },
  };

  constructor(
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private seasonHelper: SeasonHelper,
    protected toastr: ToastrService,
  ) {
    super();
  }

  radioButtonClicked() {
    if(this.isCustomer===true) {
    this.isCustomer = false;
    } else {
     this.isCustomer = true;
    }
    this.isCustomerSelected.emit(this.isCustomer)
  }

  ngOnInit() {
    this._allDay = this.appointment.allDay;
    this.dateOfToday = new Date()

    const contact = this.appointment.appointmentContactPersons
      ? this.appointment.appointmentContactPersons
      : [];

    const start = this.appointment.start || new Date();
    const end = this.appointment.end || moment().add(1, 'hours').toDate();

    this.form = new UntypedFormGroup({
      appointmentName: new UntypedFormControl(this.appointment.title, Validators.required),
      firm: new UntypedFormControl(this.appointment.customerName),
      contactPersons: new UntypedFormControl(contact),
      appointmentType: new UntypedFormControl(this.appointment.appointmentType),
      season: new UntypedFormControl(this.appointment.season === "" ? "" : this.appointment.season),
      repeats: new UntypedFormControl(this.appointment.repeats, Validators.required),
      appointmentLocation: new UntypedFormControl(this.appointment.location),
      timeRangeStart: new UntypedFormControl(start, Validators.required),
      timeRangeEnd: new UntypedFormControl(end, Validators.required),
      allDay: new UntypedFormControl(this._allDay),
    }, [
      this.startBeforeEndValidator(),
      this.maxAppointmentLengthValidator(),
    ]);

    if (this.appointment.isRecurring) {
      this.addRepeatEndFormControl();
    }

    if (this.isCreationForm) {
      this.route.queryParams.subscribe(params => {
        const day = params.day;

        if (day) {
          const newDate = moment(day, DATE_QUERY_PARAM_FORMAT);
          if (newDate.isValid()) {
            const newStart = moment();

            newStart.set({
              date: newDate.get('date'),
              month: newDate.get('month'),
              year: newDate.get('year'),
            });

            (this.timeRangeStart as AbstractControl).setValue(newStart.toDate());
            (this.timeRangeEnd as AbstractControl).setValue(newStart.clone().add(1, 'hour').toDate());
          }
        }

      });
    }

    this.form.valueChanges.subscribe(() => this.updateMinMaxDates());
    this.updateMinMaxDates();
    this.updateStartEnd();

    this.seasonHelper.getNextSeasonEnd().then(date => {
      this.nextSeasonEnd = date;
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updateStartEnd();
    if (changes.appointment && !changes.appointment.isFirstChange) {
      // Muss aufgrund von der Verwendung von Reactive Forms leider manuell
      // angepasst werden (glaube ich)
      const newAppointment: AugmentedAppointment & BaseModel = changes.appointment.currentValue;
      (this.contactPersons as AbstractControl)
        .setValue(newAppointment.appointmentContactPersons);
    }

    if (this.selectedEditMode(changes)) {
      this.form?.markAsUntouched()
      if (this.isRecurringInstance) {
        this.repeatEnd?.disable();
        this.firm?.disable();
        this.appointmentType?.disable()
        this.repeats?.disable()
        this.appointmentLocation?.disable()
        this.allDay?.disable()
        this.season?.disable()
      } else {
        this.repeatEnd?.enable();
        this.firm?.enable();
        this.appointmentType?.enable()
        this.repeats?.enable()
        this.appointmentLocation?.enable()
        this.allDay?.enable()
        this.season?.enable()
      }

      if (this.appointment.repeats === 'custom') {
        this.form.patchValue({repeats: 'custom'});
      }

    }
  }

  private selectedEditMode(changes: SimpleChanges) {
    return (changes.editMode && !changes.editMode.isFirstChange()) || changes.isEditable && !changes.isEditable.isFirstChange();
  }

  public displayKundeSearchResult(kunde: any) {
    if (typeof kunde === 'string') {
      // Fehlermeldung einfach anzeigen
      return kunde;
    }

    return showCustomerName(kunde as Customer);
  }

  public displayPotentialKundeSearchResult(kunde: any) {
    if (typeof kunde === 'string') {
      return kunde;
    }

    return showPotentialCustomerName(kunde as PotentialCustomer);
  }

  /**
   * Wählt automatisch die Kontakte für diesen Kunden als Ansprechpartner aus. So müssen diese nicht manuell hinzugefügt
   * werden.
   * @param event
   */
  @autobind
  public onKundeSelect(event: NgbTypeaheadSelectItemEvent) {
    if (typeof event.item === 'string') {
      event.preventDefault();
      return;
    }
    const kunde = event.item as CustomerWithCRMAnsprechpartner;
    this.contactPersons?.setValue(null);
    if (Array.isArray(kunde.AnsprechpartnerCRM) && kunde.AnsprechpartnerCRM.length > 0) {
      (this.contactPersons as AbstractControl).setValue(kunde.AnsprechpartnerCRM);
    }

    (this.appointmentLocation as AbstractControl).setValue(showOrt(kunde));
    (this.firm as AbstractControl).setValue(event.item);
  }

  @autobind
  public onPotentialCustomerSelect(event: NgbTypeaheadSelectItemEvent) {
    if (typeof event.item === 'string') {
      event.preventDefault();
      return;
    }
    const potentialCustomer = event.item as PotentialCustomerWithCRMAnsprechpartner;
    this.contactPersons?.setValue(null);
    (this.appointmentLocation as AbstractControl).setValue(showPotentialCustomerOrt(potentialCustomer));
    (this.firm as AbstractControl).setValue(event.item);
  }

  public displayContactPerson(contactPerson: AppointmentContactPerson | FromDB<Contact> | string) {
    if ((contactPerson as FromDB<Contact>).kundeId) {
      return (contactPerson as FromDB<Contact>).firstName + ' ' + (contactPerson as FromDB<Contact>).lastName;
    }

    return typeof contactPerson === 'string'
      ? contactPerson
      : (contactPerson as AppointmentContactPerson).contactPersonName;
  }

  @autobind
  public onRepeatChange() {
    if (this.isRepeating && (this.form.get('repeatEnd') === null)) {
      this.addRepeatEndFormControl();
    }

    if (!this.isRepeating && (this.form.get('repeatEnd') !== null)) {
      this.form.removeControl('repeatEnd');
      this.form.setValidators([
        this.startBeforeEndValidator(),
        this.maxAppointmentLengthValidator(),
      ]);
    }

    this.season?.setValue("");
  }

  @autobind
  public onCustomRepeatDaysChanged() {
    if (this.isCustomRepeating) {
      let inputValue = parseInt((<HTMLInputElement>document.getElementById('customRepeatDays'))?.value);
      if (!inputValue || inputValue < 2) {
        inputValue = 2; // als min value angegeben, bei negativen oder invaliden Werten setzen
      }
      if (inputValue && inputValue > 0) {
        // @ts-ignore
        document.getElementById('customRepeatDays').value = inputValue;
        this.cdr.detectChanges();
      }
    }
  }

  public addRepeatEndFormControl() {
    this.form.addControl('repeatEnd', new UntypedFormControl(
      {value: this.appointment.repeatEnd, disabled: this.isRecurringInstance},
      Validators.required,
    ));
    this.form.setValidators([
      this.startBeforeEndValidator(),
      this.startBeforeRepeatEndValidator(),
      this.recurrenceEndValidator(),
      this.maxAppointmentLengthValidator(),
    ]);
  }

  public onSubmit() {
    super.onSubmit();

    if (this.form.valid) {
      if (this.isCustomRepeating) {
        let inputValue = parseInt((<HTMLInputElement>document.getElementById('customRepeatDays'))?.value);
        this.form.patchValue({repeats: 'DAILY;INTERVAL=' + inputValue});
      }
      this.submit();
    } else {
      this.validateAllFormFields(this.form);
    }
  }

  public getFrequencyLabel(repeats) {
    let label = this.frequencyLabels[repeats];
    if (repeats === CUSTOM_REPETITION && this.appointment.repeatInterval) {
      label = label.replace(' X ', ' ' + this.appointment.repeatInterval.toString() + ' ');
    }
    return label;
  }

  startBeforeEndValidator(): ValidatorFn {
    return (group: UntypedFormGroup): { [key: string]: any } | null => {
      const start = group.controls['timeRangeStart'].value;
      const end = group.controls['timeRangeEnd'].value;
      const allDay = group.controls['allDay'].value;
      const granularity = allDay ? 'days' : 'minutes';

      const startBeforeEnd = moment(start).isSameOrBefore(end, granularity);

      return !startBeforeEnd ? {'startBeforeEnd': 'Startdatum ist nicht vor Enddatum'} : null;
    };
  }

  maxAppointmentLengthValidator(): ValidatorFn {
    return (group: UntypedFormGroup): { [key: string]: any } | null => {
      const start = group.controls['timeRangeStart'].value;
      const end = group.controls['timeRangeEnd'].value;
      const repeats = group.controls['repeats'].value;
      if (repeats === NO_REPETITION) {
        return null; // ok
      }
      let maxDays = 0; // 4 weeks
      switch (repeats) {
        case CUSTOM_REPETITION:
          maxDays = parseInt((<HTMLInputElement>document.getElementById('customRepeatDays'))?.value);
          break;
        case Frequency.DAILY:
          maxDays = 1;
          break;
        case Frequency.WEEKLY:
          maxDays = 7;
          break;
        case Frequency.BIWEEKLY:
          maxDays = 7*2;
          break;
        case Frequency.FOUR_WEEKLY:
          maxDays = 7*4;
          break;
      }

      const lengthExceeded = moment(end).diff(start, 'minutes') >= maxDays * 24 * 60;
      if (lengthExceeded) {
        let frequencyLabel = this.getFrequencyLabel(repeats);
        if (repeats === CUSTOM_REPETITION) {
          frequencyLabel = frequencyLabel.replace(' X ', ' ' + maxDays + ' ');
        }
        this.toastr.warning(
          'Ein Termin der sich '
                  + frequencyLabel + ' wiederholt, darf nicht länger als '
                  + maxDays + (maxDays === 1 ? ' Tag' : ' Tage')
                  + ' dauern.',
          'Hinweis',
          {
            enableHtml: true,
            disableTimeOut: true,
            closeButton: true
          },
        )
      }


      return lengthExceeded ? {'appointmentLengthExceeded': 'Maximaldauer überschritten'} : null;
    };
  }

  startBeforeRepeatEndValidator(): ValidatorFn {
    return (group: UntypedFormGroup): { [key: string]: any } | null => {
      const repeats = group.controls['repeats'] && group.controls['repeats'].value !== NO_REPETITION;

      if (!repeats) {
        return null;
      }

      const start = group.controls['timeRangeStart'].value;
      const repeatEnd = group.controls['repeatEnd'].value;
      const granularity = this._allDay ? 'days' : 'minutes';

      const startBeforeEnd = moment(start).isBefore(repeatEnd, granularity);

      if (!group.controls['repeatEnd'].dirty) {
        return null;
      }

      return !startBeforeEnd ? {'recurrenceEnd': 'Startdatum ist nicht vor Wiederholungsende'} : null;
    };
  }

  recurrenceEndValidator(): ValidatorFn {
    return (group: UntypedFormGroup): { [key: string]: any } | null => {
      if (!group.dirty || !this.recurrenceEndNeedsValidation(group)) {
        return null;
      }

      const recurrenceEndInput = group.get('repeatEnd');

      if (!recurrenceEndInput || recurrenceEndInput === null) {
        return {'recurrenceEnd': 'Das Input-Feld zum Wiederholungsende konnte nicht gefunden werden!'};
      } else if (!recurrenceEndInput.dirty) {
        return null;
      }

      const oneYearLater = moment().add(1, 'year');
      const recurrenceEnd = recurrenceEndInput.value as Date;

      return oneYearLater.isBefore(recurrenceEnd) ? {
        'recurrenceEnd': 'Das Enddatum von sich wiederholenden Terminen darf maximal ein Jahr in der Zukunft liegen!',
      } : null;
    };
  }

  /**
   * Stellt fest, ob das Wiederholungsenddatum des Terminformulars validiert werden muss
   * @param group
   */
  private recurrenceEndNeedsValidation(group: UntypedFormGroup) {
    const repeatsInput = group.get('repeats');

    if (repeatsInput === null) {
      // Es kann nicht festgestellt werden, ob validiert werden muss, daher lieber
      // sicher gehen
      return true;
    }

    return repeatsInput.value !== NO_REPETITION;
  }

  get appointmentLocationLink() {
    if (!this.appointment.geo) {
      return '';
    }

    const lat = this.appointment.geo[0];
    const lng = this.appointment.geo[1];

    return `https://www.google.com/maps/place/${lat},${lng}`;
  }

  get isEditingInstance() {
    return this.editMode === ICalEventType.Instance;
  }

  get isRepeating() {
    return this.isEditable
      ? this.form.controls['repeats'].value !== NO_REPETITION
      : this.appointment.repeats !== NO_REPETITION;
  }

  get isCustomRepeating() {
    return this.form.controls['repeats'].value == CUSTOM_REPETITION
  }

  get appointmentName(): AbstractControl {
    return this.form.get('appointmentName') as AbstractControl;
  }

  get firm() {
    return this.form.get('firm');
  }

  get contactPersons() {
    return this.form.get('contactPersons');
  }

  get appointmentType() {
    return this.form.get('appointmentType');
  }

  get repeats() {
    return this.form.get('repeats');
  }

  get season() {
    return this.form.get('season');
  }

  get allDay() {
    return this.form.get('allDay');
  }

  public get timeRangeStart() {
    return this.form.get('timeRangeStart');
  }

  public get timeRangeEnd() {
    return this.form.get('timeRangeEnd');
  }

  get appointmentLocation() {
    return this.form.get('appointmentLocation');
  }

  get repeatEnd() {
    return this.form.get('repeatEnd');
  }

  get startNotBeforeEnd() {
    return this.form.errors && this.form.errors.startBeforeEnd;
  }

  get appointmentLengthExceeded() {
    return this.form.errors && this.form.errors.appointmentLengthExceeded;
  }

  get repeatEndHasErrors() {
    return this.form.errors && this.form.errors.recurrenceEnd;
  }

  get safeStartDate() {
    return this.occurrrenceDetails
      ? this.occurrrenceDetails.startDate.toJSDate()
      : this.appointment.start;
  }

  get safeEndDate() {
    return this.occurrrenceDetails
      ? this.occurrrenceDetails.endDate.toJSDate()
      : this.appointment.end;
  }

  /**
   * Ändert sich der Start- oder Endzeitpunkt des Termins, so wird der jeweils andere Zeitpunk angepasst, sodass man
   * nicht ausversehen eine ungültige Zeitspanne auswählen kann.
   */
  onStartChange(value: any) {
    const start = (this.timeRangeStart as AbstractControl).value;
    const end = (this.timeRangeEnd as AbstractControl).value;

    // Jeweils anderes Input-Feld um Differenz verschieben
    if (start instanceof Date && end instanceof Date && this.timeRangeEnd !== null && this.oldStartDate !== null &&
      moment(start).isAfter(end)
    ) {
      const diff = start.getTime() - this.oldStartDate.getTime();
      this.oldEndDate = new Date(end.getTime() + diff);
      this.timeRangeEnd.setValue(this.oldEndDate);
    }

    this.oldStartDate = value;
  }

  /**
   * Ändert sich der Start- oder Endzeitpunkt des Termins, so wird der jeweils andere Zeitpunk angepasst, sodass man
   * nicht ausversehen eine ungültige Zeitspanne auswählen kann.
   */
  onEndChange(value: Date) {
    const start = (this.timeRangeStart as AbstractControl).value;
    const end = (this.timeRangeEnd as AbstractControl).value;

    // Jeweils anderes Input-Feld um Differenz verschieben
    if (start instanceof Date && end instanceof Date && this.timeRangeStart !== null && this.oldEndDate !== null &&
      moment(start).isAfter(end)
    ) {
      const diff = end.getTime() - this.oldEndDate.getTime();
      this.oldStartDate = new Date(start.getTime() + diff);
      this.timeRangeStart.setValue(this.oldStartDate);
    }

    this.oldEndDate = value;
  }

  /**
   * Passt das Start-, bzw Enddatum der Form an. Ist der aktuell angesehene Termin eine reguläre Instanz in der
   * Reihe, so können diese Zeitpunkte aus den occurrenceDetails ausgelesen werden. Da diese leider nicht einen
   * bearbeiteten Termin, also eine RecurrenceException, berücksichtigen, muss in diesem Fall auf die Termininstanz
   * des Tages zurückgegriffen werden. Diese befindet sich unter `occurrenceDetails.item`.
   */
  private updateStartEnd() {
    if (!(this.form && this.timeRangeStart && this.timeRangeEnd)) {
      return;
    }

    if (this.occurrrenceDetails) {
      if (this.editMode === ICalEventType.Instance) {
        // Der Nutzer editiert eine Termininstanz, also das Datum basiert auf den occurrenceDetails anpassen
        (this.timeRangeStart as AbstractControl).setValue(this.occurrrenceDetails.startDate.toJSDate());
        (this.timeRangeEnd as AbstractControl).setValue(this.occurrrenceDetails.endDate.toJSDate());
      } else if (this.editMode === ICalEventType.Series) {
        // Der Nutzer editiert eine Termininserie, also das Datum basiert auf den Seriendefinition anpassen
        (this.timeRangeStart as AbstractControl).setValue(this.occurrrenceDetails.item.startDate.toJSDate());
        (this.timeRangeEnd as AbstractControl).setValue(this.occurrrenceDetails.item.endDate.toJSDate());
      }
    }

    // Diese Werte sollten immer einmal initial gesetzt werden, da sonst die Berechnungen in
    // onEndChange und onStartChange nicht greifen!
    this.oldStartDate = this.timeRangeStart.value;
    this.oldEndDate = this.timeRangeEnd.value;
  }

  /**
   * Der PrimeNG-Kalender unterstützt minDate und maxDate-Properties zum einschränken des auswählbaren Datumszeitraumes.
   * Leider funktionieren hier keine get-Properties, da man dann (aus welchen Gründen auch immer) den Tag zwei mal
   * anclicken muss. Der erste Klick tut in diesem Fall leider nichts.
   * Daher wird ein Objekt gepflegt, welches in Variablen die auswählbaren Datumsbereiche der Felder Wiederholungsende
   * und Terminende verwaltet.
   */
  updateMinMaxDates() {
    this.minMaxDates.repeatEnd = {
      min: this.startOfDayOfTimeRangeStart,
      max: this.recurrenceEndNeedsValidation(this.form) ? this.oneYearAfterTimeRangeStart : null,
    };
    this.minMaxDates.timeRangeEnd = {
      min: this.startOfDayOfTimeRangeStart,
    };
  }

  get oneYearAfterTimeRangeStart(): Date | null {
    if (!this.form && this.timeRangeStart === null) {
      return null;
    }

    return moment(((this.timeRangeStart as AbstractControl).value as Date))
      .add(1, 'year')
      .toDate();
  }

  /**
   * Gilt sowohl für TimeRangeEnd als auch für RepeatEnd
   */
  get startOfDayOfTimeRangeStart(): Date | null {
    if (!this.form && this.timeRangeStart === null) {
      return null;
    }

    return moment((this.timeRangeStart as AbstractControl).value as Date)
      .startOf('day').toDate();
  }

  get isRecurringInstance(): boolean {
    return (this.editMode === this.editModes.Instance) && (this.appointment.isRecurring)
  }
}
