import {Component, OnInit, ViewChild} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup, ValidatorFn,
  Validators,
} from '@angular/forms';
import {BaseForm} from "../../../common/forms/base-form";
import {ApiClient} from '../../../common/api/ApiClient';
import {ToastrService} from "ngx-toastr";
import {HttpClient} from "@angular/common/http";
import {FileDownloadService} from "../../../common/api/file-download.service";
import {PROCESS_JOURNAL_ENDPOINT_INDEX_BY_NAME} from "../models/ProcessJournal";
import {
  UPLOAD_POTENTIALCUSTOMER_ENDPOINT,
  VALID_STATI_ENDPOINT
} from "../../customers/models/PotentialCustomer";
import {IconSize} from "../../../common/generic/icon/icon.component";
import {DataTableComponent} from "../../../common/api/data-table/data-table.component";

@Component({
  selector: 'sebu-order-comments',
  templateUrl: 'potential-customer-import.html',
  styles: [],
})
export class PotentialCustomerImportComponent extends BaseForm implements OnInit {

  // latestImports: ProcessJournal[] = [];
  historyEndpoint = PROCESS_JOURNAL_ENDPOINT_INDEX_BY_NAME + '/PotentialCustomerImporter';
  validStati: string[];

  @ViewChild('latestImportsTable') latestImportsTable: DataTableComponent;

  constructor(public api: ApiClient, private toastr: ToastrService, private http: HttpClient, private fileDownloader: FileDownloadService) {
    super();
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      csvFile: new UntypedFormControl('', [Validators.required, this.csvValidator()]),
    });
    this.api.get<string>(VALID_STATI_ENDPOINT, {
      observe: 'response',
    }).subscribe(response => {
      const data = response.body || []
      this.validStati = data as string[];
    });
  }

  onFileChange() {
    (this.csvFile as AbstractControl).setValidators([Validators.required, this.csvValidator()]);
  }

  csvValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value || control.value.length === 0) {
        return null;
      }
      const files = control.value as FileList;
      const file = files[0];
      const mimeTypeCsv = file.type === 'text/csv' || file.type === 'text/plain';
      const endsWithCsv = file.name.endsWith('.csv');
      const couldBeCsv = mimeTypeCsv || endsWithCsv;

      return !couldBeCsv ? {'csv': 'Datei ist keine CSV-Datei!'} : null;
    };
  }

  public onSubmit() {
    super.onSubmit();
    if (this.form.valid) {
      const formData = new FormData();
      formData.append('csv', this.form.value.csvFile[0]);
      return this.api.store(UPLOAD_POTENTIALCUSTOMER_ENDPOINT, formData).subscribe(ret => {
          this.toastr.success('Erfolgreich importiert!');
          this.form.reset();
          this.ngOnInit();
          this.latestImportsTable.loadPage();
        }, error => {
          this.toastr.error(error.error, "Fehler beim Hochladen!");
        }
      );
    } else {
      this.validateAllFormFields(this.form);
    }
  }

  public get csvFile() {
    return this.form.get('csvFile');
  }

  public get statiList(): string {
    return this.validStati?.map(status => `"${status}"`).join(', ') || 'Gültige Stati werden geladen...';
  }

  protected readonly IconSize = IconSize;
}
