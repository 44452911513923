<im-detail-container
    [edit]="editEntity"
    [delete]="deleteEntity"
    [hideDeleteButton]="isUnknownPosition"
    [showEditButtons]="! isUnknownPosition"
    [abort]="abortEdit"
    [save]="triggerSubmit"
    [transformEntity]="editEntityAfterRetrieved"
    [isEditable]="isEditable"
    [endpoint]="endpoint"
>
    <ng-template>
        <h1 class="modal-title">{{ entity.position }}</h1>

        <sebu-customer-contact-position-form *ngIf="isEditable" [entity]="entity" [send]="send">
        </sebu-customer-contact-position-form>
    </ng-template>
</im-detail-container>
