import {ElementRef} from '@angular/core';

declare const bootstrap: any;

/**
 * Basisklasse zum Erstellen von Bootstrap Modals, die ein wenig mehr Logik enthalten und daher
 * in eine eigene Klasse ausgelagert werden.
 */
export abstract class BsModalComponentBase {
  /** Hier sollte via @ViewChild eine Referenz auf das "root"-div gespeichert werden/ */
  abstract modalRoot: ElementRef;
  private boostrapModal: any;

  /**
   * Zeigt das Modal an.
   */
  show() {
    this.boostrapModal = new bootstrap.Modal(this.modalRoot.nativeElement);
    this.boostrapModal.show();
  }

  /**
   * Blendet das Modal wieder aus.
   */
  hide() {
    this.boostrapModal.hide();
  }
}
