import { Component } from '@angular/core';
import { Customer } from '../../../models/Customer';
import { DisplayEnums } from './enums';
import { CustomerDetailService } from '../../customer-detail.service';

/**
 * Ein Attribut des Kunden, welches ein numerischer Wert ist, zu dem ein
 * Beschreibungstext angezeigt werden kann.
 */
type customerEnumFieldKey = keyof typeof DisplayEnums & keyof Customer;

@Component({
  selector: 'sebu-customer-base-data',
  templateUrl: './customer-base-data.component.html',
  styleUrls: ['./customer-base-data.component.scss'],
})
export class CustomerBaseDataComponent {
  customer: Customer;

  constructor(detailService: CustomerDetailService) {
    this.customer = detailService.customer;
  }

  /**
   * Zeigt den Wert des "Enum"-Objekts an, und gibt eine
   * Fehlermeldung, falls der angegebene Key nicht existiert.
   * In diesem Fall wird einfach die Zahl und eine Hinweismeldung angezeigt.
   */
  safeDisplayEnumText(fieldKey: customerEnumFieldKey) {
    const enumObject = DisplayEnums[fieldKey];
    const enumKey = this.customer[fieldKey];

    if (!enumObject.hasOwnProperty(enumKey)) {
      return `Unbekannter Wert: ${enumKey}`;
    }

    return enumObject[enumKey];
  }
}
