import { Liefersperre } from './Liefersperre';
import { MengenstaffelKz } from './MengenstaffelKz';
import { BalmBerechtigung } from './BalmBerechtigung';
import { BonusKz } from './BonusKz';
import { ServisaKz } from './ServisaKz';
import { Mahnstufe } from './Mahnstufe';
import { SBNationalKZ } from './SBNationalKZ';
import { SkontoKz } from './SkontoKz';
import { Zahlungsart } from './Zahlungsart';
import { AuftragsbestaetigungPerFax } from './AuftragsbestaetigungPerFax';
import { Mehrwertsteuer } from './Mehrwertsteuer';
import { Kundenkennziffer } from './Kundenkennziffer';
import { PreisaufschlagKz } from './PreisaufschlagKz';
import { Sonderangebote } from './Sonderangebote';
import { Bestpreis } from './Bestpreis';
import { AlternativArtikel } from './AlternativArtikel';
import { Rueckstandsbildung } from './Rueckstandsbildung';
import { RabattKz } from './RabattKz';
import { Kontraktkunde } from './Kontraktkunde';
import { Ordersatz } from './Ordersatz';
import { Rechnungstermine } from './Rechnungstermine';

export const DisplayEnums = {
  MengenstaffelKz,
  Liefersperre,
  BalmBerechtigung,
  BonusKz,
  ServisaKz,
  Mahnstufe,
  SBNationalKZ,
  SkontoKz,
  Zahlungsart,
  AuftragsbestaetigungPerFax,
  Mehrwertsteuer,
  Kundenkennziffer,
  PreisaufschlagKz,
  Sonderangebote,
  Bestpreis,
  AlternativArtikel,
  Rueckstandsbildung,
  RabattKz,
  Kontraktkunde,
  Ordersatz,
  Rechnungstermine,
};
