import { AfterContentInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LogFactory } from '../../generic/LogFactory';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { FilterBarService } from './filter-bar.service';
import { Params } from '@angular/router';
import { DataTableService } from '../data-table/data-table.service';
import { Subscription } from 'rxjs';

let log;

/**
 * Eine Containerkomponente, die das serialisieren und deserialisieren von Query-Filter-Parametern verwaltet.
 * Genauere Dokumentation in der [README](./filter-bar.component.md).
 */
@Component({
  selector: 'im-filter-bar',
  template: `
      <form class="d-flex justify-content-between flex-wrap" (submit)="submit()" [formGroup]="form">
        <div class="d-flex flex-row align-items-center" [ngClass]="{'flex-wrap': wrap}">
          <ng-content></ng-content>
        </div>

          <button type="submit" class="btn btn-primary ms-auto" [disabled]="isLoading">
              <im-icon name="refresh"></im-icon>
              Aktualisieren
          </button>
      </form>
  `,
  styleUrls: ['./filter-bar.component.scss'],
})
export class FilterBarComponent implements AfterContentInit, OnInit, OnDestroy {
  @Input('filterGroup') public form: UntypedFormGroup;
  @Input('wrap') public wrap: boolean = true;
  private previousFilterQueryParams: Params;
  isLoading = true;
  private subs = new Subscription();

  constructor(
    private service: FilterBarService,
    private dataTable: DataTableService,
  ) {
  }

  ngOnInit() {
    this.subs.add(this.dataTable.loadingStartedEvent.subscribe(() => {
      this.isLoading = true;
    }));

    this.subs.add(this.dataTable.loadingFinishedEvent.subscribe(meta => {
      this.isLoading = false;
    }));
  }

  ngAfterContentInit() {
    this.applyQueryFilterParams();
    this.previousFilterQueryParams = this.service.deserializedFilterParams;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  /**
   * Liest aus den Query-Parametern die initialen Werte der Filter aus.
   */
  private applyQueryFilterParams() {
    const sanitizedParams = this.service.deserializedFilterParams;

    Object.keys(sanitizedParams).forEach(key => {
      const control = this.form.get(key);

      if (!control) {
        log(
          `Für ${key} existiert zwar ein Query-Parameter, aber kein Filter!`,
          sanitizedParams,
          Object.keys(this.form.controls),
        );
        return;
      }

      const value = sanitizedParams[key];

      log(`Setze Wert von Filter ${key}...`, value);
      (control as AbstractControl).setValue(value);
    });
  }

  submit() {
    const newFilterQueryParams = this.service.serializeFilterParams(this.form.value);

    if (this.filtersHaveChanged(newFilterQueryParams)) {
      this.service.propagateFilterChanges(newFilterQueryParams)
        .then(() => {
          this.previousFilterQueryParams = newFilterQueryParams;
        });
    } else {
      this.dataTable.reload();
    }
  }

  private filtersHaveChanged(newParams: { [key: string]: string }) {
    const oldParams = this.previousFilterQueryParams;
    const oldKeys = Object.keys(oldParams);
    const newKeys = Object.keys(newParams);

    if (oldKeys.length !== newKeys.length) {
      return true;
    }

    const diffOld = oldKeys.filter(key => !newParams.hasOwnProperty(key) || newParams[key] !== oldParams[key]);
    const diffNew = newKeys.filter(key => !oldParams.hasOwnProperty(key) || oldParams[key] !== newParams[key]);

    const oldHasDifferences = Object.keys(diffOld).length !== 0;
    const newHasDifferences = Object.keys(diffNew).length !== 0;

    return oldHasDifferences || newHasDifferences;
  }
}

log = LogFactory.create(FilterBarComponent);
