import { Category } from './Category';
import { BaseModel } from '../../../common/api/BaseModel';

export interface BaseItem {
  thumbnailId: string;
  name: string;
  creationDate: string;
  publishedAt: string | Date;
  endDate: string | Date | null;
}

export interface Item extends BaseItem {
  categories: Array<Category & BaseModel>;
}

export function displayCategories(item: Item) {
  return item.categories
    .map(category => category.name)
    .join(', ');
}

export const ITEM_ENDPOINT = '/presentation/items';
