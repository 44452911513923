import {BaseModel} from "../../../common/api/BaseModel";
import {UUID} from "../../../common/generic/UUID";

export interface Note extends BaseModel {
  customerId: UUID;
  creatorId: UUID;
  creatorName: string;
  content: string;
}

export const INDEX_BY_CUSTOMER_ENDPOINT = '/notes';
export const ENDPOINT = '/notes';

