import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs/internal/observable/empty';
import { HttpClient } from '@angular/common/http';
import { FromDB } from '../../../common/api/FromDB';
import { ApiClient } from '../../../common/api/ApiClient';
import { BLOB_ENDPOINT } from '../models/Blob';
import { LogFactory } from '../../../common/generic/LogFactory';

let log;

@Injectable({
  providedIn: 'root',
})
export class BlobService {
  constructor(private http: HttpClient) {
  }

  /**
   * Ruft die konkreten Daten des Blobs vom Endpunkt ab.
   *
   * @param {string | FromDB<Blob>} blob
   * @returns {Observable<Blob>}
   */
  public getRawAsBase64(blob: string | FromDB<Blob>) {
    const id = blob && typeof blob === 'object' && blob.Id
      ? blob.Id
      : blob;

    if (!id) {
      log('[getRawAsBase64] Keine id übergeben, stelle keine Request an die Api...');
      console.warn('BlobService[getRawAsBase64] Request abgebrochen, keine id übergeben!');
      return EMPTY;
    }

    return this.http.get(ApiClient.normalizeEndpoint(BLOB_ENDPOINT + '/raw/' + id), {
      headers: {
        accept: '*',
      },
      responseType: 'blob',
    });
  }
}

log = LogFactory.create(BlobService);
