<im-filter-bar [filterGroup]="filters">
  <label class="me-2" for="search" [title]="searchHint">
    Suche
  </label>
  <input class="form-control me-2" id="search" type="text" [formControl]="filters.get('search')" />
</im-filter-bar>

<div class="container" style="margin-bottom: 15px;">
  <div class="d-flex flex-row flex-row-reverse">
    <button (click)="downloadContactExport()" class="btn btn-link">
      <im-icon name="download"></im-icon>
      Kontakte exportieren
    </button>

    <button (click)="downloadCustomerExport()" class="btn btn-link">
      <im-icon name="download"></im-icon>
      Kunden exportieren
    </button>
  </div>
</div>

<im-data-table [endpoint]="endpoint">
  <ng-template imDataTableHeaders>
    <th>Kd.-Nr.</th>
    <th>Kunde</th>
    <th>Ansprechpartner</th>
    <th>Ort</th>
    <th>Kontaktdaten</th>
    <th class="actions-column">Aktionen</th>
  </ng-template>

  <ng-template imDataTableRow let-row>
    <td>{{showCustomerNumber(row)}}</td>
    <td class="oneliner">{{showCustomerName(row)}}</td>
    <td>{{showAnsprechPartner(row)}}</td>
    <td>{{showOrt(row)}}</td>
    <td>{{showContactInformation(row)}}</td>
    <td>
      <div class="btn-group">
        <button imDetailButton [model]="row">Details</button>
      </div>
    </td>
  </ng-template>
</im-data-table>
