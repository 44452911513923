import { Component, Input } from '@angular/core';
import { TreeViewNode } from '../../../../common/layout/tree-view/TreeViewNode';
import { Category } from '../../models/Category';
import { FromDB } from '../../../../common/api/FromDB';

@Component({
  selector: 'im-categories-overview-node',
  template: `
    <div class="d-flex justify-content-between align-items-center">
      <span>{{ node.data.sortOrder }}: {{ node.data.name }}</span>

      <button imDetailButton [model]="node.data">
        Details
      </button>
    </div>
  `,
})
export class CategoriesOverviewNodeComponent {
  @Input() node: TreeViewNode<FromDB<Category>>;
}
