import { Injectable } from '@angular/core';
import { Component, Event, parse, Time } from 'ical.js';
import { LogFactory } from '../../../common/generic/LogFactory';
import { ExpandedEvent } from '../models/ExpandedEvent';
import { timeFromICalString } from './utils';

let log;

export const HAS_RECURRENCE_ID_MAX_TRIES = 400;

/**
 * Stark inspiriert von https://www.npmjs.com/package/ical-expander.
 */
@Injectable({
  providedIn: 'root',
})
export class ICalExpanderService {
  /**
   * Überprüft, ob die recurrenceId zu einem Event der Terminreihe gehört.
   *
   * @param event
   * @param recurrenceId
   */
  public hasRecurrenceId(event: Event, recurrenceId: string) {
    const it = event.iterator();
    let tries = 0;
    let recurrence: Time;

    while (tries < HAS_RECURRENCE_ID_MAX_TRIES && (recurrence = it.next())) {
      tries++;

      if (recurrence.toICALString() === recurrenceId) {
        return true;
      }
    }

    return false;
  }

  /**
   * Gibt alle Events zwischen searchStart und searchEnd zurück.
   *
   * @param {string} ical
   * @param {Date} searchStart
   * @param {Date} searchEnd
   * @returns {module:ical.js.Event[]}
   */
  public between(ical: string, searchStart: Date, searchEnd: Date): ExpandedEvent[] {
    const calendar = this.parse(ical);
    // Alle Eventreihenbeschreibungen und Ausnahmen aus dem ICal
    const eventEntries = this.getEvents(calendar);
    // Seriendefinition des Termins, es *muss* genau eine geben
    const seriesDefinition = this.getSeriesDefinition(eventEntries);
    // Termine die verschoben wurden
    const exceptions = this.getSeriesExceptions(eventEntries);
    // Daten, an denen einzelne Termininstanzen gelöscht wurden
    const exDates = this.getExDates(seriesDefinition);

    // 1. Alle regulär vorkommenden Termine aus der Reihe im Intervall finden, exDates beachten
    const expansionStartTime = this.getExpansionStart(searchStart, seriesDefinition);
    const regularOccurrences = this.expandInterval(expansionStartTime, searchStart, searchEnd, seriesDefinition, exDates);

    // 2. Alle Termine mit RecurrenceId durchsuchen, ob sie in der Reihe vorkommen. Wird ein
    //    Termin mit einer RecurrenceId gefunden, der
    const correctOccurrences = this.overwriteRegularOccurencesWithExceptions(
      searchStart,
      searchEnd,
      regularOccurrences, // exDates werden schon beim expandieren beachtet.
      exceptions,
    );

    return Array.from(correctOccurrences.entries()).map(keyValueArray => {
      return {
        recurrence: timeFromICalString(keyValueArray[0]),
        event: keyValueArray[1],
      };
    });
  }

  private parse(ical: string): Component {
    return new Component(parse(ical));
  }

  public getEvents(calendar: Component): Event[] {
    return calendar
      .getAllSubcomponents('vevent')
      .map(vevent => new Event(vevent));
  }

  public getSeriesDefinition(eventDefinitions: Event[]): Event {
    return eventDefinitions.filter(event => !event.isRecurrenceException())[0];
  }

  public getSeriesExceptions(eventDefinitions: Event[]): Event[] {
    return eventDefinitions.filter(event => event.isRecurrenceException());
  }

  public getSeriesExceptionMap(eventDefinitions: Event[]): Map<string, Event> {
    return this.getSeriesExceptions(eventDefinitions)
      .reduce((exceptionMap, exception) => {
        exceptionMap.set(exception.recurrenceId.toICALString(), exception);
        return exceptionMap;
      }, new Map<string, Event>());
  }

  /**
   * Sucht zur gegebenen Seriendefinition alle Ausnahmen heraus.
   */
  public getExDates(seriesDefinition: Event) {
    const exDateProperties = seriesDefinition.component.getAllProperties('exdate');
    return new Set<string>(exDateProperties.reduce((exDates, property) => {
      property.getValues().forEach((val: Time) => {
        exDates.push(val.toICALString());
      });

      return exDates;
    }, <Array<string>>[]));
  }

  /**
   * Berechnet das Startdatum, von dem aus der Iterator iterieren soll, um alle regulären Instanzen
   * der Termine im Intervall zu erhalten.
   *
   * @param {Date} searchStart
   * @param {module:ical.js.Event} seriesDefinition
   * @returns {module:ical.js.Time}
   */
  private getExpansionStart(searchStart: Date, seriesDefinition: Event): Time {
    return seriesDefinition.startDate; // TODO
  }

  /**
   * Löst alle Termininstanzen im Suchintervall auf, die regulär vorkommen.
   * Hierbei werden bereits Instanzen, die auf ein EXDATE fallen, herausgefiltert.
   *
   * @param {module:ical.js.Time} expansionStartTime
   * @param {Date} searchStart
   * @param {Date} searchEnd
   * @param {module:ical.js.Event} seriesDefinition
   * @param {Set<module:ical.js.Time>} exDates
   * @returns {Map<module:ical.js.Time, module:ical.js.Event>}
   */
  private expandInterval(
    expansionStartTime: Time,
    searchStart: Date, searchEnd:
    Date, seriesDefinition: Event,
    exDates: Set<string>,
  ): Map<string, Event> {
    const regularOccurences = new Map<string, Event>();
    const it = seriesDefinition.iterator(expansionStartTime);
    let occurrence: Time;
    let occurrenceDate: Date;
    let safetyCounter = 400;
    const eventDuration = seriesDefinition.endDate.toJSDate().getTime() - seriesDefinition.startDate.toJSDate().getTime();

    while (safetyCounter-- > 0 && (occurrence = it.next()) && (occurrenceDate = occurrence.toJSDate()) < searchEnd) {
      log(`Überprüfe Instanz ${occurrenceDate}...`);

      if (exDates.has(occurrence.toICALString())) {
        continue;
      }

      const occurrenceStart = occurrenceDate;
      const occurrenceEnd = new Date(occurrenceStart.getTime() + eventDuration);

      if (this.intervalsOverlap(searchStart, searchEnd, occurrenceStart, occurrenceEnd)) {
        regularOccurences.set(occurrence.toICALString(), seriesDefinition);
      }
    }

    return regularOccurences;
  }

  /**
   * Überprüft, ob sich die beiden Intervalle A und B überlappen.
   *
   * @see https://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap
   * @param {Date} aStart
   * @param {Date} aEnd
   * @param {Date} bStart
   * @param {Date} bEnd
   */
  private intervalsOverlap(aStart: Date, aEnd: Date, bStart: Date, bEnd: Date) {
    return aStart.getTime() < bEnd.getTime() && aEnd >= bStart;
  }

  /**
   * Da sich in regularOccurences noch Termininstanzen befinden können, die eigentlich bearbeitet
   * wurden, wird hier noch einmal über die Ausnahmendefinitionen im Kalender iteriert und eventuelle
   * bearbeitete Termine aus der bisherigen Ergebnismenge angepasst oder neu hinzugefügt.
   *
   * Wird in den Ausnahmendefinitionen eine Recurrence-Id gefunden, die bereits in regularOccurences
   * enthalten ist, so muss die dort befindliche Seriendefinition definitiv gelöscht werden.
   * Liegt die Ausnahme / der bearbeitete Termin trotz Bearbeitung im Intervall, so kann er erneut
   * der Ergebnismenge hinzugefügt werden.
   *
   * Wird eine Recurrence-Id gefunden, die noch nicht
   *
   * @param searchStart
   * @param searchEnd
   * @param {Map<string, module:ical.js.Event>} regularOccurences
   * @param {module:ical.js.Event[]} exceptions
   * @returns {Map<string, module:ical.js.Event>}
   */
  private overwriteRegularOccurencesWithExceptions(
    searchStart: Date,
    searchEnd: Date,
    regularOccurences: Map<string, Event>,
    exceptions: Event[],
  ) {
    const occurrences = new Map<string, Event>(regularOccurences);
    const recurrenceIdsInInterval = new Set(regularOccurences.keys());

    exceptions.forEach(recurrenceException => {
      const exceptionRecurrenceId = recurrenceException.recurrenceId.toICALString();
      const exceptionStart = recurrenceException.startDate.toJSDate();
      const exceptionEnd = recurrenceException.endDate.toJSDate();

      // Prüfen, ob die Ausnahme im Such-Intervall liegt
      if (this.intervalsOverlap(
        searchStart, searchEnd,
        exceptionStart, exceptionEnd,
      )) {
        // Wenn die Ausnahme im Suchintervall liegt, muss sie in die Ergebnismenge mit aufgenommen werden.
        occurrences.set(exceptionRecurrenceId, recurrenceException);

      } else if (recurrenceIdsInInterval.has(exceptionRecurrenceId)) {
        // Wenn eine Instanz im Intervall als Ausnahme aus dem Intervall rausgeschoben wurde, muss sie aus den
        // Ergebnissen entfernt werden.
        occurrences.delete(exceptionRecurrenceId);
      }
    });

    return occurrences;
  }
}

log = LogFactory.create(ICalExpanderService);
