import { Component, ElementRef, Injector, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractEntityFilter } from '../../../../common/api/filter-bar/common-filters/AbstractEntityFilter';
import { Category, CATEGORY_ENDPOINT } from '../../models/Category';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FromDB } from '../../../../common/api/FromDB';
import { TreeViewService } from '../../../../common/layout/tree-view/tree-view.service';
import { TreeViewNode } from '../../../../common/layout/tree-view/TreeViewNode';
import { Subscription } from 'rxjs';
import { applySortOrderRecursive } from '../../../../sebu/utils/TreeUtils';

@Component({
  selector: 'im-category-selector',
  template: `
    <select #categorySelector
            (focus)="onFocus()"
            (change)="onChange(selectedEntity)"
            (blur)="onBlur(selectedEntity)"
            [(ngModel)]="selectedEntity"
            [compareWith]="identityComparator"
            class="form-control form-select" [id]="id"
            [ngClass]="childClasses">
      <option></option>
      <option *ngFor="let categoryNode of entitiesAsTree" [ngValue]="categoryNode.data" #option>
        <ng-container *ngIf="isFocused">
          {{ '&nbsp;&nbsp;'.repeat(categoryNode.level) }}
        </ng-container>
        {{ categoryNode.data.sortOrder }}:&nbsp;
        {{ categoryNode.data.name }}
      </option>
    </select>
  `,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: CategorySelectorComponent,
    multi: true,
  }],
})
export class CategorySelectorComponent extends AbstractEntityFilter<Category> implements OnInit, OnDestroy {
  endpoint = CATEGORY_ENDPOINT;
  @ViewChild('categorySelector') selectRef: ElementRef<HTMLSelectElement>;
  @Input() id;
  @Input() additionalEndpointParams = {};
  @Input() childClasses;
  entitiesAsTree: TreeViewNode<FromDB<Category>>[] = [];
  /** Steuert, ob die Option-Tags eingerückt werden, oder nicht */
  isFocused = false;
  private subs = new Subscription();

  constructor(
    protected injector: Injector,
    private tree: TreeViewService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    super.init();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  protected onEntitiesLoaded(entities: FromDB<Category>[]) {
    this.entitiesAsTree = this.applyTreeLayout(entities);
    super.onEntitiesLoaded(entities);
  }

  private applyTreeLayout(entities: FromDB<Category>[]) {
    const entitiesAsTree = applySortOrderRecursive(this.tree.buildTree(entities));
    const flattenedTree: TreeViewNode<FromDB<Category>>[] = [];
    const queue: TreeViewNode[] = [];

    queue.push(...entitiesAsTree.children);

    while (queue.length > 0) {
      const child = queue.shift() as TreeViewNode<FromDB<Category>>;

      queue.unshift(...child.children);

      flattenedTree.push(child);
    }

    return flattenedTree;
  }

  // Diese Methoden steuern isFocused, sodass die Einrückung immer passend angezeigt wird

  public onFocus() {
    this.isFocused = true;
  }

  public onBlur(obj: any) {
    this.isFocused = false;
    this.onTouched(obj);
  }
}
