<h1 class="page-title">{{ title }}</h1>

<ul class="list-group">
  <li class="list-group-item" *ngFor="let setting of settings">
    <h5 class="mb-1 text-monospace">
      {{ setting.key }}
    </h5>
    <p class="mb-2">{{ setting.description }}</p>
    <im-dynamic-setting-factory (settingChanged)="updateSetting($event)"
                                [setting]="setting"></im-dynamic-setting-factory>
  </li>
</ul>
