import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../im-modules/user/auth/auth.guard';
import { SortimenteComponent } from './sortimente/sortimente.component';
import { SortimentDetailComponent } from './sortimente/sortiment-detail.component';
import { ArticleSuggestionsComponent } from './suggestions/suggestions.component';
import { UpdateTitle } from '../../common/generic/title/title.service';
import { AdditionalInformationComponent } from './additional-information/additional-information.component';

export const PATHS = {
  'warengruppen': 'warengruppen',
  'zusatzinformationen': 'zusatzinformationen',
  'artikelvorschläge': 'article_suggestions',
};

export const TITLES = {
  sortimente: new UpdateTitle('Warengruppen'),
  zusatzinformationen: new UpdateTitle('Zusatzinformationen'),
  artikelvorschläge: new UpdateTitle('Artikelvorschläge'),
};

const routes: Routes = [
  {
    path: PATHS['warengruppen'] + '/:id',
    component: SortimentDetailComponent,
    canActivate: [AuthGuard],
    data: { title: TITLES.sortimente },
  },
  {
    path: PATHS['warengruppen'],
    component: SortimenteComponent,
    canActivate: [AuthGuard],
    data: { title: TITLES.sortimente },
  },
  {
    path: PATHS['zusatzinformationen'],
    component: AdditionalInformationComponent,
    canActivate: [AuthGuard],
    data: { title: TITLES.zusatzinformationen },
  },
  {
    path: PATHS['artikelvorschläge'],
    component: ArticleSuggestionsComponent,
    canActivate: [AuthGuard],
    data: { title: TITLES.artikelvorschläge },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ArticlesRoutingModule { }
