import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from '../../im-modules/user/auth/auth.guard';
import { CustomersComponent } from './customers/customers.component';
import { CustomerDetailComponent } from './customers/customer-detail/customer-detail.component';
import { CustomerContactsComponent } from './customers/customer-detail/customer-contacts/customer-contacts.component';
import { CustomerOrderHistoryComponent } from './customers/customer-detail/customer-order-history/customer-order-history.component';
import { CustomerDetailResolverService } from './customers/customer-detail-resolver.service';
import { CustomerOrderDetailComponent } from './customers/customer-detail/customer-order-history/customer-order-detail.component';
import { CustomerContactPositionsComponent } from './customer-contact-position/customer-contact-positions.component';
import { UpdateTitle } from '../../common/generic/title/title.service';
import { CustomerAppointmentsComponent } from './customers/customer-detail/customer-appointments/customer-appointments.component';
import { NewCustomersComponent } from './new-customers.component';
import { CustomerDocumentsComponent } from './customers/customer-detail/customer-documents/customer-documents.component';
import { CustomerContactPositionDetailComponent } from './customer-contact-position/detail/customer-contact-position-detail.component';
import { CustomerBaseDataComponent } from './customers/customer-detail/customer-base-data/customer-base-data.component';
import { CustomerNoticeComponent } from './customers/customer-detail/customer-notice/customer-notice.component';

export const PATHS = {
  'customers': '',
  'custom-customer-attribute-definitions': 'custom-fields',
  'contact-positions': 'contact-positions',
  'new-customers': 'new-customers',
  'notice': 'notice',
};

export const TITLES = {
  customers: new UpdateTitle('Kunden'),
  newCustomers: new UpdateTitle('Neukunden'),
  baseData: new UpdateTitle('Stammdaten', null),
  orderHistory: new UpdateTitle('Auftragshistorie', null),
  notice: new UpdateTitle('Notizen', null),
  appointments: new UpdateTitle('Termine', null),
  documents: new UpdateTitle('Dokumente', null),
  contacts: new UpdateTitle('Kundenkontakte', null),
  positions: new UpdateTitle('Kontaktpositionen'),
};

const routes: Routes = [
  {
    path: PATHS['contact-positions'] + '/:id',
    component: CustomerContactPositionDetailComponent,
    canActivate: [AuthGuard],
    data: { title: TITLES.positions },
  },
  {
    path: PATHS['contact-positions'],
    component: CustomerContactPositionsComponent,
    canActivate: [AuthGuard],
    data: { title: TITLES.positions },
  },
  {
    path: PATHS['new-customers'],
    component: NewCustomersComponent,
    canActivate: [AuthGuard],
    data: {title: TITLES.newCustomers},
  },
  {
    path: PATHS.customers + ':id',
    component: CustomerDetailComponent,
    canActivate: [AuthGuard],
    resolve: { customer: CustomerDetailResolverService },
    children: [
      { path: '', redirectTo: 'base-data' },
      {
        path: 'base-data',
        component: CustomerBaseDataComponent,
        data: { title: TITLES.baseData },
      },
      {
        path: 'notice',
        component: CustomerNoticeComponent,
        data: { title: TITLES.notice },
      },
      {
        path: 'contacts',
        component: CustomerContactsComponent,
        data: { title: TITLES.contacts },
      },
      {
        path: 'order-history/:id',
        component: CustomerOrderDetailComponent,
        data: { title: TITLES.orderHistory },
      },
      {
        path: 'order-history',
        component: CustomerOrderHistoryComponent,
        data: { title: TITLES.orderHistory },
      },
      {
        path: 'appointments',
        component: CustomerAppointmentsComponent,
        data: { title: TITLES.appointments },
      },
      {
        path: 'documents',
        component: CustomerDocumentsComponent,
        data: { title: TITLES.documents },
      },
    ],
  },
  {
    path: PATHS.customers,
    component: CustomersComponent,
    canActivate: [AuthGuard],
    data: { title: TITLES.customers },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
    CustomerDetailResolverService,
  ],
})
export class CustomersRoutingModule {
}
