import { Component, ViewChild } from '@angular/core';
import { ApiClient } from '../../../../common/api/ApiClient';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Message, MESSAGE_ENDPOINT } from '../../models/Message';
import { displayUser, getUserLink, User, USER_ENDPOINT } from '../../../user/users/User';
import { DATEPICKER_DE } from '../../../../config/locales';
import * as moment from 'moment';
import { DATE_DB_FORMAT } from '../../../../config/dates';
import { MESSAGE_CATEGORY_ENDPOINT, MessageCategory } from '../../models/MessageCategory';
import autobind from 'autobind-decorator';
import { DetailContainerComponent } from '../../../../common/layout/detail-container/detail-container.component';
import { BaseModel } from '../../../../common/api/BaseModel';

@Component({
  selector: 'im-message-detail',
  templateUrl: './message-detail.component.html',
})
export class MessageDetailComponent {
  public messageBackup: Message;
  public isEditable = false;
  public allUsers: Array<User> = [];
  public allCategories: Array<MessageCategory> = [];
  public displayUser = displayUser;
  public getUserLink = getUserLink;
  public de = DATEPICKER_DE;
  public readonly today = new Date();
  public messageEndpoint = MESSAGE_ENDPOINT;
  @ViewChild('detailContainer') detailContainer: DetailContainerComponent;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiClient,
    private toastr: ToastrService,
  ) {}

  @autobind
  transformMessage(message: Message & BaseModel) {
    this.messageBackup = JSON.parse(JSON.stringify(message));
    message.activationDateTime = moment(message.activationDateTime, DATE_DB_FORMAT).toDate();
    if (message.endDate) {
      message.endDate = moment(message.endDate, DATE_DB_FORMAT).toDate();
    }
    return message;
  }

  @autobind
  deleteMessage() {
    if (confirm('Wollen Sie die Nachricht wirklich löschen?')) {
      this.api.destroy(MESSAGE_ENDPOINT, this.message.Id).subscribe(
        () => {
          this.toastr.success('Nachricht erfolgreich gelöscht!');
          this.router.navigate(['../'], { relativeTo: this.route });
        },
      );
    }
  }

  @autobind
  editMessage() {
    if (this.allUsers.length === 0) {
      this.api.all<User>(USER_ENDPOINT).subscribe(
        users => {
          this.allUsers = users;
        },
      );
      this.api.all<MessageCategory>(MESSAGE_CATEGORY_ENDPOINT).subscribe(
        categories => {
          this.allCategories = categories;
        },
      );
    }

    this.isEditable = true;
  }

  @autobind
  abortEdit() {
    this.message = JSON.parse(JSON.stringify(this.messageBackup));
    this.message.activationDateTime = moment(this.message.activationDateTime, DATE_DB_FORMAT).toDate();
    if (this.message.endDate) {
      this.message.endDate = moment(this.message.endDate).format(DATE_DB_FORMAT);
    }
    this.isEditable = false;
  }

  @autobind
  saveMessage() {
    if (this.message.title.length === 0) {
      this.toastr.info('Bitte geben sie eine Mitteilung ein!');
      return;
    }
    if (this.message.users.length === 0) {
      this.toastr.info('Eine Nachricht benötigt mindestens einen Empfänger!');
      return;
    }
    if (this.message.endDate) {
      this.message.endDate = moment(this.message.endDate).format(DATE_DB_FORMAT);
    }

    this.api
      .update(MESSAGE_ENDPOINT, this.message.Id, {
        title: this.message.title,
        activationDateTime: this.message.activationDateTime,
        endDate: this.message.endDate,
        userIds: this.message.users.map(user => user.Id),
        categoryId: this.message.category.Id,
      })
      .subscribe(
        () => {
          this.toastr.success('Bearbeiten erfolgreich!');
          this.isEditable = false;
          this.detailContainer.refresh();
        },
      );
  }

  public get message(): Message {
    if (!this.detailContainer) {
      return null as any;
    }

    return this.detailContainer.entity;
  }

  public set message(message: Message) {
    this.detailContainer.entity = message;
  }
}
