<nav *ngIf="isReady && meta.getNumPages() > 1">
  <ul class="pagination justify-content-center" [ngClass]="{ 'loading': isLoading }">
    <li class="page-item"
        [ngClass]="{ 'disabled': !isReady || meta.getCurrentPageNum() === 1}">
      <a class="page-link" routerLink="./"
         [queryParams]="{ page: isReady ? meta.getCurrentPageNum() - 1 : 1 }"
         (click)="loadPage(isReady ? meta.getCurrentPageNum() - 1 : 1)">
        <span aria-hidden="true"><im-icon name="angle-left"></im-icon></span>
        <span class="sr-only">Previous</span>
      </a>
    </li>

    <li class="page-item" *ngFor="let entry of entries" [ngClass]="{
      'active': entry.active,
      'disabled': entry.disabled,
      'current': entryIsCurrent(entry)
    }">
      <a class="page-link" routerLink="./" [queryParams]="entry.params" (click)="loadPage(entry.label)">
        {{entry.label}}
      </a>
    </li>

    <li class="page-item"
        [ngClass]="{ 'disabled': !isReady || meta.getCurrentPageNum() === meta.getNumPages()}">
      <a class="page-link" routerLink="./"
         [queryParams]="{ page: isReady ? meta.getCurrentPageNum() + 1 : 1}"
         (click)="loadPage(isReady ? meta.getCurrentPageNum() + 1 : 1)">
        <span aria-hidden="true"><im-icon name="angle-left" additionalClasses="fa-flip-horizontal"></im-icon></span>
        <span class="sr-only">Next</span>
      </a>
    </li>
  </ul>
</nav>
