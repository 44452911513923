<div *ngIf="orderStatus === OrderStatus.FAILED" class="alert alert-warning" role="alert">
    <h4 class="alert-heading">
      Beim Upload dieses Auftrags ist ein Fehler aufgetreten
    </h4>

    <p>
      Die Aufträge werden nach dem Abschließen und Synchronisieren
      an das SBAX übertragen.
      Dieser Upload ist zuletzt am {{ order.ExportFailedAt | sbDate }}
      fehlgeschlagen!
    </p>

    <div class="collapse" id="export-traffic">
        <div class="card card-body">
            <h3>Anfrage</h3>
            <pre class="mb-3">
              {{ order.ExportRequest }}
            </pre>

            <h3>Antwort</h3>
            <pre>
              {{ order.ExportResponse }}
            </pre>
        </div>
    </div>

    <hr>

    <div class="btn-group ms-auto d-block">
        <button (click)="retryUpload()" [disabled]="pressedRetry"
                class="btn btn-outline-dark">
            <im-icon name="repeat"></im-icon>
            Upload erneut anstoßen
        </button>

        <button class="btn btn-outline-dark" type="button"
                data-bs-toggle="collapse" aria-expanded="false"
                data-bs-target="#export-traffic" aria-controls="export-traffic">
            <im-icon name="bug"></im-icon>
            Export-Traffic einsehen
        </button>
    </div>
</div>

<div *ngIf="orderStatus === OrderStatus.EXPORTED"
         class="alert alert-success"
         role="alert"
>
    <h4 class="alert-heading">
      Dieser Auftrag wurde erfolgreich exportiert!
    </h4>

    <p>
      Die Aufträge werden nach dem Abschließen und Synchronisieren
      an das SBAX übertragen.
      Dieser Upload wurde am {{ order.ExportedAt | sbDate }}
      erfolgreich durchgeführt!
    </p>

    <div class="collapse" id="export-traffic">
        <div class="card card-body">
            <h3>Anfrage</h3>
            <pre class="mb-3">
               {{ order.ExportRequest }}
            </pre>

            <h3>Antwort</h3>
            <pre>
               {{ order.ExportResponse }}
            </pre>
        </div>
    </div>

    <hr>

    <button class="btn btn-outline-dark" type="button"
            data-bs-toggle="collapse" aria-expanded="false"
            data-bs-target="#export-traffic" aria-controls="export-traffic">
      <im-icon name="bug"></im-icon>
      Export-Traffic einsehen
    </button>
</div>

<div
  *ngIf="orderStatus === OrderStatus.EXPORTING"
  class="alert alert-warning"
  role="alert"
>
  <h4 class="alert-heading">Dieser Auftrag wird aktuell exportiert</h4>
  <p>
    Die Aufträge werden nach dem Abschließen und Synchronisieren exportiert. Dies kann einen Moment dauern.
  </p>
</div>

<div
  *ngIf="orderStatus === OrderStatus.OPEN"
  class="alert alert-warning"
  role="alert"
>
  <h4 class="alert-heading">Dies ist ein offener Auftrag und wird nicht exportiert</h4>
  <p>
    Die Aufträge werden nach dem Abschließen und Synchronisieren exportiert. Dies kann einen Moment dauern.
  </p>
</div>

<div
  *ngIf="orderStatus === OrderStatus.OFFER"
  class="alert alert-warning"
  role="alert"
>
  <h4 class="alert-heading">Dies ist ein Angebot und wird nicht exportiert</h4>
  <p>
    Wird aus einem Angebot ein Auftrag erzeugt, wird dieser nach dem Abschließen und Synchronisieren exportiert. Dies kann einen Moment dauern.
  </p>
</div>
