import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import {AuthGuard} from "./auth.guard";

@Injectable()
export class AdminGuard extends AuthGuard{
  constructor(public auth: AuthService, public router: Router) {
    super(auth, router);
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return super.canActivate(route, state);// && (hasRole(this.auth.user, Roles.SUPER_ADMIN) || hasRole(this.auth.user, Roles.BACKEND_ADMIN));
  }
}
