export const NO_REPETITION = 'none';
export const CUSTOM_REPETITION = 'custom';

export enum Frequency {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  BIWEEKLY = 'WEEKLY;INTERVAL=2',
  FOUR_WEEKLY = 'WEEKLY;INTERVAL=4',
}

export type SupportedFrequency = Frequency | typeof NO_REPETITION | typeof CUSTOM_REPETITION;

export const FrequencyLabels = {
  [NO_REPETITION]: 'keine Wiederholung',
  [Frequency.DAILY]: 'täglich',
  [Frequency.WEEKLY]: 'wöchentlich',
  [Frequency.BIWEEKLY]: '2-wöchentlich',
  [Frequency.FOUR_WEEKLY]: '4-wöchentlich',
  [CUSTOM_REPETITION]: 'Nach X Tagen',
};
