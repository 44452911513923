import {Component, ViewChild} from '@angular/core';
import { Customer } from '../../../models/Customer';
import { CustomerDetailService } from '../../customer-detail.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ApiClient } from '../../../../../common/api/ApiClient';
import { ToastrService } from 'ngx-toastr';
import { FromDB } from '../../../../../common/api/FromDB';
import { Roles } from '../../../../../im-modules/user/users/Role';
import { AuthService } from '../../../../../im-modules/user/auth/auth.service';
import { hasRole } from '../../../../../im-modules/user/users/User';
import { ENDPOINT, Note } from "../../../models/Note";
import { CustomerNoteModalComponent } from "./customer-note-modal.component";
import { DataTableComponent } from "../../../../../common/api/data-table/data-table.component";

@Component({
  selector: 'sebu-customer-notice',
  templateUrl: './customer-notice.component.html',
  styles: [],
})
export class CustomerNoticeComponent {
  customer: FromDB<Customer>;
  notice: UntypedFormControl;
  isEditable: boolean;
  notesHistoryEndpoint: string;
  selectedNote: Note|null = null;
  @ViewChild('noteModal') noteModal: CustomerNoteModalComponent
  @ViewChild('notesHistoryTable') notesHistoryTable: DataTableComponent;

  constructor(
    detailService: CustomerDetailService,
    protected api: ApiClient,
    private toastr: ToastrService,
    protected auth: AuthService,
  ) {
    this.customer = detailService.customer;
    this.notice = new UntypedFormControl(
      {value: detailService.customer.Notiz, disabled: !this.isAdmin()},
      Validators.maxLength(1000)
    );
    this.isEditable = this.isAdmin()
    this.notesHistoryEndpoint = ENDPOINT + '/' + this.customer.Id;
  }

  saveCustomerInfo() {
    if (this.isAdmin() && this.notice.valid) {
      this.customer.Notiz = this.notice.value;
      this.api.update('kunden', this.customer.Id, {'Notiz': this.notice.value}).subscribe(
        () => {
          this.toastr.success('Die KundenInfo wurde erfolgreich gespeichert!');
        });
    }
  }

  createNote() {
    this.selectedNote = null;
    this.noteModal.showForNote(null);
  }

  editNote(note: Note): void {
    this.selectedNote = note;
    this.noteModal.showForNote(note);
  }

  onNoteSaved() {
    this.notesHistoryTable.loadPage();
    this.noteModal.hide();
  }

  deleteNote(note: Note): void {
    if (confirm('Wirklich löschen?')) {
      this.api.destroy('notes', note.Id).subscribe(
        () => {
          this.toastr.success('Die Notiz wurde erfolgreich gelöscht!');
          this.notesHistoryTable.loadPage();
          this.selectedNote = null;
        });
    }
  }

  isAdmin() {
    return hasRole(this.auth.user, Roles.BACKEND_ADMIN)
      || hasRole(this.auth.user, Roles.SUPER_ADMIN);
  }

}
