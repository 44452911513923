import { Component, HostListener, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';


const SELECTOR = 'im-checkable-option';

@Component({
  selector: SELECTOR,
  template: `
    <div class="form-check">
      <label class="form-check-label">
        <input type="checkbox" class="form-check-input"
               [checked]="checked" [disabled]="disabled" (click)="prevent($event)">
        <ng-content></ng-content>
      </label>
    </div>
  `,
})
export class CheckableOptionComponent implements ControlValueAccessor {
  @Input() checked = false;
  public disabled = false;
  @Input() public model: any;
  private onChanges: Array<Function> = [];

  @HostListener('click', [ '$event' ]) onClick(event: MouseEvent) {
    const nodeName = (event.target as HTMLElement).nodeName.toLocaleLowerCase();

    if (nodeName === SELECTOR || nodeName === 'div') {
      // Falls weder Label noch Checkbox angeclickt wurden, ebenfalls updaten
      this.checked = !this.checked;
      this.publishChange();
    }
  }

  prevent($event) {
    this.checked = !this.checked;
    this.publishChange();

    // Event nicht mehr an den Host-Listener weiterleiten
    $event.stopImmediatePropagation();
  }

  private publishChange() {
    this.onChanges.forEach(fn => {
      fn(this.model);
    });
  }

  registerOnChange(fn: any): void {
    this.onChanges.push(fn);
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.model = obj;
    this.publishChange();
  }
}
