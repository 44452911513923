import * as debug from 'debug';

export class LogFactory {
  private static APP_PREFIX = 'sebu:';

  static create(namespace: Function|string) {
    let _namespace = namespace;

    if (typeof namespace === 'function') {
      _namespace = namespace.name;
    }

    return debug(LogFactory.APP_PREFIX + _namespace);
  }
}
