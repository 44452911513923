<a *ngIf="contacts.length === 0 && !(this.loadingService.loading$ | async)" class="text-muted text-center">
    Keine Kontakte vorhanden! <br>
    <span *ngIf="adisContactName !== ''">
        Laut ADIS exisitert ein Kontakt mit dem Namen '{{ adisContactName}}!'
    </span>
</a>

<ul class="list-group" *ngIf="contacts.length > 0">
    <a *ngFor="let contact of contacts" class="list-group-item list-group-item-action"
       (click)="contactSelected.next(contact)"
       [ngClass]="{'active': activeContact === contact}">
       {{contact.firstName}} {{contact.lastName}}
    </a>
</ul>
