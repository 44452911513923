import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Customer, showCustomerNumber } from '../../../models/Customer';
import { AUFTR_ENDPOINT, isFinished } from '../../../models/Auftr';
import { FilterBarService } from '../../../../../common/api/filter-bar/filter-bar.service';
import { CustomerDetailService } from '../../customer-detail.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DATEPICKER_DE } from '../../../../../config/locales';
import * as moment from 'moment';
import { DATE_QUERY_PARAM_FORMAT } from '../../../../../config/dates';

@Component({
  selector: 'sebu-customer-order-history',
  templateUrl: './customer-order-history.component.html',
  styleUrls: ['customer-order-history.component.scss'],
  providers: [
    FilterBarService,
  ],
})
export class CustomerOrderHistoryComponent {
  public endpoint = AUFTR_ENDPOINT;
  @ViewChild('tableBody') tableBodyTemplate: TemplateRef<any>;

  customer: Customer;
  isFinished = isFinished;
  de = DATEPICKER_DE;
  filters = new UntypedFormGroup({
    'order-date-from': new UntypedFormControl(),
    'order-date-to': new UntypedFormControl(),
  });

  constructor(
    private filterBar: FilterBarService,
    detailService: CustomerDetailService,
  ) {
    this.customer = detailService.customer;
    this.filterBar.registerDeserializerForKey(
      'order-date-from',
      CustomerOrderHistoryComponent.deserializeDateFromQueryParam,
    );
    this.filterBar.registerDeserializerForKey(
      'order-date-to',
      CustomerOrderHistoryComponent.deserializeDateFromQueryParam,
    );
  }

  public static deserializeDateFromQueryParam(dateStr: string) {
    return moment(dateStr, DATE_QUERY_PARAM_FORMAT).toDate();
  }

  get dataTableParams() {
    const kundennummer = showCustomerNumber(this.customer);
    const filterKey = `${this.filterBar.filterPrefix}customer`;

    return {
      [filterKey]: kundennummer,
    };
  }
}
