<div class="mb-3" *ngIf="canUpload">
  <sebu-customer-document-upload
    [customer]="customer"
    (created)="reloadDataTable()"
  ></sebu-customer-document-upload>
</div>

<im-data-table [endpoint]="endpoint">
  <ng-template imDataTableHeaders>
    <th>Erstellt</th>
    <th>Name</th>
    <th>Datei</th>
    <th class="actions-column">Aktionen</th>
  </ng-template>

  <ng-template imDataTableRow let-row>
    <td>{{ row.CreatedAt | sbDate }}</td>
    <td>{{ row.label }}</td>
    <td>{{ row.original_filename }}</td>
    <td>
      <div class="btn-group">
        <button class="btn btn-outline-dark" (click)="download(row)">
          <im-icon name="download"></im-icon>
          Download
        </button>
        <button
          imDeleteButton [model]="row"
          [endpoint]="endpointWithoutQuery"
          [getId]="extractPivotId"
          (deleted)="reloadDataTable()"
        >
          Löschen
        </button>
      </div>
    </td>
  </ng-template>
</im-data-table>
