import { Component, ViewChild } from '@angular/core';
import { OverviewContainerComponent } from '../../../common/layout/overview-container/overview-container.component';

@Component({
  selector: 'im-products',
  template: `
    <im-overview-container heading="Produkte" buttonTextCreate="Neues Produkt erstellen">
      <im-products-table *imOverviewContainerOverviewTemplate></im-products-table>

      <ng-container *imAdminOnly>
        <im-create-product
          *imOverviewContainerCreationFormTemplate
          (productCreated)="onProductCreated()"
        ></im-create-product>
      </ng-container>
    </im-overview-container>
  `,
})
export class ProductsComponent {
  @ViewChild(OverviewContainerComponent)
  overviewContainer: OverviewContainerComponent;

  onProductCreated() {
    this.overviewContainer.switchView();
  }
}
