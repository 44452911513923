import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import { User } from '../../../user/users/User';
import { ExpandedAugmentedAppointment } from '../../ical/ical-query.service';
import { ICalAppointmentService } from '../../ical/i-cal-appointment.service';
import { LogFactory } from '../../../../common/generic/LogFactory';
import * as moment from 'moment';
import { FromDB } from '../../../../common/api/FromDB';
import { AppointmentType } from '../../models/AppointmentType';
import { Customer } from '../../../../sebu/customers/models/Customer';
import { map } from 'rxjs/operators';


let log;

@Component({
  selector: 'im-appointment-list',
  templateUrl: 'appointment-list.component.html',
  styleUrls: ['appointment-list.component.scss'],
})
export class AppointmentListComponent implements OnChanges {
  @Input() user: User;
  @Input() from: Date;
  @Input() to: Date;
  @Input() type: FromDB<AppointmentType>;
  @Input() filterMissingReports: boolean;
  @Input() selectedAppointments: Array<ExpandedAugmentedAppointment> | null = null; // Wenn gesetzt handelt es sich um die Termin-Übertragung Ansicht
  @Output() selectedAppointmentsChange = new EventEmitter<Array<ExpandedAugmentedAppointment>>();

  // Dieser Input wird nur verwendet, weil diese Komponente die Daten selbst
  // abruft. Vielleicht sollte man doch eher die geladenen Daten einfach hier
  // übergeben.
  @Input() customer: FromDB<Customer> | null = null;

  public hasLoaded = false;

  public appointments: Array<ExpandedAugmentedAppointment> = [];

  constructor(private ical: ICalAppointmentService) {
  }

  fetchData() {
    log('Rufe neue Kalenderdaten ab...');

    this.hasLoaded = false;

    this.ical
      .range(this.user, this.from, this.to, this.type)
      .pipe(
        map(appointments => appointments.filter(appointment => {
          // Falls kein Kunde übergeben wurde, auch nicht filtern!
          // Hier filtern wir erstmal frontend-seitig, falls die Performance
          // nachher zu schlecht wird, verlagern wir das ins Backend.
          return this.customer === null || appointment.customerId === this.customer.Id;
        })),
      )
      .subscribe(appointments => {
        log('Neue Kalenderdaten erhalten!', appointments);
        this.appointments = appointments;
        this.hasLoaded = true;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.user || changes.date || changes.type || changes.filterMissingReports) && this.hasAllDataToFetch) {
      this.fetchData();
    } else {
      this.appointments = [];
    }
  }

  /**
   * Gruppiert die Termine nach Tagen.
   */
  public get appointmentsByDay() {
    const days = new Map<number, ExpandedAugmentedAppointment[]>();

    // Fehlender Bericht Filter
    this.appointments.filter(appointment => {
      // filter nicht angehakt --> nicht filtern
      return !this.filterMissingReports || this.user.vertreter == null ||
        // Vertreter ist verpflichtet, Termin ist keine Ausnahme, & Bericht ist leer
        (!this.user.vertreter.SkipVisitReport &&
          !appointment.SkipVisitReport &&
          appointment.report.trim().length < 1);
    })
      .forEach(appointment => {
        const recurrenceDayTimeStamp = appointment.isException ? moment(appointment.start).startOf('day').toDate().valueOf() :
          moment(appointment.recurrence.toJSDate())
            .startOf('day')
            .toDate()
            .valueOf();

        if (!days.has(recurrenceDayTimeStamp)) {
          days.set(recurrenceDayTimeStamp, []);
        }

        (days.get(recurrenceDayTimeStamp) as ExpandedAugmentedAppointment[]).push(appointment);
      });

    return Array.from(days.entries()).map(entry => {
      return {
        day: new Date(entry[0]),
        appointments: entry[1],
      };
    });
  }

  private get hasAllDataToFetch() {
    return this.user && this.from && this.to;
  }

  protected get isSelectionMode() {
    return this.selectedAppointments !== null;
  }

  protected onAppointmentSelected(appointment: ExpandedAugmentedAppointment) {
    if (this.selectedAppointments !== null) {
      this.selectedAppointments.push(appointment);
    }
  }

  protected onAppointmentUnselected(appointment: ExpandedAugmentedAppointment) {
    if (this.selectedAppointments !== null) {
      this.selectedAppointments.splice(this.selectedAppointments.indexOf(appointment), 1);
    }
  }

  protected onAppointmentSelectClicked(appointment: ExpandedAugmentedAppointment) {
    if (this.appointmentIsSelected(appointment)) {
      this.onAppointmentUnselected(appointment);
    } else {
      this.onAppointmentSelected(appointment);
    }
  }
  protected appointmentIsSelected(appointment: ExpandedAugmentedAppointment) {
    return this.selectedAppointments !== null && this.selectedAppointments.includes(appointment);
  }
}

log = LogFactory.create(AppointmentListComponent);
