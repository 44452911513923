import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LaravelPaginatedResponse } from './LaravelPaginatedResponse';
import { PaginationMetadata } from './paginator/PaginationMetadata';
import { LaravelPaginationMetadata } from './paginator/LaravelPaginationMetadata';
import { FromDB } from './FromDB';
import { map } from 'rxjs/operators';

/**
 * Ein ApiClient zum erhalten von Models. Stark angelehnt an die Verwendung
 * mit einem Laravel-Resource Controller.
 */
@Injectable({
  providedIn: 'root',
})
export class ApiClient {
  static baseUrl = '/api';

  constructor(private http: HttpClient) {}

  public normalize(endpoint: string) {
    return ApiClient.normalizeEndpoint(endpoint);
  }

  public static normalizeEndpoint(endpoint: string) {
    endpoint = endpoint.startsWith('/') ? endpoint : '/' + endpoint;

    return ApiClient.baseUrl + endpoint;
  }

  // ==========================================================================
  //  Laravelspezifische Methoden
  // ==========================================================================

  public index<T>(endpoint: string, onResponse?: (meta: PaginationMetadata) => void): Observable<Array<FromDB<T>>> {
    return (this.http.get(ApiClient.normalizeEndpoint(endpoint)) as Observable<LaravelPaginatedResponse<FromDB<T>>>).pipe(
      map(response => {
        if (onResponse) {
          onResponse(new LaravelPaginationMetadata(response));
        }
        return response.data;
      }),
    );
  }

  public all<T>(endpoint: string, params = {}): Observable<Array<FromDB<T>>> {
    return this.http.get(ApiClient.normalizeEndpoint(endpoint), {
      params: {
        all: 'true',
        ...params,
      },
    }) as Observable<Array<FromDB<T>>>;
  }

  public show<T>(endpoint: string, id: string): Observable<FromDB<T>> {
    return this.http.get(
      ApiClient.normalizeEndpoint(endpoint) + '/' + id,
    ) as Observable<FromDB<T>>;
  }

  public update<T>(endpoint: string, id: string, data: Object): Observable<FromDB<T>> {
    return this.http.put(
      ApiClient.normalizeEndpoint(endpoint) + '/' + id,
      data,
    ) as Observable<FromDB<T>>;
  }

  public store<T>(endpoint: string, data: Object): Observable<FromDB<T>> {
    return this.http.post(ApiClient.normalizeEndpoint(endpoint), data) as Observable<FromDB<T>
    >;
  }

  public destroy(endpoint: string, id: string) {
    return this.http.delete(ApiClient.normalizeEndpoint(endpoint) + '/' + id);
  }

  // ==========================================================================
  //  Methoden, die zum Http-Clienten durchgereich werden
  // ==========================================================================

  public get<T>(endpoint: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe: 'response';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<HttpResponse<T>> {
    return this.http.get<T>(ApiClient.normalizeEndpoint(endpoint), options);
  }

  public getHttpClient(): HttpClient {
    return this.http;
  }
}
