<im-filter-bar [filterGroup]="filters" [formGroup]="filters">
  <div class="filter-group">
    <label class="mr-2 ml-3 me-2 ms-3" for="order-date-from">
      Auftragsdatum von
    </label>
    <p-calendar
      id="order-date-from" class="me-2"
      formControlName="order-date-from"
      [locale]="de" dateFormat="dd.mm.yy" showButtonBar="true"
    ></p-calendar>
  </div>

  <div class="filter-group">
    <label class="mr-2 ml-3 me-2 ms-3" for="order-date-to">
      Auftragsdatum bis
    </label>
    <p-calendar
      id="order-date-to" class="me-2"
      formControlName="order-date-to"
      [locale]="de" dateFormat="dd.mm.yy" showButtonBar="true"
    ></p-calendar>
  </div>

  <div class="filter-group">
    <label class="mr-2 ml-3 me-2 ms-3" for="order-date-to">
      Typ
    </label>

    <select
      class="form-control form-select"
      formControlName="order-type"
      [attr.disabled]="allUsers.length === 0 ? true : null"
    >
      <option value="">Alle</option>
      <option [value]="OrderFilterType.ONLY_AUFTRAEGE">Nur Aufträge</option>
      <option [value]="OrderFilterType.ONLY_OFFERS">Nur Angebote</option>
    </select>
  </div>

  <div class="filter-group">
    <label class="mr-2 ml-3 me-2 ms-3">
      SBAX
    </label>
    <select
      class="form-control form-select"
      formControlName="order-sbax"
      [attr.disabled]="allUsers.length === 0 ? true : null"
    >
      <option value="">Alle</option>
      <option [value]="OrderFilterSBAX.EXPORTED">Exportiert</option>
      <option [value]="OrderFilterSBAX.NOT_EXPORTED">Nicht Exportiert</option>
      <option [value]="OrderFilterSBAX.FAILED">Fehlgeschlagen</option>
      <option [value]="OrderFilterSBAX.WILL_EXPORT">Wird Exportiert</option>
    </select>
  </div>

  <div class="filter-group">
    <label class="mr-2 ml-3 me-2 ms-3" for="order-date-to">
      Nutzer
    </label>

    <select
      class="form-control form-select"
      formControlName="order-user"
      [attr.disabled]="allUsers.length === 0 ? true : null"
    >
      <option
        *ngFor="let user of allUsers"
        [ngValue]="user ? user.Id : undefined"
      >
        {{displayUser(user, true)}}
      </option>
    </select>
  </div>

  <div class="filter-group">
    <label class="mr-2 ml-3 me-2 ms-3" for="order-number">
      Auftragsnummer
    </label>
    <input type="text" class="form-control" id="order-number" [formControl]="filters.get('order-number')"/>
  </div>

  <div class="filter-group">
    <label class="mr-2 ml-3 me-2 ms-3" for="order-customer">
      Kunde
    </label>
    <input type="text" class="form-control" id="order-customer" [formControl]="filters.get('order-customer')"/>
  </div>

  <div class="filter-group">
    <label class="mr-2 ml-3 me-2 ms-3">
      Status
    </label>
    <select
      class="form-control form-select"
      formControlName="order-status"
      [attr.disabled]="allUsers.length === 0 ? true : null"
    >
      <option value="">Alle</option>
      <option [value]="OrderFilterStatus.COMPLETED">Abgeschlossen</option>
      <option [value]="OrderFilterStatus.NOT_COMPLETED">Nicht Abgeschlossen</option>
    </select>
  </div>
</im-filter-bar>
<div class="container">
  <div class="d-flex justify-content-end">
    <button
      class="btn btn-link ms-auto"
      style="margin-bottom: 15px;"
      (click)="downloadCsvExport()"
    >
      <im-icon name="download"></im-icon>
      Export herunterladen
    </button>
  </div>
</div>

<im-data-table [endpoint]="endpoint" [bodyTemplate]="tableBodyTemplate" (total)="this.total = $event">
  <ng-template imDataTableHeaders>
    <th (click)="sortTable.sort('NotepadAuftragsNr')"
        class="text-center cursor-pointer">Auftragsnummer</th>
    <th (click)="sortTable.sort('Kundenname1')"
      class="text-center cursor-pointer">Kunde</th>
    <th (click)="sortTable.sort('Auftragsdatum')" class="cursor-pointer text-center">Auftragsdatum</th>
    <th (click)="sortTable.sort('Lieferdatum')" class="cursor-pointer text-center">Lieferdatum</th>
    <th (click)="sortTable.sort('Auftragswert')" class="cursor-pointer text-center">Wert</th>
    <th (click)="sortTable.sort('CompletedAt')" class="cursor-pointer text-center">Status</th>
    <th (click)="sortTable.sort('SBAX')" class="cursor-pointer text-center">SBAX</th>
    <th (click)="sortTable.sort('isOffer')" class="cursor-pointer text-center">Typ</th>
    <th class="actions-column">Aktionen</th>
  </ng-template>
</im-data-table>

<ng-template #tableBody let-rows>
  <tr
    *ngFor="let row of rows"
    [class.table-danger]="!isFinished(row)"
    [class.table-warning]="uploadDidFail(row)"
  >
    <td class="text-center">{{ row.NotepadAuftragsNr }}</td>
    <td class="text-center">
      <div class="line-clamp">{{ row.Kunde.Kundenname1 }}</div>
    </td>
    <td class="text-center">{{ row.Auftragsdatum | sbDate:true }}</td>
    <td class="text-center">{{ row.Lieferdatum | sbDate:true }}</td>
    <td class="text-right">{{ row.Auftragswert | currency:'EUR':'symbol' }}</td>
    <td class="text-center">{{ isFinished(row) ? 'abgeschlossen' : 'nicht abgeschlossen' }}</td>
    <td class="text-center">{{ displaySBAXStatus(row) }}</td>
    <td class="text-center">{{ row.isOffer ? 'Angebot' : 'Auftrag' }}</td>
    <td>
      <div class="btn-group">
        <button imDetailButton [model]="row" (click)="onDetailClicked(row, $event)">Details</button>
      </div>
    </td>
  </tr>
</ng-template>
