import {Component} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {ApiClient} from "../../../common/api/ApiClient";
import {ToastrService} from "ngx-toastr";
import {FromDB} from "../../../common/api/FromDB";
import {FailedOrderUploadEmail} from "../emails-failed-order-upload/emails-failed-order-upload.component";

@Component({
  selector: 'sebu-emails-new-customer-application',
  templateUrl: './emails-new-customer-application.component.html',
  styles: []
})
export class EmailsNewCustomerApplicationComponent {
  public formGroupList: UntypedFormGroup = new UntypedFormGroup({});
  public newEntity: UntypedFormGroup;
  private apiEndpoint = 'new-customer-application-email';
  public search = '';

  constructor(
    private api: ApiClient,
    protected toastr: ToastrService,
  ) {
    this.newEntity = this.createFormGroup('');
  }

  ngOnInit(): void {
    this.loadList();
  }

  add() {
    if (this.newEntity.valid) {
      this.api.store(this.apiEndpoint, this.newEntity.value).subscribe(
        () => {
          this.toastr.success('Die E-Mail wurde erfolgreich hinterlegt!');
          this.newEntity = this.createFormGroup('');
          this.loadList();
        });
    } else {
      const email = this.newEntity.get('email');
      if (email !== null) {
        email.markAsDirty();
      }
    }
  }

  changeSearch(event) {
    this.search = event.target.value;
  }

  getFormGroupListIds(): Array<string> {
    return Object.keys(this.formGroupList.controls);
  }

  delete(event) {
    const id = event.target.id;
    const formGroup = this.formGroupList.get(id);

    if (formGroup !== null) {
      if (!confirm('Sind Sie sicher, dass die Zeile gelöscht werden soll?')) {
        return;
      }
    }

    this.api.destroy(this.apiEndpoint, id).subscribe(
      () => {
        this.toastr.success('Die Zeile wurde erfolgreich gelöscht!');
        this.loadList();
      });
  }

  loadList() {
    this.api.all<FailedOrderUploadEmail>(this.apiEndpoint).subscribe((list: FromDB<FailedOrderUploadEmail>[]) => {
        const existingIds: string[] = [];

        for (const email of list.reverse()) {
          const formGroup = this.formGroupList.get(email.Id);
          existingIds.push(email.Id);

          if (formGroup === null) {
            // Neue Einträge hinzufügen
            this.formGroupList.addControl(
              email.Id,
              this.createFormGroup(email.email),
            );
          } else {
            // Nur nicht geänderte Zeilen mit den neuen Daten befüllen
            if (!formGroup.dirty) {
              formGroup.reset({
                email: email.email,
              });
            }
          }
        }

        // Alle unbekannten Ids löschen
        const arrayDiff = this.getFormGroupListIds().filter(x => !existingIds.includes(x));
        for (const diff of arrayDiff) {
          this.formGroupList.removeControl(diff);
        }
      },
    );
  }

  /**
   * Erstellt den FormGroup mit Default-Daten
   *
   * @param value
   * @private
   */
  private createFormGroup(
    value: string,
  ): UntypedFormGroup {
    return new UntypedFormGroup({
      email: new UntypedFormControl(value, [Validators.maxLength(255), Validators.email, Validators.required, Validators.minLength(1)]),
    });
  }
}
