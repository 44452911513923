import { Injectable, Type } from '@angular/core';
import { SettingComponent } from './setting.component';
import {
  BooleanAppSetting,
  BoolSettingComponent,
} from './bool-setting.component';
import { AppSetting } from '../models/AppSetting';
import {
  IntegerAppSetting,
  IntegerSettingComponent,
} from './integer-setting.component';
import {
  StringAppSetting,
  StringSettingComponent,
} from './string-setting.component';
import {
  EmailAppSetting,
  EmailSettingComponent,
} from './email-setting.component';
import {
  PasswordSettingComponent, PasswordAppSetting,
} from './password-setting.component';
import {
  DateSettingComponent, DateAppSetting,
} from './date-setting.component';

/**
 * Service zum erhalten einer Einstellungsanzeigekomponente für einen Datentypen.
 * Im Konstruktor werden hier diverste Komponenten registriert.
 */
@Injectable({
  providedIn: 'root',
})
export class SettingComponentService {
  /** Map aller registrierten Komponenten-Einstellungstypen-Zuordnungen */
  private components = new Map<string, Type<SettingComponent<any>>>();

  /**
   * Hier sollten die Komponenten den jeweiligen Typen zugeordnet werden
   */
  constructor() {
    this.registerComponentForType<BooleanAppSetting>('bool', BoolSettingComponent);
    this.registerComponentForType<IntegerAppSetting>('integer', IntegerSettingComponent);
    this.registerComponentForType<StringAppSetting>('string', StringSettingComponent);
    this.registerComponentForType<EmailAppSetting>('email', EmailSettingComponent);
    this.registerComponentForType<PasswordAppSetting>('password', PasswordSettingComponent);
    this.registerComponentForType<DateAppSetting>('date', DateSettingComponent);
  }

  registerComponentForType<T extends AppSetting>(type: T['type'], component: Type<SettingComponent<T>>) {
    this.components.set(type, component);
  }

  /**
   * Liefert für einen Einstellungstypen eine Komponente zur Verwaltung.
   *
   * @param type
   */
  getComponentForType<T extends AppSetting>(type: T['type']): Type<SettingComponent<T>> {
    const component = this.components.get(type);

    if (!component) {
      throw new Error(`Keine Komponente für Typ '${type}' registriert!`);
    }

    return component;
  }
}
