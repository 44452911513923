import { Salutation } from './Salutation';
import { Customer } from './Customer';
import { CustomContactAttributes } from './CustomContactAttributes';
import { FromDB } from '../../../common/api/FromDB';
import { ContactPosition } from './ContactPosition';

export interface Contact {
  kundeId: string;
  salutationId: string;
  firstName: string;
  lastName: string;
  mobilePhone: string;
  phone: string;
  fax: string;
  email: string;
  birthDate: string | Date;
  positionId: string;
  note: string;

  salutation: Salutation;
  customer: Customer;
  customAttributes: CustomContactAttributes[];
}

export type ContactWithPosition = FromDB<Contact> & {
  position: FromDB<ContactPosition>;
};

export const CONTACT_ENDPOINT = '/crm/contacts';

export const CONTACT_CSV_EXPORT_ENDPOINT = `${CONTACT_ENDPOINT}/csv-export`;
