import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ApiClient } from './ApiClient';

@Injectable({
  providedIn: 'root',
})
export class FileDownloadService {

  public defaultFilename = 'download.txt';

  constructor(protected http: HttpClient) { }

  download(endpoint: string, params = {}): void {
    this.http
      .get(ApiClient.normalizeEndpoint(endpoint), {
        observe: 'events',
        responseType: 'blob',
        params,
      })
      .subscribe(event => {
        if (!(event instanceof HttpResponse)) {
          return;
        }

        const response = event as HttpResponse<Blob>;
        const filename = this.getFilename(response, this.defaultFilename);

        this.saveBlob(response.body as Blob, filename);
      });
  }

  /**
   * Noch verbesserungswürdig!
   * @param response
   * @param fallback
   */
  private getFilename(response: HttpResponse<any>, fallback: string) {
    const contentDisposition = response.headers.get('Content-Disposition');

    if (!contentDisposition) {
      return fallback;
    }

    const filenameKey = 'filename=';
    const filenameIndex = contentDisposition.indexOf(filenameKey);

    if (filenameIndex === -1) {
      return fallback;
    }

    let fileName = contentDisposition.slice(filenameIndex + filenameKey.length);

    if (fileName[0] === '"') {
      fileName = fileName.slice(1);
    }

    if (fileName[fileName.length - 1] === '"') {
      fileName = fileName.slice(0, fileName.length - 1);
    }

    return fileName;
  }

  private saveBlob(blob: Blob, name: string) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = name;
    a.click();

    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }
}
