export interface Role {
  id: number;
  name: Roles;
  guard_name: String;
}

export enum Roles {
  INNENDIENST = 'innendienst',
  AUSSENDIENST = 'aussendienst',
  BACKEND_ADMIN = 'backend_admin',
  SUPER_ADMIN = 'super_admin',
}

export const roleNames = {
  [Roles.INNENDIENST]: 'Innendienst',
  [Roles.AUSSENDIENST]: 'Außendienst',
  [Roles.BACKEND_ADMIN]: 'Backend Admin',
  [Roles.SUPER_ADMIN]: 'Super Admin',
};

export function rolesAsString(roles: Role[]) {
  return roles
    .map(role => roleNames[role.name] || role.name)
    .join(', ');
}

export const ROLE_ENDPOINT = '/roles';
