import { Component, Input } from '@angular/core';

@Component({
  selector: 'sebu-customer-attribute',
  templateUrl: 'customer-attribute.component.html',
  styleUrls: ['customer-attribute.component.scss'],
})
export class CustomerAttributeComponent {
  @Input() label = '';
}
