import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../im-modules/user/auth/auth.guard';
import { UpdateTitle } from '../../common/generic/title/title.service';
import { NgModule } from '@angular/core';
import { OrdersComponent } from './orders.component';
import { OriginComponent } from "./origin/origin.component";
import {OriginDetailComponent} from "./origin/origin-detail/origin-detail.component";

export const PATHS = {
  orders: '',
  orderOrigin: 'auftragsherkünfte',
};

export const TITLES = {
    orders: new UpdateTitle('Aufträge'),
    orderOrigin: new UpdateTitle('Auftragsherkünfte'),
};


const routes: Routes = [
  {
    path: '',
    component: OrdersComponent,
    canActivate: [AuthGuard],
    data: { title: TITLES.orders },
  },
  {
    path: PATHS.orderOrigin,
    component: OriginComponent,
    canActivate: [AuthGuard],
    data: { title: TITLES.orderOrigin },
  },
  {
    path: PATHS.orderOrigin + '/:id',
    component: OriginDetailComponent,
    canActivate: [AuthGuard],
    data: { title: TITLES.orderOrigin },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OrdersRoutingModule {
}
