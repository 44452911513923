<form
  [formGroup]="form"
  class="d-flex justify-content-between flex-wrap align-items-end"
  (submit)="submit($event)"
>
    <div class="form-group">
        <label class="me-2">Name</label>
        <input type="text" class="form-control" formControlName="label">
    </div>

    <div class="form-group">
        <im-file-input
          (ngModelChange)="onFileChange($event)"
          formControlName="document"
          accept="application/pdf"
        ></im-file-input>
    </div>

    <button type="submit" class="btn btn-primary mb-3">
        <im-icon name="plus"></im-icon>
        Neues Dokument
    </button>
</form>
