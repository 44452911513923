import { UsersModuleConfig, UsersModuleConfigService } from './im-modules/user/users/users.config';
import { hasRole, User } from './im-modules/user/users/User';
import { Roles } from './im-modules/user/users/Role';
import { Provider } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

/**
 * SB-spezifische Konfiguration des Users-Moduls.
 */
export class SBUsersModuleConfig implements UsersModuleConfig {
  public canAccess = (route: ActivatedRouteSnapshot, user: User) => {
    const lastRoute = route.pathFromRoot[route.pathFromRoot.length - 1];
    const possibleUserId = lastRoute.url.toString();

    if (possibleUserId === user.Id) {
      // Nutzer dürfen sich selbst sehen
      return true;
    }

    // BE & Super-Admins dürfen Benutzer bearbeiten SB-2770
    return hasRole(user, Roles.SUPER_ADMIN) || hasRole(user, Roles.BACKEND_ADMIN);
  }
}

/**
 * Sammlung aller SB-spezifischen Konfigurationsprovider der allgemeinen Module.
 */
export const SB_IM_MODULE_CONFIGURATION_PROVIDERS: Provider[] = [
  {
    provide: UsersModuleConfigService,
    useClass: SBUsersModuleConfig,
  },
];
