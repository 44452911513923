import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from '../user/auth/auth.guard';
import { MessagesComponent } from './messages/messages.component';
import { MessageDetailComponent } from './messages/message-detail/message-detail.component';
import { MessageCategoryOverviewComponent } from './message-categories/message-category-overview.component';
import { MessageCategoryDetailsComponent } from './message-categories/message-category-details.component';
import { UpdateTitle } from '../../common/generic/title/title.service';

export const PATHS = {
  messages: '',
  categories: 'categories',
};

export const TITLES = {
  messages: new UpdateTitle('Mitteilungen'),
  messageCategories: new UpdateTitle('Mittelungskategorien'),
};

const routes: Routes = [
  {
    path: PATHS.categories,
    component: MessageCategoryOverviewComponent,
    canActivate: [AuthGuard],
    data: { title: TITLES.messageCategories },
  },
  {
    path: PATHS.categories + '/:id',
    component: MessageCategoryDetailsComponent,
    canActivate: [AuthGuard],
    data: { title: TITLES.messageCategories },
  },
  {
    path: PATHS.messages,
    component: MessagesComponent,
    canActivate: [AuthGuard],
    data: { title: TITLES.messages },
  },
  {
    path: ':id',
    component: MessageDetailComponent,
    canActivate: [AuthGuard],
    data: { title: TITLES.messages },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MessagesRoutingModule {
}
