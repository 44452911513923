import { Component, ViewChild } from '@angular/core';
import { OverviewContainerComponent } from '../../../common/layout/overview-container/overview-container.component';

@Component({
  selector: 'im-product-categories',
  template: `
    <im-overview-container heading="Produktkategorien" buttonTextCreate="Neue Produktkategorie erstellen">
      <im-categories-overview *imOverviewContainerOverviewTemplate></im-categories-overview>
      <ng-container *imAdminOnly>
        <im-create-category
          *imOverviewContainerCreationFormTemplate
          (categoryCreated)="onCategoryCreated()"
        ></im-create-category>
      </ng-container>
    </im-overview-container>
  `,
})
export class CategoriesComponent {
  @ViewChild(OverviewContainerComponent)
  overviewContainer: OverviewContainerComponent;

  onCategoryCreated() {
    this.overviewContainer.switchView();
  }
}
