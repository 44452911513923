import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FilterBarService } from '../../common/api/filter-bar/filter-bar.service';
import { Auftr, AUFTR_ENDPOINT, AUFTR_EXPORT_ENDPOINT, isFinished } from '../customers/models/Auftr';
import { DATEPICKER_DE } from '../../config/locales';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import * as moment from 'moment';
import { DATE_QUERY_PARAM_FORMAT } from '../../config/dates';
import { displayUser, User, USER_ENDPOINT } from '../../im-modules/user/users/User';
import { ApiClient } from '../../common/api/ApiClient';
import { FileDownloadService } from '../../common/api/file-download.service';
import {Customer, CUSTOMER_ENDPOINT, CustomerAddress, showCustomerName} from '../customers/models/Customer';
import { FromDB } from '../../common/api/FromDB';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {SortTableService} from "../../common/api/sort-table/sort-table.service";

enum OrderFilterType {
  ONLY_AUFTRAEGE = 'auftraege',
  ONLY_OFFERS = 'angebote',
}

enum OrderFilterStatus {
  COMPLETED = 'abgeschlossen',
  NOT_COMPLETED = 'nicht abgeschlossen'
}

enum OrderFilterSBAX {
  EXPORTED = 'exportiert',
  NOT_EXPORTED = 'nicht exportiert',
  FAILED = 'fehlgeschlagen',
  WILL_EXPORT = 'wird exportiert',
}

const MAX_DOWNLOAD = 10000;

@Component({
  selector: 'sebu-orders-table',
  templateUrl: './orders-table.component.html',
  styleUrls: ['orders-table.component.scss'],
  providers: [
    FilterBarService,
  ],
})
export class OrdersTableComponent implements OnInit {
  public endpoint = AUFTR_ENDPOINT;
  @ViewChild('tableBody') tableBodyTemplate: TemplateRef<any>;
  displayUser = displayUser;
  displayCustomer = showCustomerName;
  allUsers: Array<User | undefined> = [];
  OrderFilterType = OrderFilterType;
  total = 0;

  isFinished = isFinished;
  de = DATEPICKER_DE;
  filters = new UntypedFormGroup({
    'order-date-from': new UntypedFormControl(),
    'order-date-to': new UntypedFormControl(),
    'order-user': new UntypedFormControl(),
    'order-type': new UntypedFormControl(),
    'order-number': new UntypedFormControl(),
    'order-customer': new UntypedFormControl(),
    'order-status': new UntypedFormControl(),
    'order-sbax': new UntypedFormControl(),
  });
  OrderFilterStatus = OrderFilterStatus;
  OrderFilterSBAX = OrderFilterSBAX;

  constructor(
    private filterBar: FilterBarService,
    private api: ApiClient,
    private fileDownloader: FileDownloadService,
    private router: Router,
    private toastr: ToastrService,
    public sortTable: SortTableService
  ) {
    this.filterBar.registerDeserializerForKey(
      'order-date-from',
      OrdersTableComponent.deserializeDateFromQueryParam,
    );
    this.filterBar.registerDeserializerForKey(
      'order-date-to',
      OrdersTableComponent.deserializeDateFromQueryParam,
    );
  }

  ngOnInit() {
    this.api.all<User>(USER_ENDPOINT).subscribe(allUsers => {
      this.allUsers = [undefined, ...allUsers];
    });
  }

  onDetailClicked(row: FromDB<Auftr>, event: MouseEvent) {
    event.preventDefault();

    this.api
      .show<Auftr & {
        Kunde: FromDB<Customer>,
      }>(AUFTR_ENDPOINT, row.Id)
      .subscribe(auftr => {
        const kundeId = auftr.Kunde.Id;
        this.router.navigateByUrl(`/customers/${kundeId}/order-history/${row.Id}`);
      });
  }

  downloadCsvExport() {
    if (this.total > MAX_DOWNLOAD) {
      this.toastr.error(
        "Der Export ist auf maximal 10000 Aufträge begrenzt! \n" +
        `Aktuelle Ergebnisse: ${this.total}\n`
        + "Bitte schränken Sie die Suche weiter ein."
      )
      return;
    }

    const filterValues = this.filterBar.deserializedFilterParams;
    const queryParams = this.filterBar.serializeFilterParams(filterValues);

    this.fileDownloader.download(
      AUFTR_EXPORT_ENDPOINT,
      queryParams,
    );
  }

  uploadDidFail(order: Auftr) {
    return order.ExportFailedAt !== null;
  }

  displaySBAXStatus(order: Auftr) {
    if (this.uploadDidFail(order)) {
      return `fehlgeschlagen`;
    }

    if (order.ExportedAt !== null) {
      return `exportiert`;
    }

    if (order.CompletedAt !== null) {
      return 'wird exportiert';
    }

    return '';
  }

  public static deserializeDateFromQueryParam(dateStr: string) {
    return moment(dateStr, DATE_QUERY_PARAM_FORMAT).toDate();
  }
}
