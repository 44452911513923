import {Component, Event, Property, Recur, Time, parse} from 'ical.js';
import * as moment from 'moment';
import {Injectable} from '@angular/core';
import {ICalData, NewICalAppointment} from './i-cal-appointment.service';
import {NO_REPETITION} from '../models/SupportedRepetitions';
import {UUID} from '../../../common/generic/UUID';
import {createTime} from './utils';
import {IcalQueryService} from './ical-query.service';
import {LogFactory} from '../../../common/generic/LogFactory';
import {SeasonHelper} from "../appointments/Helper/SeasonHelper";

let log;

/**
 * Service zum Erstellen und Bearbeiten von ICal-Strings
 */
@Injectable()
export class ICalEditService {
  constructor(private icalQuery: IcalQueryService, private seasonHelper: SeasonHelper) {
  }

  public createCalendar(): Component {
    const ical = new Component(['vcalendar', [], []]);
    ical.updatePropertyWithValue('prodid', '-//Service Bund Admin UI');
    ical.updatePropertyWithValue('version', '2.0');

    return ical;
  }

  public createVEvent(): Component {
    const vevent = new Component(['vevent', [], []]);
    vevent.addPropertyWithValue('DTSTAMP', Time.now().toICALString());

    return vevent;
  }

  public createEvent(vevent: Component, data: NewICalAppointment): Event | null {
    const event = new Event(vevent);

    event.summary = data.appointmentName;
    event.uid = UUID.generate();
    event.startDate = createTime(data.timeRangeStart, data.allDay);
    event.endDate = createTime(data.timeRangeEnd, data.allDay);

    if (data.appointmentLocation) {
      event.location = data.appointmentLocation;
    }

    if (data.repeats == NO_REPETITION) {
      return event;
    }

    let repetitionEnd = "";

    if (data.season === "") {
      repetitionEnd = moment(moment(data.repeatEnd).endOf('day')).format('YYYYMMDDTHHmmss');
    } else {

      let calculatedRepetitionEnd = this.repetitionEndOfAppointmentWithSeason(data);
      let calculatedStartDate = this.startDateOfRepeatingAppointmentWithSeason(data);

      if (calculatedRepetitionEnd === null || calculatedStartDate === null) {
        return null
      }

      let calculatedEndDate = createTime(this.createEndDate(calculatedStartDate, data), data.allDay)

      if (calculatedRepetitionEnd < calculatedEndDate.toJSDate()) {
        return null
      }

      repetitionEnd = moment(moment(calculatedRepetitionEnd).endOf('day')).format('YYYYMMDDTHHmmss');
      event.startDate = createTime(calculatedStartDate, data.allDay);
      event.endDate = calculatedEndDate
    }

    vevent.addPropertyWithValue('RRULE',
      `FREQ=${data.repeats};UNTIL=${repetitionEnd}`,
    );

    return event;
  }

  private createEndDate(calculatedStartDate: Date, data: NewICalAppointment) {
    let newEndDate = new Date(calculatedStartDate)
    newEndDate.setHours(data.timeRangeEnd.getHours());
    newEndDate.setMinutes(data.timeRangeEnd.getMinutes());
    return newEndDate
  }

  private startDateOfRepeatingAppointmentWithSeason(data: NewICalAppointment) {
    if (data.timeRangeStart < this.seasonHelper.currentSeasonEnd) {
      if (data.season === this.seasonHelper.currentSeason) {
        return data.timeRangeStart
      } else {
        return this.startDateInNextSeason(data);
      }
    } else {
      if (data.season !== this.seasonHelper.currentSeason) {
        return data.timeRangeStart
      } else {
        return null
      }
    }
  }

  private repetitionEndOfAppointmentWithSeason(data: NewICalAppointment) {
    let repeatEnd = data.repeatEnd!
    repeatEnd.setHours(23)
    repeatEnd.setMinutes(59)

    if (repeatEnd < this.seasonHelper.currentSeasonEnd) {
      if (data.season === this.seasonHelper.currentSeason) {
        return repeatEnd
      } else {
        return null;
      }
    } else {
      if (data.season !== this.seasonHelper.currentSeason) {
        return repeatEnd
      } else {
        return this.seasonHelper.currentSeasonEnd
      }
    }
  }


  /**
   * Returns the next date in the next season having same weekday like timeRangeStart
   */
  private startDateInNextSeason(data: NewICalAppointment) {
    const dayOfOrginialStartDate = data.timeRangeStart.getDay();
    let newStartDate = new Date(this.seasonHelper.nextSeasonStart);
    newStartDate.setHours(data.timeRangeStart.getHours())
    newStartDate.setMinutes(data.timeRangeStart.getMinutes())
    let dayOfNewSeasonDate = newStartDate.getDay();

    while (dayOfNewSeasonDate !== dayOfOrginialStartDate) {
      newStartDate.setDate(newStartDate.getDate() + 1);
      dayOfNewSeasonDate = newStartDate.getDay();
    }

    return newStartDate;
  }

  /**
   * Erstellt eine neue Instanz eines Termins einer Reihe, bei der bestimmte
   * Werte verändert sind. Diese Methode sollte nicht zum Löschen einer Instanz
   * einer Reihe verwendet werden!
   *
   * @param {"ical.js".Event} event
   * @param {"ical.js".Time} recurrenceId
   * @param {Partial<ICalData>} newData
   * @returns {"ical.js".Event}
   */
  public createOccurenceException(event: Event, recurrenceId: Time, newData: Partial<ICalData>): Event {
    event.strictExceptions = true; // UID **immer** validieren
    log(`Erstelle neue Ausnahme für ${event.summary}...`);

    const exception = new Event();
    exception.component.addPropertyWithValue('dtstamp', Time.now());

    // Exception mit default-Werten vom event generieren
    this.copyEventData(event, exception);
    this.updateEventData(exception, newData);

    exception.uid = event.uid;
    exception.recurrenceId = recurrenceId;
    event.relateException(exception);

    // Dem Kalender die Exception hinzufügen
    event.component.parent.addSubcomponent(exception.component);

    return exception;
  }

  /**
   * Löscht eine Instanz am angegebenen Tag eines sich wiederholenden Termins durch das Setzen des
   * EXDATE-Attributs.
   *
   * @param {"ical.js".Event} event
   * @param {Date} day
   * @returns {boolean} Ob die Instanz gelöscht wurde
   */
  public deleteInstance(event: Event, day: Date | Time): boolean {
    let recurrence: Time;

    if (day instanceof Date) {
      recurrence = this.icalQuery.getRecurrenceIdOnDay(event, day);

      if (!recurrence) {
        return false;
      }
    } else {
      recurrence = day;
    }

    const eventDefinitions = event.component.parent
      .getAllSubcomponents('vevent')
      .map(vevent => new Event(vevent));
    const seriesDefinition = eventDefinitions
      .filter(entry => !entry.isRecurrenceException())[0];

    seriesDefinition.component.addPropertyWithValue(
      'EXDATE',
      recurrence,
    );

    // Falls es eine Ausnahme für diesen Tag gibt, muss diese gelöscht werden (SB-3842
    const seriesException = eventDefinitions.filter(entry =>
        entry.isRecurrenceException() && entry.recurrenceId.toICALString() === recurrence.toICALString()
    ).pop();

    if (seriesException) {
      event.component.parent.removeSubcomponent(seriesException.component);
    }

    return true;
  }

  /**
   * Löscht eine Exception am angegebenen Tag eines sich wiederholenden Termins
   *
   * @returns {boolean} Ob die Instanz gelöscht wurde
   * @param calendar
   * @param targetDate
   */
  public deleteException(calendar: Component, targetDate: Time): Component {
    let newCalendar = this.createCalendar()
    let events = calendar.getAllSubcomponents('vevent');
    for (let i = 0; i < events.length; i++) {
      let event = new Event(events[i]);
      if (!(event.recurrenceId && event.recurrenceId.toString() === targetDate.toString())) {
        newCalendar.addSubcomponent(events[i]);
      }
    }
    return newCalendar;
  }

  /**
   * Aktualisiert ein Event mit Daten und greift auf einen optionales "Fallback"
   * Event zurück, falls die neuen Daten nicht vollständig vorhanden sind.
   *
   * @param {"ical.js".Event} target
   * @param {Partial<ICalData>} newData
   * @param calendar
   * @returns {"ical.js".Event}
   */
  public updateEventData(target: Event, newData: Partial<ICalData>, calendar?: Component) {
    let timeRangeStartWasEdited = false;
    let rruleUntilWasEdited = false;
    const oldTimeRangeStart = target.startDate;

    const allDay = newData.allDay !== undefined && newData.allDay !== null
      ? newData.allDay
      : target.startDate.icaltype === 'date';

    if (newData.timeRangeStart) {
      timeRangeStartWasEdited = true;
      target.startDate = createTime(newData.timeRangeStart, allDay);
    }

    if (newData.timeRangeEnd) {
      target.endDate = createTime(newData.timeRangeEnd, allDay);
    }

    if (newData.appointmentName) {
      target.summary = newData.appointmentName;
    }

    if (newData.appointmentLocation) {
      target.location = newData.appointmentLocation;
    }

    // Falls nötig, Wiederholungen setzen
    if (newData.repeats === NO_REPETITION) {
      if (target.component.hasProperty('rrule')) {
        target.component.removeAllProperties('rrule');
      }
    } else if (newData.repeatEnd instanceof Date) {
      rruleUntilWasEdited = true;
      target.component.updatePropertyWithValue('rrule', {
        freq: newData.repeats,
        until: moment(newData.repeatEnd).endOf('day').format('YYYY-MM-DD\THH:mm:ss'),
      });
    }

    // Wird eine Terminreihe editiert, so kann sich der Startzeitpunkt der Terminreihe ändern. In diesem Fall sind
    // alle Recurrence-Ids der bearbeiteten Termininstanzen ungültig.
    if (target.isRecurring() && timeRangeStartWasEdited && calendar) {
      // Positiv -> Event wurde nach hintern verschoben, Negativ -> Event wurde nach vorne verschoben
      const shiftedTime = target.startDate.toJSDate().getTime() - oldTimeRangeStart.toJSDate().getTime();
      const events = calendar
        .getAllSubcomponents('vevent')
        .map(component => new Event(component));
      const seriesDefinition = events.filter(event => !event.isRecurrenceException())[0];
      const exceptions = events.filter(event => event.isRecurrenceException());

      this.shiftExdates(seriesDefinition, shiftedTime);
      this.shiftRecurrenceIds(exceptions, shiftedTime);

      // Hat der Nutzer nicht manuell das Enddatum des Termins angepasst, führen wir hier die Anpassung durch
      // und setzen diesen auf die Differenz, um die das Event nach vorne oder hinten verschoben wurde.
      if (!rruleUntilWasEdited) {
        this.shiftRRule(seriesDefinition, shiftedTime);
      }
    }

    return target;
  }

  /**
   * Verschiebt die gelöschten Termine. Beispiel (X = Termin kommt vor):
   * Tag:         1. 2. 3. 4. 5. 6. 7. 8.
   * Vorkommen:   X     X     X
   *
   * Terminstart wird um 1 Tag nach hinten verschoben, also auf den 2.
   *
   * Tag:         1. 2. 3. 4. 5. 6. 7. 8.
   * Vorkommen:      X     X     X
   *
   * @param seriesDefinition
   * @param shiftedTime
   */
  private shiftExdates(seriesDefinition: Event, shiftedTime: number) {
    seriesDefinition.component.getAllProperties('exdate').forEach(exDateProperty => {
      const newExDates = exDateProperty.getValues().map((exDate: Time) => {
        return Time.fromJSDate(new Date(exDate.toJSDate().getTime() + shiftedTime));
      });

      exDateProperty.setValues(newExDates);
    });
  }

  private shiftRecurrenceIds(exceptions: Event[], shiftedTime: number) {
    exceptions.forEach(exception => {
      exception.recurrenceId = Time.fromJSDate(
        new Date(exception.recurrenceId.toJSDate().getTime() + shiftedTime),
      );
    });
  }

  private shiftRRule(seriesDefinition: Event, shiftedTime: number) {
    const untilProp = seriesDefinition.component
      .getFirstProperty('rrule')
      .getFirstValue() as Recur;
    untilProp.until = Time.fromJSDate(new Date(untilProp.until.toJSDate().getTime() + shiftedTime));

    seriesDefinition.component.updatePropertyWithValue('rrule', untilProp);
  }

  /**
   * Kopiert die Daten vom einen Event in ein anderes.
   *
   * @param {module:ical.js.Event} source
   * @param {module:ical.js.Event} target
   */
  private copyEventData(source: Event, target: Event) {
    /** Diese Werte können einfach kopiert werden */
    const simpleCopyKeys: Array<keyof Event> = ['summary', 'location', 'startDate', 'endDate'];
    /** Diese Werte werden nicht von ICal.js unterstützt und müssen aus den Properties kopiert werden */
    const propertyCopyKeys = ['geo'];

    simpleCopyKeys.forEach(key => {
      const value = source[key];
      if (value) {
        target = Object.assign(target, {[key]: value});
      }
    });

    propertyCopyKeys.forEach(key => {
      if (source.component.hasProperty(key)) {
        const sourcePropertyValues = source.component.getFirstProperty(key).getValues();

        // Wert aktualisieren oder neu hinzufügen
        if (target.component.hasProperty(key)) {
          target.component.getFirstProperty(key).setValue(sourcePropertyValues);
        } else {
          const newProperty = new Property(key);
          newProperty.setValue(sourcePropertyValues);
          target.component.addProperty(newProperty);
        }
      }
    });
  }

  updatedDataForSeason(data: NewICalAppointment): NewICalAppointment | null {
    if(data.season === "") {
      return data
    }

    let calculatedRepetitionEnd = this.repetitionEndOfAppointmentWithSeason(data);
    let calculatedStartDate = this.startDateOfRepeatingAppointmentWithSeason(data);

    if (calculatedRepetitionEnd === null || calculatedStartDate === null) {
      return null
    }

    let calculatedEndDate = createTime(this.createEndDate(calculatedStartDate, data), data.allDay).toJSDate()
    if (calculatedRepetitionEnd < calculatedEndDate) {
      return null
    }

    data.repeatEnd = calculatedRepetitionEnd
    data.timeRangeStart = createTime(calculatedStartDate, data.allDay).toJSDate();
    data.timeRangeEnd = calculatedEndDate;

    return data;
  }

  public removeExceptionsNotInTimerange(icalOfOldUser: string,from:Date, to:Date ) {
    let oldCalendar = parse(icalOfOldUser);
    let oldComponent = new Component(oldCalendar);
    let newCalendar = this.createCalendar()

    let events = oldComponent.getAllSubcomponents('vevent');
    for (let i = 0; i < events.length; i++) {
      let event = new Event(events[i]);
      if ((event.recurrenceId === null) || ((from < event.recurrenceId.toJSDate()) && (event.recurrenceId.toJSDate() < to))) {
        newCalendar.addSubcomponent(events[i]);
      }
    }
    return newCalendar.toString();
  }
}

log = LogFactory.create(ICalEditService);
