import { Inject, Injectable } from '@angular/core';
import { EntityDependantsService } from '../../../common/api/entity-dependants.service';
import { HTTP_INTERCEPTORS, HttpErrorResponse } from '@angular/common/http';
import { MESSAGE_CATEGORY_ENDPOINT, MessageCategory } from '../models/MessageCategory';
import { FromDB } from '../../../common/api/FromDB';
import { ApiClient } from '../../../common/api/ApiClient';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import autobind from 'autobind-decorator';
import { AjaxErrorToastrInterceptor } from '../../../common/api/ajax-error-toastr.interceptor';

@Injectable({
  providedIn: 'root',
})
export class MessageCategoryService {
  /**
   * Hiermit ist die Url der Frontend-Route gemeint.
   * @TODO: Url hier aus der Config auslesen!
   */
  static get messagesBaseUrl() {
    return '/messages';
  }

  constructor(
    protected api: ApiClient,
    protected toastr: ToastrService,
    protected router: Router,
    protected categoryHasDependants: EntityDependantsService,
    @Inject(HTTP_INTERCEPTORS) private interceptors,
  ) { }

  private get errorToastr() {
    return this.interceptors.filter(i => i instanceof AjaxErrorToastrInterceptor)[0];
  }

  private get hasAjaxInterceptor() {
    return this.interceptors
      .filter(i => i instanceof AjaxErrorToastrInterceptor)
      .length > 0;
  }

  private silenceAjaxInterceptor() {
    if (this.hasAjaxInterceptor) {
      this.errorToastr.ignore(this.api.normalize(MESSAGE_CATEGORY_ENDPOINT));
    }
  }

  private unSilenceAjaxInterceptor() {
    if (this.hasAjaxInterceptor) {
      this.errorToastr.ignore(this.api.normalize(MESSAGE_CATEGORY_ENDPOINT));
    }
  }

  /**
   * Löscht die Nachrichtenkategorie.
   *
   * @param messageCategory
   */
  public destroy(messageCategory: FromDB<MessageCategory>) {
    this.silenceAjaxInterceptor();
    return this.api
      .destroy(MESSAGE_CATEGORY_ENDPOINT, messageCategory.Id)
      .pipe(
        catchError(response => {
          if (response instanceof HttpErrorResponse) {
            this.unSilenceAjaxInterceptor();
            if (response.status === 422) {
              // Kategorie wird noch von Nachrichten verwendet. Es wird ein
              // Objekt mit der Id und der Nachricht übergeben.
              this.showCategoryHasDependantsNotification(response);
            }
          }

          return throwError(response);
        }),
      );
  }

  @autobind
  public onCategoryDestroyed(navigate = true) {
    this.toastr.success('Kategorie erfolgreich gelöscht!');

    if (navigate) {
      this.router.navigate([MessageCategoryService.messagesBaseUrl]);

    }
  }

  /**
   * Zeigt einen Toast mit den Nachriten an, die noch von der zu löschenden
   * Kategorie abhängen.
   *
   * @param response
   */
  public showCategoryHasDependantsNotification(response: HttpErrorResponse) {
    this.categoryHasDependants.notify<{
      Id: string,
      title: string,
    }>(
      response,
      message => {
        const messageUrl = [
          MessageCategoryService.messagesBaseUrl,
          message.Id,
        ].join('/');

        return `<a class="text-danger" href="${messageUrl}">
          ${message.title.slice(0, 10)}
        </a>`;
      },
      'Diese Kategorie wird noch in folgenden Nachrichten verwendet',
    );
  }
}
