<im-filter-bar [filterGroup]="filters" [formGroup]="filters">
  <label class="me-2 ms-3" for="order-date-from">
    Auftragsdatum von
  </label>
  <p-calendar
    id="order-date-from" class="me-2"
    formControlName="order-date-from"
    [locale]="de" dateFormat="dd.mm.yy" showButtonBar="true"
  ></p-calendar>

  <label class="me-2" for="order-date-to">
    Auftragsdatum bis
  </label>
  <p-calendar
    id="order-date-to" class="me-2"
    formControlName="order-date-to"
    [locale]="de" dateFormat="dd.mm.yy" showButtonBar="true"
  ></p-calendar>
</im-filter-bar>

<div class="w-100">
  <im-data-table class="w-100" [endpoint]="endpoint" [constantParams]="dataTableParams" [bodyTemplate]="tableBodyTemplate">
    <ng-template imDataTableHeaders>
      <th class="text-center">Auftragsnummer</th>
      <th class="text-center">Auftragsdatum</th>
      <th class="text-center">Lieferdatum</th>
      <th class="text-center">Wert</th>
      <th class="text-center">Status</th>
      <th class="text-center actions-column">Aktionen</th>
    </ng-template>
  </im-data-table>

  <ng-template #tableBody let-rows>
    <tr *ngFor="let row of rows" [class.table-danger]="!isFinished(row)">
      <td class="text-center">{{ row.NotepadAuftragsNr }}</td>
      <td class="text-center">{{ row.Auftragsdatum | sbDate:true }}</td>
      <td class="text-center">{{ row.Lieferdatum | sbDate:true }}</td>
      <td class="text-right">{{ row.Auftragswert | currency:'EUR':'symbol' }}</td>
      <td class="text-center">{{ isFinished(row) ? 'abgeschlossen' : 'nicht abgeschlossen' }}</td>
      <td class="text-center">
        <div class="btn-group">
          <button imDetailButton [model]="row">Details</button>
        </div>
      </td>
    </tr>
  </ng-template>
</div>

