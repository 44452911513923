<h3 class="h3 mt-3 mb-3">E-Mails Neukunden Antrag</h3>

<div class="mb-5">
  <div class="input-group">
    <input type="text"
           name="comment"
           class="form-control"
           [formControl]="this.newEntity.get('email')"
           [ngClass]="{'is-invalid' : this.newEntity.invalid && this.newEntity.get('email')?.dirty}"
            placeholder="E-Mail">
      <button class="btn btn-outline-primary" (click)="add()">Hinzufügen</button>
    <div *ngIf="this.newEntity.invalid && this.newEntity.get('email')?.dirty" class="invalid-feedback">
      Es muss eine gültige E-Mail angegeben sein
    </div>
  </div>
</div>

<h4 class="d-flex flex-row">
  <span class="text-nowrap me-4">Vorhandene Einträge</span>
  <input type="text"
    name="search"
    class="form-control "
    placeholder="Suchen"
    (keyup)="changeSearch($event)">
</h4>

<div class="table-responsive mt-3">
  <table class="table table-striped">
    <caption *ngIf="getFormGroupListIds().length === 0" class="text-center">Keine Einträge vorhanden</caption>
    <thead>
    <tr>
      <th>E-Mail</th>
      <th>Aktion</th>
    </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let key of getFormGroupListIds()">
        <tr *ngIf="!(this.search !== '' && this.formGroupList.get([key, 'email'])?.value.indexOf(this.search) === -1)">
          <td>{{this.formGroupList.get([key, 'email'])?.value}}</td>
          <td>
            <div class="btn-group">
              <button data-id="{{ key }}" (click)="delete($event)" class="btn btn-outline-danger">Löschen</button>
            </div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
