import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { filter, flatMap, map, scan } from 'rxjs/operators';

/**
 * Instruktion, den Titel der Seite anzupassen.
 * Wird in der Routendefinition unter data.title übergeben, sodass beim Navigieren auf
 * eine Route der Titel entsprechend angepsst wird. Der detail-Part kann auch manuell
 * in einer Detailkomponente über den TitleService gesetzt werden.
 * Um den detail-Part explizit zurückzusetzen, beispielsweise auf einer Übersicht, kann ein leerer String übergeben werden.
 */
export class UpdateTitle {
  constructor(
    public section: null | string = '',
    public detail: null | string = '',
  ) {
  }
}

/**
 * Service zum Anpassen des Titels.
 * Damit dieser korrekt funktioniert, muss die Methode `startListening` einmal im AppComponent
 * aufgerufen werden! Dannach reagiert der Service auf Navigationsevents und passt den Titel entsprechend an.
 * Dazu sollte
 */
@Injectable({
  providedIn: 'root',
})
export class TitleService {
  /**
   * Initial gesetzter Titel beim Erzeugen des Services.
   * Wird als Ausganspunkt für alle weitere Titel verwendet.
   */
  private baseTitle = '';
  private _section = '';
  private _detail = '';

  constructor(private title: Title, private router: Router, private activatedRoute: ActivatedRoute) {
    this.baseTitle = title.getTitle();
  }

  /**
   * Registriert den Listener für Routenänderungen.
   * Diese Funktion sollte im AppComponent aufgerufen werden, um sicher zu gehen, dass sich der Titel
   * auch immer korrekt updated.
   * Diese Methode kann nicht im Constructor dieses Services aufgerufen werden, da sonst nicht alle Navigationsevents
   * registriert werden!
   */
  public startListening() {
    this.currentTitleSegment.subscribe(newTitle => {
      this.section = newTitle.section !== null
        ? newTitle.section
        : this.section;
      this.detail = newTitle.detail !== null
        ? newTitle.detail
        : this.detail;
    });
  }

  public get section() {
    return this._section;
  }

  public set section(section: string) {
    this._section = section;
    this.updateTitle();
  }

  public get detail() {
    return this._detail;
  }

  public set detail(detail: string) {
    this._detail = detail;
    this.updateTitle();
  }

  /**
   * Aktualisiert den Titel.
   */
  private updateTitle() {
    this.title.setTitle(this.computeNewTitle());
  }

  /**
   * Baut sich aus Basis, Abschnitt und Detail den Titel zusammen.
   */
  private computeNewTitle() {
    let newTitle = this.baseTitle;

    if (this.section !== '') {
      newTitle = `${this.section} | ${newTitle}`;
    }

    if (this.detail !== '') {
      newTitle = `${this.detail} - ${newTitle}`;
    }

    return newTitle;
  }

  /**
   * Änderungen zum aktuellen Titel basierend auf Navigationsevents.
   * Abboniert die Routerevents und baut sich aus der neuen Route den neuen Titel zusammen.
   *
   * Da sich der Routen-Baum hierarchisch aufbaut, kann man das Setzen des Section-Parts bei
   * Child-Routen weglassen.
   */
  private get currentTitleSegment() {
    return this.router.events
      .pipe(
        // Nur erfolgreiche Navigationsevents berücksichtigen
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        // Daten zu allen Parent-Routen sammeln
        flatMap(route => {
          // Standardmäßig bestehende Section und Detail zurücksetzen, sodass einfach der Basistitel
          // angezeigt wird, wenn man keinen explizit setzt. Würde dies nicht gemacht werden, so könnte
          // es sein, dass ein alter Titel einer vorherigen Seite angezeigt wird, wenn man auf eine Seite
          // navigiert, die nicht explizit eine Section oder Detail setzt!
          // Daher ist hier von Beginn an ein Wert im Array.
          const routeData: Data[] = [{ title: new UpdateTitle() }];

          while (route.firstChild) {
            route.data.subscribe(data => routeData.push(data));
            route = route.firstChild;
          }

          // Die letzte Route wird nicht in der while-Schleife erfasst, da die letzte Route
          // ja kein `firstChild` besitzt. Daher nachtragen.
          route.data.subscribe(data => routeData.push(data));

          return routeData;
        }),
        // Die Titel-Attribute herausfiltern
        filter(data => data.title instanceof UpdateTitle),
        map<{ title: UpdateTitle }, UpdateTitle>(data => data.title),
        // Titel mergen, Sections im Falle section = null vom Parent übernehmen
        scan((newTitle: UpdateTitle, currentTitle: UpdateTitle) => {
          // Der Section-Part wird vom Parent übernommen, falls ein Child diesen nicht setzt
          newTitle.section = currentTitle.section !== null
            ? currentTitle.section
            : newTitle.section;
          newTitle.detail = currentTitle.detail;

          return newTitle;
        }),
      );
  }
}
