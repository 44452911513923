import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaginationMetadata } from './PaginationMetadata';
import { ApiClient } from '../ApiClient';
import { Observable, Subscription } from 'rxjs';
import { DataTableService } from '../data-table/data-table.service';
import { map } from 'rxjs/operators';

interface PaginationEntry {
  params: { page: number } | null;
  label: number|string;
  active: boolean;
  disabled: boolean;
}

@Component({
  selector: 'im-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit, OnDestroy {
  public padding = 2;
  public isLoading = false;
  public meta: PaginationMetadata;
  private subs = new Subscription();
  @Output() private triggerRender = new EventEmitter();

  constructor(private router: Router, private dataTable: DataTableService) {
  }

  static getCurrentPageUrl(route: ActivatedRoute, endpoint: string): Observable<string> {
    return route.queryParams
      .pipe(
        map(params => {
          const template = new URL(`${location.origin}${ApiClient.normalizeEndpoint(endpoint)}`);

          Object.entries(params).forEach(([key, value]) => {
            template.searchParams.set(key, value);
          });

          return template.toString();
        }),
      );
  }

  ngOnInit() {
    this.subs.add(this.dataTable.loadingStartedEvent.subscribe(() => {
      this.isLoading = true;
    }));

    this.subs.add(this.dataTable.loadingFinishedEvent.subscribe(() => {
      this.isLoading = false;
    }));

    this.subs.add(this.dataTable.pageLoadedEvent.subscribe(meta => {
      this.meta = meta;
      this.triggerRender.next(null);
    }));
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  loadPage(page: number) {
    this.router.navigate([], {
      queryParams: {
        [this.meta.pageName]: page,
      },
      queryParamsHandling: 'merge',
    });
  }

  entryIsCurrent(entry: PaginationEntry) {
    return this.isReady && entry.params && entry.params.page === this.meta.getCurrentPageNum();
  }

  get isReady() {
    return Boolean(this.meta);
  }

  get entries(): Array<PaginationEntry> {
    if (!this.isReady) {
      return [];
    }

    const entries: Array<PaginationEntry> = [];
    const validPages: Array<number> = [];

    const currentPage = this.meta.getCurrentPageNum();
    const lastPage = this.meta.getNumPages();
    const left = currentPage - this.padding;
    const right = currentPage + this.padding + 1;
    const hasLeftDots = currentPage > 2 * this.padding;
    const hasRightDots = currentPage < lastPage - 2 * this.padding;

    for (let i = 1; i <= lastPage; i++) {
      if (i === 1 || i === lastPage || i >= left && i < right) {
        validPages.push(i);
      }
    }

    for (const pageNumber of validPages) {
      if (pageNumber === lastPage && hasRightDots) {
        entries.push({
          params: null,
          disabled: true,
          active: false,
          label: '...',
        });
      }

      entries.push({
        params: { page: pageNumber },
        disabled: false,
        active: pageNumber === currentPage,
        label: pageNumber,
      });

      if (pageNumber === 1 && hasLeftDots) {
        entries.push({
          params: null,
          disabled: true,
          active: false,
          label: '...',
        });
      }
    }

    return entries;
  }
}

