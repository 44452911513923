import { Injectable } from '@angular/core';
import { ApiClient } from '../../../../common/api/ApiClient';
import { APP_SETTINGS_ENDPOINT, AppSetting } from '../../../user/app-settings/models/AppSetting';
import { FromDB } from '../../../../common/api/FromDB';
import { LogFactory } from '../../../../common/generic/LogFactory';
import { Item } from '../../models/Item';
import { BehaviorSubject, merge, ReplaySubject } from 'rxjs';
import * as moment from 'moment';
import { map } from 'rxjs/operators';

let log;

@Injectable({
  providedIn: 'root',
})
export class ProductIsNewService {
  /**
   * Der Key des AppSettings, der die Anzahl der Tage enthält, die ein Produkt als 'neu' markiert wird.
   */
  public static appSettingKey = 'AmountDaysPresentationIsMarkedAsNew';

  /**
   * Observable, welche die konfigurierte Anzahl der Tage zurückgibt, die ein Produkt als neu markiert werden sollte.
   * Hier wird ein ReplaySubject verwendet, da der Service potentiell noch nicht initialisiert ist, wenn `isProductNew`
   * aufgerufen wird. Ein ReplaySubject emitted erst eine value, wenn es zum ersten Mal einen Wert enthält. So wird
   * an dieser Stelle auf die Initialisierung des Services gewartet.
   */
  public amountDaysMarkedAsNew$ = new ReplaySubject<number>();

  constructor(private api: ApiClient) {
    this.load();
  }

  /**
   * Erkennt, ob ein Produkt neu ist, oder nicht.
   * Es wird an dieser Stelle keine Nummer zurückgegeben, da der Service potentiell noch nicht initialisiert ist.
   * Es kann also einfach mit Hilfe der async-Pipe verwendet werden.
   * @param product
   */
  public isProductNew(product: FromDB<Item>) {
    return merge(
      // Initial false emitten, da der Service potentiell noch nicht initialisiert ist.
      new BehaviorSubject(false),

      // Wenn der Service initialisiert ist, den korrekten Wert berechnen.
      this.amountDaysMarkedAsNew$
        .pipe(
          map((amountDaysMarkedAsNew) => {
            const lastDayProductIsNew = moment(product.creationDate).add(amountDaysMarkedAsNew, 'days');
            return moment().isSameOrBefore(lastDayProductIsNew, 'day');
          }),
        ),
    );
  }

  /**
   * Ruft alle AppSettings ab und extrahiert die für den Service nötigen Informationen.
   */
  public load() {
    this.api
      .all<AppSetting>(APP_SETTINGS_ENDPOINT)
      .subscribe(settings => {
        const amountDaysMarkedAsNew = ProductIsNewService.extractAmountDaysMarkedAsNew(settings);

        if (amountDaysMarkedAsNew === null) {
          log('Anzahl der Tage, die ein Produkt neu ist konnte nicht festgestellt werden! Kein Produkt wird als neu markiert!');
        } else {
          this.amountDaysMarkedAsNew$.next(amountDaysMarkedAsNew);
        }
      });
  }

  /**
   * Durchsucht die aus der DB erhaltenen AppSettings nach der Einstellung, die definiert, wie lange ein
   * Produkt als 'neu' markiert werden sollte. Kann dies nicht ermittelt werden, wird null zurückgegeben.
   * @param settings
   */
  private static extractAmountDaysMarkedAsNew(settings: FromDB<AppSetting>[]) {
    for (const setting of settings) {
      if (setting.key === ProductIsNewService.appSettingKey) {
        return parseInt(setting.value, 10);
      }
    }

    return null;
  }
}

log = LogFactory.create(ProductIsNewService);
