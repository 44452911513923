import {
  Component,
  ContentChild,
  Directive,
  ElementRef, EventEmitter,
  Input,
  OnDestroy,
  OnInit, Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { LaravelPaginationMetadata } from '../paginator/LaravelPaginationMetadata';
import { emitLoadingError } from '../loading-error-container/loading-error-container.component';
import { LaravelPaginatedResponse } from '../LaravelPaginatedResponse';
import { HttpClient } from '@angular/common/http';
import { DataTableService } from './data-table.service';
import { Subscription, timestamp } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import { ApiClient } from '../ApiClient';
import { catchError, tap } from 'rxjs/operators';

@Directive({
  selector: '[imDataTableHeaders]',
})
export class DataTableHeadersDirective {
}

@Directive({
  selector: '[imDataTableRow]',
})
export class DataTableRowDirective<T> {
}

/**
 * Eine Komponente zum Anzeigen tabelarischer Daten.
 * Es können Wahlweise Spalten oder ein gesamtes Tabellenbody-Template definiert werden.
 * Der angegebene Endpunkt wird automatisch angefragt, die Ergebnisse sollten paginierbar sein.
 * Um die Anfragen zu parametrisieren, sollte die URL verwendet werden, die Komponente schickt alle Query-Parameter
 * bei der Request mit. So kann man garantieren, dass man auf bestimmte Seiten der DataTable auch sauber verlinken kann.
 * Da ein Filter oder eine Sortierung die Position eines Datensatzes in der Tabelle ändern kann, ist es wichtig, dass
 * solche Parameter in der URL stehen.
 * Zum Bereitstellen von Parametern kann man sich die FilterBar oder den Paginator anschauen.
 * Zur Filterung sollte die FilterBarComponent verwendet werden.
 */
@Component({
  selector: 'im-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DataTableComponent implements OnInit, OnDestroy {
  @ContentChild(DataTableHeadersDirective, { read: TemplateRef }) headerTemplate;
  @ContentChild(DataTableRowDirective, { read: TemplateRef }) rowTemplate;
  @ViewChild('defaultBodyTemplate') defaultBodyTemplate: TemplateRef<any>;

  /** Der anzufragende Endpunkt. Sollte eine Paginierung unterstützen. */
  @Input() endpoint = '';
  @Input() constantParams: Params = {};
  @Input() bodyTemplate: TemplateRef<any>;
  @Input() emptyStateMessage = 'Die Anfrage lieferte keine Ergebnisse.';

  @Output() private total = new EventEmitter();

  /** Die Daten, aus denen die Reihen der Tabelle generiert werden */
  data: any[] = [];
  /** Metadaten zur Paginierung, wie Anzahl der Seiten usw. */
  meta: LaravelPaginationMetadata;
  caption: string;
  /** Die zuletzt erfolgreich geladene URL. So kann man bei einem Neuladen einfach diese URL anfragen. */
  private lastPageSuccessfullyLoaded = '';
  public isLoading = false;
  private subscriptions = new Subscription();
  private _params: Params;

  public get params() {
    return {
      ...this.constantParams,
      ...this._params,
    };
  }

  public set params(params: Params) {
    this._params = params;
  }

  constructor(
    private http: HttpClient,
    private element: ElementRef,
    private service: DataTableService,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.subscriptions.add(this.service._reloadEvent.subscribe(params => {
      this.loadPage(params);
      window.scrollTo(0, 0);
    }));

    this.route.queryParams.subscribe(params => {
      this.params = params;
      this.loadPage(params);
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public loadPage(params?: Params | string | null | undefined) {
    const url = params
      ? this.getUrlFromParams(params)
      : this.lastPageSuccessfullyLoaded;

    this.service.loadingStartedEvent.next(null);
    this.isLoading = true;

    return this.http
      .get<LaravelPaginatedResponse<any>>(url)
      .pipe(
        tap(() => this.isLoading = false),
        catchError(err => emitLoadingError(this.element, err)),
      )
      .subscribe((response: LaravelPaginatedResponse<any>) => {
        this.meta = new LaravelPaginationMetadata(response);
        this.data = response.data;
        this.lastPageSuccessfullyLoaded = url;
        this.service.pageLoadedEvent.next(this.meta);
        this.service.loadingFinishedEvent.next(null);
        this.total.emit(response.total);
      }, () => {
        this.service.loadingFinishedEvent.next(null);
      });
  }

  /**
   * Die anzufragende URL wird aus dem übergebenen Objekt ermittelt. Dabei wird angenommen, dass die einzelnen Strings
   * noch nicht als URIComponent encoded wurden!
   *
   * @param {Params | string} params
   * @returns {string}
   */
  private getUrlFromParams(params: Params | string): string {
    if (typeof params === 'string') {
      // Wird direkt ein String übergeben, wird angenommen, dass es sich um eine valide URL handelt
      return params;
    }

    const newParams = {
      ...this.params,
      ...params,
    };

    const paramString = this.serializeQueryString(newParams);

    return ApiClient.normalizeEndpoint(this.endpoint + paramString);
  }

  private serializeQueryString(params: Params) {
    if (!params || Object.keys(params).length === 0) {
      return '';
    }

    return '?' + Object.keys(params).map(key => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
    }).join('&');
  }
}
