import { AuthGuard } from './im-modules/user/auth/auth.guard';
import * as Paths from './sebu.paths';
import { HomeComponent } from './sebu/home/home.component';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { LoginComponent } from './im-modules/user/auth/login/login.component';
import { UpdateTitle } from './common/generic/title/title.service';

const routes: Routes = [
  {
    path: Paths.CALENDAR,
    loadChildren: () => import('./im-modules/calendar/calendar.module').then(x => x.CalendarModule),
  },

  {
    path: Paths.USERS,
    loadChildren: () => import('./im-modules/user/users/users.module').then(x => x.UsersModule),
  },

  {
    path: Paths.MESSAGES,
    loadChildren: () => import('./im-modules/messages/messages.module').then(x => x.MessagesModule),
  },

  {
    path: Paths.PRESENTATION,
    loadChildren: () => import('./im-modules/presentation/presentation.module').then(x => x.PresentationModule),
  },

  {
    path: Paths.CUSTOMERS,
    loadChildren: () => import('./sebu/customers/customers.module').then(x => x.CustomersModule),
  },

  {
    path: Paths.SETTINGS,
    loadChildren: () => import('./sebu/settings/settings.module').then(x => x.SettingsModule),
  },

  {
    path: Paths.ARTICLES,
    loadChildren: () => import('./sebu/articles/articles.module').then(x => x.ArticlesModule),
  },

  {
    path: Paths.ORDERS,
    loadChildren: () => import('./sebu/orders/orders.module').then(x => x.OrdersModule),
  },

  {
    path: Paths.LOGIN,
    component: LoginComponent,
    data: { title: new UpdateTitle('Login') },
  },

  {
    path: Paths.HOME,
    component: HomeComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
        enableTracing: false,  // <-- debugging purposes only)
        preloadingStrategy: PreloadAllModules,
      },
    ),
  ],
  exports: [RouterModule],
  providers: [],
})
export class ServiceBundRoutingModule {
}
