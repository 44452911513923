import { Contact } from './Contact';
import { Vertreter } from '../../../im-modules/user/users/models/Vertreter';
import { FromDB } from '../../../common/api/FromDB';
import {POTENTIALCUSTOMERS} from "../../../sebu.paths";

export interface Pivot {
  Id: string;
  userId: string;
}

export interface PotentialCustomer {
  vertreterNummer: number;
  kunde: string;
  anrede: string;
  vorname: string;
  nachname: string;
  street: string;
  postalcode: string;
  city: string;
  email: string;
  phoneNumber: string;

  Rechnungsadresse?: CustomerAddress;
  /** Zugehöriger Aussendienstler, basierend auf Vertreternummer */
  Aussendienstler?: Vertreter;
  /** Zugehöriger Innendienstler, basierend auf InnendienstVerkaeufer */
  Innendienstler?: Vertreter;
  pivot?: Pivot | null | undefined;
}

export const POTENTIALCUSTOMER_ENDPOINT = '/potential-customers';
export const IMPORTED_POTENTIALCUSTOMER_ENDPOINT = '/potential-customers/imported';
export const UPLOAD_POTENTIALCUSTOMER_ENDPOINT = '/potential-customers/upload';
export const VALID_STATI_ENDPOINT = '/potential-customers/valid-stati';


export type CustomerAddress = Pick<PotentialCustomer,
  'kunde' | 'anrede' | 'vorname' |
  'nachname' | 'street' | 'postalcode' | 'city' |
  'phoneNumber' | 'email'>;

export type PotentialCustomerWithCRMAnsprechpartner = FromDB<PotentialCustomer> & {
  AnsprechpartnerCRM: FromDB<Contact>[],
};

export function showPotentialCustomerName(kunde: CustomerAddress) {
  return [[
    kunde.kunde,
    kunde.vorname,
  ].filter(x => x).join(' '),
    kunde.nachname].filter(x => x).join(' ');
}

export function linkToCustomer(kunde: FromDB<PotentialCustomer>) {
  return `/${POTENTIALCUSTOMERS}/${kunde.Id}`;
}

export function showPotentialCustomerOrt(kunde: PotentialCustomer) {
  return [
    kunde.street,
    `${kunde.postalcode} ${kunde.city}`,
  ].filter(x => x).join(', ');
}
