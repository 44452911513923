<form [formGroup]="form" (submit)="onSubmit()">
    <div class="form-group">
        <label for="position">Position</label>
        <input type="text" class="form-control" [formControl]="position">
    </div>

    <button *ngIf="showSubmit" class="btn btn-primary" type="submit" imDisabledWhileLoading>
        <im-icon name="plus"></im-icon>
        Erstellen
    </button>
</form>
