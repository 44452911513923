import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { BlobService } from './blob.service';
import { tap } from 'rxjs/operators';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FromDB } from '../../../common/api/FromDB';

@Component({
  selector: 'blob-image',
  templateUrl: './blob-image.component.html',
  styleUrls: ['./blob-image.component.scss'],
})
export class BlobImageComponent implements OnChanges {
  @Input() blob: FromDB<Blob> | string;

  _image: SafeUrl = '';
  _isLoading = false;
  _hasError = false;

  constructor(private blobs: BlobService, private sanitizer: DomSanitizer) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.blob.currentValue) {
      this.fetchImage();
    }
  }

  private fetchImage() {
    this.blobs
      .getRawAsBase64(this.blob as any)
      .pipe(tap(() => this._isLoading = true))
      .subscribe(imageBlob => {
        this._isLoading = false;
        this.readRawBlobToImage(imageBlob);
      }, () => {
        this._hasError = true;
        this._image = 'data:error';
      });
  }

  private readRawBlobToImage(blob: Blob) {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (typeof reader.result === 'string') {
        this._image = this.sanitizer.bypassSecurityTrustUrl(reader.result); // NOSONAR
      }
    };

    reader.readAsDataURL(blob);
  }
}
