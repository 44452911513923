<div class="mb-5">
  <div class="input-group">
    <input type="text"
           name="comment"
           class="form-control"
           [formControl]="this.newEntity"
           [ngClass]="{'is-invalid' : this.newEntity.invalid && (this.newEntity.dirty || this.newEntity.touched)}"
            placeholder="Kommentar hinzufügen">
      <button class="btn btn-outline-primary" (click)="add()">Hinzufügen</button>
    <div *ngIf="this.newEntity.invalid && (this.newEntity.dirty || this.newEntity.touched)" class="invalid-feedback">
      Kommentar darf nur 1-50 Zeichen haben
    </div>
  </div>
</div>

<h4 class="d-flex flex-row">
  <span class="text-nowrap me-4">Vorhandene Einträge</span>
  <input type="text"
    name="search"
    class="form-control "
    placeholder="Suchen"
    (keyup)="changeSearch($event)">
</h4>
<div *ngFor="let key of getFormGroupIds()">
  <div class="input-group m-2" *ngIf="!(this.search !== '' && this.commentsFormGroup.get(key)?.value.indexOf(this.search) === -1)">
    <input type="text"
           [formControl]="this.commentsFormGroup.get(key)"
           [ngClass]="{
            'border-warning': !this.isFormControlInvalid(key) && this.commentsFormGroup.get(key)?.dirty,
            'is-invalid': this.isFormControlInvalid(key)
            }"
           class="form-control">
      <button data-id="{{ key }}" (click)="update($event)" class="btn btn-outline-secondary">Update</button>
      <button data-id="{{ key }}" (click)="delete($event)" class="btn btn-outline-danger">Löschen</button>
    <div *ngIf="this.isFormControlInvalid(key)" class="invalid-feedback">
      Kommentar darf nur 1-50 Zeichen haben
    </div>
  </div>
</div>
