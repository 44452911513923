/** In diesem Datumsformat speichert die Datenbank 'Dates' ab */
export const DATE_DB_FORMAT = 'YYYY-MM-DD HH:mm:ssZ';
/** Das Datumsanzeigeformat, das standardmäßig angezeigt wird. */
export const DATE_DISPLAY_FORMAT = 'DD.MM.YYYY';
/** Das Datumsformat, was oben in der URL-Leiste bei Query-Parametern verwendet werden sollte */
export const DATE_QUERY_PARAM_FORMAT = 'DD-MM-YYYY';
/** Das Zeitanzeigeformat, das standardmäßig angezeigt wird. */
export const TIME_DISPLAY_FORMAT = 'HH:mm';
/** Werden Datum und Zeit zusammen angezeigt, wird dieses Format verwendet */
export const DATETIME_DISPLAY_FORMAT = `${DATE_DISPLAY_FORMAT}, ${TIME_DISPLAY_FORMAT}`;
