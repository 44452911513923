import { Component, EventEmitter, Input } from '@angular/core';
import { AppSetting } from '../models/AppSetting';
import { FromDB } from '../../../../common/api/FromDB';
import { SettingComponent } from './setting.component';

export interface StringAppSetting extends AppSetting {
  type: 'string';
  value: string;
}

@Component({
  selector: 'im-string-setting',
  template: `
    <div class="input-group">
      <input type="text" class="form-control"
             [value]="_value" (change)="_value = $event.target.value">
        <button class="btn btn-primary" type="button" (click)="settingChange.emit(setting)" imDisabledWhileLoading>
          Speichern
        </button>
    </div>
  `,
  styles: [],
})
export class StringSettingComponent implements SettingComponent<StringAppSetting> {

  public settingChange = new EventEmitter<FromDB<StringAppSetting>>();
  /** Die interne repräsentation des Wertes der Einstellung */
  _value: string;
  /** Die Einstellung */
  _setting: FromDB<StringAppSetting>;

  /**
   * Setzt die zu verwaltende Einstellung, und verarbeitet den Stringwert in eine
   * interne Darstellung.
   *
   * @param setting
   */
  @Input()
  set setting(setting: FromDB<StringAppSetting>) {
    this._setting = setting;
    this._value = setting.value;
  }

  /**
   * Ruft das Setting mit seinem aktuellen Wert wieder ab.
   */
  get setting(): FromDB<StringAppSetting> {
    return {
      ...this._setting,
      value: this._value,
    };
  }
}
