import { ElementRef, Injectable, Injector } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { identityComparator } from '../../../forms/identityComparator';
import { ApiClient } from '../../ApiClient';
import { FromDB } from '../../FromDB';
import { Subject } from 'rxjs';
import { LogFactory } from '../../../generic/LogFactory';

let log;

/**
 * Basisklasse, aus der Dropdowns für Entitäten in Filtern erstellt werden können.
 */
@Injectable()
export abstract class AbstractEntityFilter<T> implements  ControlValueAccessor {
  abstract endpoint: string;
  abstract selectRef: ElementRef<HTMLSelectElement>;
  abstract additionalEndpointParams: object;

  protected api: ApiClient;
  public selectedEntity: FromDB<T> | undefined | null;
  public entities: FromDB<T>[] = [];
  public hasLoaded = false;
  public loading = new Subject();

  constructor(protected injector: Injector) {
    this.api = injector.get(ApiClient);
    this.loadEntities = this.loadEntities.bind(this);
  }

  public identityComparator = identityComparator;

  init() {
    if (!this.endpoint) {
      throw new Error('[AbstractEntityFilter] \'endpoint\'-Attribut wird benötigt!');
    }

    this.loadEntities();
  }

  protected loadEntities() {
    this.api
      .all<T>(this.endpoint, this.additionalEndpointParams)
      .subscribe(entities => {
        this.onEntitiesLoaded(entities);
      });
  }

  protected onEntitiesLoaded(entitites: FromDB<T>[]) {
    this.entities = entitites;
    this.resetAfterEntitiesArrived();
  }

  protected resetAfterEntitiesArrived() {
    this.hasLoaded = true;
    this.loading.next(null);
    this.loading.complete();
  }

  public onChange(obj: any) {
  }

  public onTouched(obj: any) {
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    if (this.selectRef) {
      this.selectRef.nativeElement.disabled = isDisabled;
    }
  }

  writeValue(obj: any) {
    if (!this.hasLoaded && obj) {
      log('Element wurde geschrieben, die Entitäten wurden allerdings noch nicht geladen, warte...');
      this.loading.subscribe(() => {
        log('Entitäten geladen! Suche und selektiere passendes Element...');
        this.writeMatchingElement(obj);
      });
      return;
    }

    this.selectedEntity = obj;
    this.onChange(obj);
  }

  /**
   * Sind noch nicht alle Enitäten geladen, wird darauf gewartet. Diese Funktion setzt daraufhin die
   * selectedEntity auf die korrekte Entity aus der Liste.
   *
   * @param obj
   */
  private writeMatchingElement(obj: any) {
    const searchFn = typeof obj === 'string'
      ? search => search.Id === obj
      : search => search.Id === obj.Id;


    const elem = this.entities.find(searchFn);

    if (!elem) {
      log(`Kein passendes Element zu ${obj} in entities gefunden...`, obj, this.entities);
    }

    this.selectedEntity = elem;
    this.onChange(elem);
  }
}

log = LogFactory.create(AbstractEntityFilter);
