<ng-container *ngIf="customer">
  <!---------------------------------------------------------------------------
                                Reihe 1
---------------------------------------------------------------------------->
  <div class="row">
    <div class="col-md-4">
      <h4>Lieferadresse</h4>

      <sebu-customer-address [address]="customer"></sebu-customer-address>
    </div>
    <div class="col-md-4">
      <h4>
        Rechnungs&shy;addresse
      </h4>

      <sebu-customer-address
        [address]="customer.Rechnungsadresse ? customer.Rechnungsadresse : customer"
      ></sebu-customer-address>
    </div>
  </div>

  <!---------------------------------------------------------------------------
                                  Reihe 2
  ---------------------------------------------------------------------------->
  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12">
          <h4>Preisfindungskennziffern</h4>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <sebu-customer-attribute label="ID">
            {{ customer.InnendienstVerkaeufer }}
            <span *ngIf="customer.Innendienstler">{{ customer?.Innendienstler?.Name }}</span>
          </sebu-customer-attribute>
          <sebu-customer-attribute label="AD">
            {{ customer.VertreterNr }}
            <span *ngIf="customer.Aussendienstler">{{ customer?.Aussendienstler?.Name }}</span>
          </sebu-customer-attribute>
        </div>

        <div class="col-md-4">
          <sebu-customer-attribute label="Ordersatz">
            {{ safeDisplayEnumText('Ordersatz') }}
          </sebu-customer-attribute>
          <sebu-customer-attribute label="Kontraktkunde">
            {{ safeDisplayEnumText('Kontraktkunde') }}
          </sebu-customer-attribute>
          <sebu-customer-attribute label="Rabattsteuerung">
            {{ safeDisplayEnumText('RabattKz').replace('RABATT', customer.Rabatt) }}
          </sebu-customer-attribute>
          <sebu-customer-attribute label="Standard-Preisgruppe">
            {{ customer.Preisgruppe }}
          </sebu-customer-attribute>
        </div>

        <div class="col-md-4">
          <sebu-customer-attribute label="Mengenstaffel">
            {{ safeDisplayEnumText('MengenstaffelKz') }}
          </sebu-customer-attribute>
          <sebu-customer-attribute label="Rückstandsbildung">
            {{ safeDisplayEnumText('Rueckstandsbildung') }}
          </sebu-customer-attribute>
          <sebu-customer-attribute label="Alternativ-Artikel">
            {{ safeDisplayEnumText('AlternativArtikel') }}
          </sebu-customer-attribute>
          <sebu-customer-attribute label="Bestpreisregelung">
            {{ safeDisplayEnumText('Bestpreis') }}
          </sebu-customer-attribute>
          <sebu-customer-attribute label="Allg. Sonderangebote">
            {{ safeDisplayEnumText('Sonderangebote') }}
          </sebu-customer-attribute>
          <sebu-customer-attribute label="Gruppen-Sonderangebote">
            {{ customer.AngebotGruppeNr1 }}, {{ customer.AngebotGruppeNr2 }}
          </sebu-customer-attribute>
        </div>
      </div>
    </div>
  </div>

  <!---------------------------------------------------------------------------
                                  Reihe 3
  ---------------------------------------------------------------------------->
  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12">
          <h4>Kundenauftragsüberprüfung</h4>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <sebu-customer-attribute label="Mehrwertsteuer">
            {{ safeDisplayEnumText('Mehrwertsteuer') }}
          </sebu-customer-attribute>
          <sebu-customer-attribute label="Interner-Hinweis">{{ customer.InternerHinweis }}</sebu-customer-attribute>
          <sebu-customer-attribute label="Balm-Berechtigung">
            {{ safeDisplayEnumText('BalmBerechtigung') }}
          </sebu-customer-attribute>
        </div>

        <div class="col-md-4">
          <sebu-customer-attribute label="Auftragslimit">
            {{ customer.Auftragslimit | currency:'EUR':'symbol' }}
          </sebu-customer-attribute>
          <sebu-customer-attribute label="Kreditlimit">
            {{ customer.Kreditlimit | currency:'EUR':'symbol' }}
          </sebu-customer-attribute>
          <sebu-customer-attribute label="Mindestwert">
            {{ customer.Mindestwert | currency:'EUR':'symbol' }}
          </sebu-customer-attribute>
          <sebu-customer-attribute label="MWUnterschreitung">
            {{ customer.MWUnterschreitung | currency:'EUR':'symbol' }}
          </sebu-customer-attribute>
          <sebu-customer-attribute label="Mindestroherloes">
            {{ customer.Mindestroherloes | currency:'EUR':'symbol' }}
          </sebu-customer-attribute>
        </div>

        <div class="col-md-4">
          <sebu-customer-attribute label="Mahnstufe">
            {{ safeDisplayEnumText('Mahnstufe') }}
          </sebu-customer-attribute>
          <sebu-customer-attribute label="Liefersperre">
            {{ safeDisplayEnumText('Liefersperre') }}
          </sebu-customer-attribute>
          <sebu-customer-attribute label="Kundenkentyp">
            {{ safeDisplayEnumText('Kundenkennziffer') }}
          </sebu-customer-attribute>
          <sebu-customer-attribute label="Preisaufschlag">
            {{ safeDisplayEnumText('PreisaufschlagKz').replace('PREISAUFSCHLAG', customer.PreisaufschlagProzent) }}
          </sebu-customer-attribute>
        </div>
      </div>
    </div>
  </div>

  <!---------------------------------------------------------------------------
                                  Reihe 4
  ---------------------------------------------------------------------------->
  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12">
          <h4>Informationen</h4>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <sebu-customer-attribute label="Bonusregelung">
            {{ safeDisplayEnumText('BonusKz') }}
          </sebu-customer-attribute>
          <sebu-customer-attribute label="Bonus">{{ customer.BonusProzent }}%</sebu-customer-attribute>
          <sebu-customer-attribute label="Skonto">
            {{ safeDisplayEnumText('SkontoKz').replace('SKONTO', customer.Skonto) }}
          </sebu-customer-attribute>
          <sebu-customer-attribute label="Nationalkunde">
            {{ safeDisplayEnumText('SBNationalKZ').replace('KUNDENGRUPPE', customer.SBKundenGruppe) }}
          </sebu-customer-attribute>
        </div>

        <div class="col-md-4">
          <sebu-customer-attribute label="Auftragsbestätigung">
            {{ safeDisplayEnumText('AuftragsbestaetigungPerFax') }}
          </sebu-customer-attribute>
          <sebu-customer-attribute label="Rechnungstermine">
            {{ safeDisplayEnumText('Rechnungstermine') }}
          </sebu-customer-attribute>
          <sebu-customer-attribute label="Zahlungsbedingungen">
            {{ customer.Zahlungsbedingung }}
          </sebu-customer-attribute>
          <sebu-customer-attribute label="Versand-Art">{{ customer.VersandArt}}</sebu-customer-attribute>
          <sebu-customer-attribute label="Versand-Hinweis">{{ customer.VersandHinweis}}</sebu-customer-attribute>
          <sebu-customer-attribute label="Auslieferungstour">{{ customer.Auslieferungstour}}</sebu-customer-attribute>
        </div>

        <div class="col-md-4">
          <h5>Ruhetage Besuch</h5>
          <sebu-customer-rest-days [restDays]="customer.RuhetageBesuch"></sebu-customer-rest-days>

          <h5 class="mt-3">Ruhetage Auslieferung</h5>
          <sebu-customer-rest-days [restDays]="customer.RuhetageAuslieferung"></sebu-customer-rest-days>
        </div>
      </div>
    </div>
  </div>

</ng-container>
