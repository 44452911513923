import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiClient } from '../../../common/api/ApiClient';
import { ToastrService } from 'ngx-toastr';
import { FromDB } from '../../../common/api/FromDB';

export interface Comment {
  Id: string;
  comment: string;
}

@Component({
  selector: 'sebu-comment-logic',
  templateUrl: './comment-logic.component.html',
})
export class CommentLogicComponent implements OnInit {
  @Input() header: string;
  @Input() apiEndpoint: string;

  public commentsFormGroup: UntypedFormGroup = new UntypedFormGroup({});
  public newEntity: UntypedFormControl;
  public search = '';

  constructor(
    private api: ApiClient,
    protected toastr: ToastrService,
  ) {
    this.newEntity = this.createFormControl('');
  }

  changeSearch(event) {
    this.search = event.target.value;
  }

  ngOnInit() {
    this.loadCommentsList();
  }

  getFormGroupIds(): Array<string> {
    return Object.keys(this.commentsFormGroup.controls);
  }

  isFormControlInvalid(id: string): boolean {
    const formControl = this.commentsFormGroup.get(id);

    if (formControl === null) { return true; }

    return (formControl.invalid && (formControl.dirty || this.newEntity.touched));
  }

  add() {
    if (this.newEntity.valid) {
      const formData = new FormData();
      formData.append('comment', this.newEntity.value);

      this.api.store(this.apiEndpoint, formData).subscribe(
        () => {
          this.toastr.success('Der Kommentar wurde erfolgreich erstellt!');
          this.newEntity.reset('');
          this.loadCommentsList();
        });
    }
  }

  delete(event) {
    const id = event.target.id;
    const formControl = this.commentsFormGroup.get(id);

    if (formControl !== null) {
      if (!confirm('Sind Sie sicher, dass der Kommentar "' + formControl.value + '" gelöscht werden soll?')) {
        return;
      }
    }

    this.api.destroy(this.apiEndpoint, id).subscribe(
      () => {
        this.toastr.success('Der Kommentar wurde erfolgreich gelöscht!');
        this.loadCommentsList();
      });
  }

  update(event) {
    const id = event.target.id;
    const formControl = this.commentsFormGroup.get(event.target.id);

    if (formControl !== null && formControl.valid) {
      this.api.update(this.apiEndpoint, id, {'comment': formControl.value}).subscribe(
        () => {
          this.toastr.success('Der Kommentar wurde erfolgreich geändert!');
          formControl.reset(formControl.value);
          this.loadCommentsList();
        });
    }
  }

  loadCommentsList() {
    this.api.all<Comment>(this.apiEndpoint).subscribe(
      (commentLists: FromDB<Comment>[]) => {
        const existingIds: string[] = [];

        for (const comment of commentLists.reverse()) {
          const formControl = this.commentsFormGroup.get(comment.Id);
          existingIds.push(comment.Id);

          if (formControl === null) {
            // Neue Einträge hinzufügen
            this.commentsFormGroup.addControl(comment.Id, this.createFormControl(comment.comment));
          } else {
            // Nur nicht geänderte Zeilen mit den neuen Daten befüllen
            if (!formControl.dirty) {
              formControl.reset(comment.comment);
            }
          }
        }

        // Alle unbekannten Ids löschen
        const arrayDiff = this.getFormGroupIds().filter(x => !existingIds.includes(x));
        for (const diff of arrayDiff) {
          this.commentsFormGroup.removeControl(diff);
        }
      },
    );
  }

  private createFormControl(value: string): UntypedFormControl {
    return new UntypedFormControl(value, [Validators.maxLength(50), Validators.required, Validators.minLength(1)]);
  }
}
