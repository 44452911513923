import { FromDB } from '../../../common/api/FromDB';
import { AuftrPosWithArtikel } from './AuftrPos';
import { AuftrAns } from './AuftrAns';
import { AuftrTxt } from './AuftrTxt';

export interface Auftr {
  NotepadAuftragsNr: number;
  Hauptkundennummer: number;
  Filialnummer: number;
  LieferanschriftKz: number;
  VertreterNr: number;
  InvestitionsVertreter: number;
  Auftragsdatum: Date;
  Lieferdatum: Date;
  Auftragswert: number;
  RabattKz: number;
  Rabatt: number;
  PreiszuschlagsKz: number;
  Preiszuschlag: number;
  Positionsanzahl: number;
  Auftragsbestaet: number;
  Rechnungstermin: number;
  isOffer: boolean;

  CompletedAt: Date | undefined | null;
  ExportedAt: Date | undefined | null;
  ExportFailedAt: Date | undefined | null;
  ExportRequest: string;
  ExportResponse: string;
}

export interface AuftrWithRelations extends Auftr {
  Positionen: FromDB<AuftrPosWithArtikel>[];
  Lieferadresse: null | FromDB<AuftrAns>;
  Kommentare: FromDB<AuftrTxt>[];
}

export const AUFTR_ENDPOINT = '/auftraege';
export const AUFTR_EXPORT_ENDPOINT = AUFTR_ENDPOINT + '/export';

export function isFinished(auftrag: Auftr) {
  return auftrag.CompletedAt !== null && auftrag.CompletedAt !== undefined;
}
