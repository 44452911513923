import { Injectable } from '@angular/core';
import { ApiClient } from '../../../common/api/ApiClient';

@Injectable({
  providedIn: 'root',
})
export class AdditionalInformationService {

  public readonly ENDPOINT = '/artikel-zusatz-infos';

  constructor(public api: ApiClient) { }

  create(
    { name,
      file,
      artikelId,
    }: {
      name: string,
      file: File,
      artikelId: string,
    }) {
    const data = new FormData();
    data.set('name', name);
    data.set('blob', file);
    data.set('artikelId', artikelId);

    return this.api.store(this.ENDPOINT, data);
  }
}
