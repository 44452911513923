import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiClient } from '../../../common/api/ApiClient';
import { CUSTOMER_ENDPOINT, Customer } from '../models/Customer';
import { tap } from 'rxjs/operators';
import { CustomerDetailService } from './customer-detail.service';

/**
 * Ruft initial für alle Customer-Detailansichten den Customer ab und speichert
 * sich diesen im CustomerDetailService. Auf diese Weise müssen nicht alle
 * Detailkomponenten, wie Auftragshistorie, Kontakte usw. den ganzen Voodo mit
 * `route.parent.data.subscribe(...)` durchführen und können direkt ihren customer auf
 * den des Services verweisen lassen.
 */
@Injectable()
export class CustomerDetailResolverService  {
  constructor(private api: ApiClient, private detailService: CustomerDetailService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<Customer> | Promise<Customer> | Customer {
    return new Promise((resolve, reject) => {
      this.api
        .show<Customer>(CUSTOMER_ENDPOINT, route.params['id'])
        .pipe(tap(customer => {
          this.detailService.customer = customer;
        }))
        .subscribe(resolve);
    });
  }
}
