import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

export class BaseForm {
  public form: UntypedFormGroup;
  public submitAttempt = false;

  isFieldInValid(fieldName: string) {
    if (!this.form) {
      return true;
    }

    const field = this.form.get(fieldName);

    return field && !field.valid && field.touched && this.submitAttempt;
  }

  displayFieldCss(field: string, additionalConditions = true) {
    if (!this.submitAttempt || !this.form.touched) {
      return {};
    }

    const isValid = !this.isFieldInValid(field) && additionalConditions;

    return {
      'is-invalid': !isValid,
      'is-valid': isValid,
    };
  }

  getFieldCssString(field: string, additionalConditions = true) {
    const styleClasses = this.displayFieldCss(field, additionalConditions);
    const trueStyles = Object.keys(styleClasses).filter(key => styleClasses[key]);
    trueStyles.push('form-control');

    return trueStyles.join(' ');
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  public onSubmit() {
    this.submitAttempt = true;
  }

  reset() {
    this.form.reset();
    this.submitAttempt = false;
  }
}
