import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseForm } from '../../../common/forms/base-form';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AdditionalInformationService } from './additional-information.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'sebu-create-additional-information',
  template: `
    <form [formGroup]="form" (submit)="onSubmit()">
      <div class="form-group">
        <label>Artikel</label>
        <sebu-article-search
          id="artikel"
          [inputNgClass]="displayFieldCss('artikel')"
          [formControl]="artikel"
        ></sebu-article-search>
        <small class="form-text text-muted">
          Es kann nach dem Bezeichnung und nach der Artikelnummer gesucht werden.
        </small>
        <im-field-error-display
          [displayError]="submitAttempt && artikel?.invalid"
          errorMsg="Bitte wählen Sie einen Artikel aus den Suchergebnissen!"
        ></im-field-error-display>
      </div>

      <div class="form-group">
        <label>Name</label>
        <input
          id="name"
          [formControl]="name"
          class="form-control"
          [ngClass]="displayFieldCss('name')"
        />
        <im-field-error-display
          [displayError]="submitAttempt && name?.invalid"
          errorMsg="Bitte geben Sie einen Namen an!"
        ></im-field-error-display>
      </div>

      <div class="form-group">
        <label>Zusatzinformation</label>
        <div>
          <im-file-input
            id="file" accept=".pdf,.mp4"
            placeholder="Zusatzinformation hochladen..."
            [formControl]="blob"
            [inputClasses]="displayFieldCss('blob')"
          ></im-file-input>
          <small class="form-text text-muted">
            Gültige Formate sind pdf und mp4.
          </small>
          <im-field-error-display
            [displayError]="submitAttempt && blob?.invalid"
            errorMsg="Bitte wählen sie eine Datei!"
          ></im-field-error-display>
        </div>
      </div>

      <div class="form-group">
        <button type="submit" class="btn btn-primary">
            <im-icon name="plus"></im-icon>
            Erstellen
        </button>
      </div>
    </form>
  `,
})
export class CreateAdditionalInformationComponent extends BaseForm implements OnInit {
  constructor(
    public additionalInfos: AdditionalInformationService,
    public toastr: ToastrService,
  ) {
    super();
  }

  @Output()
  public created = new EventEmitter();

  @Output()
  public attemptedSubmit = new EventEmitter();

  ngOnInit() {
    this.form = new UntypedFormGroup({
      blob : new UntypedFormControl(null, Validators.required),
      name : new UntypedFormControl('', Validators.required),
      artikel : new UntypedFormControl(null, (control: AbstractControl): {[key: string]: any} | null => {
        return control.value && control.value.Id
          ? null
          : {'artikel': 'Bitte wählen Sie einen Artikel aus den Suchergebnissen!'};
      }),
    });
  }

  public onSubmit() {
    super.onSubmit();

    if (this.form.valid) {
      const fileList = this.form.value.blob;

      this.additionalInfos.create({
        name: this.form.value.name,
        file: fileList[0],
        artikelId: this.form.value.artikel.Id,
      }).subscribe(() => {
        this.toastr.success('Erfolgreich erstellt!');
        this.created.emit();
      });
    } else {
      this.validateAllFormFields(this.form);
      this.attemptedSubmit.emit();
    }
  }

  public get blob() {
    return this.form.get('blob');
  }

  public get artikel() {
    return this.form.get('artikel');
  }

  public get name() {
    return this.form.get('name');
  }
}
