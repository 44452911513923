import { Component, OnInit } from '@angular/core';
import { Customer } from '../../../models/Customer';
import { Contact } from '../../../models/Contact';
import { CustomerContactService } from '../../customer-contact.service';
import { FromDB } from '../../../../../common/api/FromDB';
import { CustomerDetailService } from '../../customer-detail.service';

@Component({
  selector: 'sebu-customer-contacts',
  template: `
    <sebu-contact-switcher
      [contacts]="contacts"
      [adisContactName]="customer.Ansprechpartner"
      [activeContact]="activeContact"
      (contactSelected)="activeContact = $event"
    ></sebu-contact-switcher>

    <hr *ngIf="activeContact">

    <sebu-contact-detail
      [contact]="activeContact"
      [mainContact]="customer.Hauptansprechpartner"
    ></sebu-contact-detail>
  `,
})
export class CustomerContactsComponent implements OnInit {
  customer: FromDB<Customer>;
  contacts: Contact[] = [];
  activeContact: Contact | undefined | null;

  constructor(
    private contactService: CustomerContactService,
    detailService: CustomerDetailService,
  ) {
    this.customer = detailService.customer;
  }

  ngOnInit() {
    this.contactService.forCustomer(this.customer)
      .subscribe(contacts => {
        this.contacts = contacts;
      });
  }
}
