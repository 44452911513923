import {Injectable} from '@angular/core';
import {User} from '../../user/users/User';
import {NO_REPETITION, SupportedFrequency} from '../models/SupportedRepetitions';
import {LogFactory} from '../../../common/generic/LogFactory';
import {ApiClient} from '../../../common/api/ApiClient';
import {Appointment, APPOINTMENT_ENDPOINT} from '../models/Appointment';
import {Observable} from 'rxjs';
import {ICalEditService} from './ical-edit.service';
import {ExpandedAugmentedAppointment, IcalQueryService} from './ical-query.service';
import {ICalEventType} from '../appointments/appointment-form/appointment-form.component';
import {AppointmentType} from '../models/AppointmentType';
import {AppointmentContactPerson} from '../models/AppointmentContactPerson';
import {Component, Event, OccurrenceDetails, parse, Time} from 'ical.js';
import * as moment from 'moment';
import { BaseModel } from '../../../common/api/BaseModel';
import { Customer } from '../../../sebu/customers/models/Customer';
import { FromDB } from '../../../common/api/FromDB';
import { DATE_QUERY_PARAM_FORMAT } from '../../../config/dates';
import { createTime } from './utils';
import {PotentialCustomer} from "../../../sebu/customers/models/PotentialCustomer";

/**
 * Alle Informationen zu einem Termin, die im ICAL-String gespeichert werden
 */
export interface ICalData {
  appointmentName: string;
  appointmentLocation: string;
  timeRangeStart: Date;
  timeRangeEnd: Date;
  allDay: boolean;
  repeats: SupportedFrequency;
  repeatEnd: Date | null;
}

/**
 * Alle Informationen zu einem Termin, die in Tabellen und nicht im ICal-String
 * gespeichert werden
 */
export interface AppointmentData {
  firm: string | Customer | PotentialCustomer;
  contactPersons: Array<(AppointmentContactPerson & BaseModel) | string>;
  appointmentType: (AppointmentType & BaseModel);
  season: string | null;
}

/**
 * Alle Informationen die zur Erstellung eines Termins benötigt werden
 */
export interface NewICalAppointment extends ICalData, AppointmentData {
}

interface ExtractedICalInfo {
  start: Date;
  end: Date;
  title: string;
  location: string;
  allDay: boolean;
  isRecurring: boolean;
  isException: boolean;
  repeatEnd: Date | null;
  repeats: SupportedFrequency;
  repeatInterval: number | null;
  geo: [number, number] | null;
  report: string;
  completed: boolean;
}

export type AugmentedAppointment = Appointment & ExtractedICalInfo;

let log;

@Injectable()
export class ICalAppointmentService {
  constructor(
    private api: ApiClient,
    private icalQuery: IcalQueryService,
    private icalEdit: ICalEditService,
  ) {
  }

  /**
   * Fragt alle Termine ab, die am gegebenen Tag für den gegebenen Nutzer im
   * Backend eingetragen sind.
   *
   * @param {User} user
   * @param {Date} from
   * @param {Date} to
   * @param {AppointmentType} type
   * @returns {Observable<ExpandedAugmentedAppointment[]>}
   */
  public range(user: User, from: Date, to: Date, type?: FromDB<AppointmentType>): Observable<ExpandedAugmentedAppointment[]> {
    return new Observable(observer => {
      const params = new URLSearchParams();
      params.set('from', moment(from).format(DATE_QUERY_PARAM_FORMAT));
      params.set('to', moment(to).format(DATE_QUERY_PARAM_FORMAT));
      params.set('userId', user.Id);
      if (type) {
        params.set('filter-type', type.Id);
      }

      this.api
        .get<Array<AugmentedAppointment & BaseModel>>(`${APPOINTMENT_ENDPOINT}?${params.toString()}`, {
          observe: 'response',
        })
        .subscribe(response => {
          const data = response.body || [];
          const extractAppointments = this.icalQuery.generateAppointmentReducer(
            moment(from),
            moment(to),
          );

          log('Parse und filtere ICals...', data);
          const appointments = data
            .reduce(extractAppointments, [])
            .sort(this.icalQuery.sortByStartTime) as ExpandedAugmentedAppointment[];

          observer.next(appointments);
          observer.complete();
        });
    });
  }

  /**
   * Erstellt ein neues ICal-Event und gibt es serialisiert als String zurück.
   */
  public create(user: User, data: NewICalAppointment, icalString: null | string = null, transferred = false) {
    this.normalizeIcalData(data);

    if (icalString === null) {
      const newIcal = this.icalEdit.createCalendar();
      const vevent = this.icalEdit.createVEvent();
      let eventCanBeTransformed = this.icalEdit.createEvent(vevent, data) !== null;
      if (!eventCanBeTransformed) {
        return null
      }
      newIcal.addSubcomponent(vevent);
      icalString = newIcal.toString();
    }

    log(icalString);

    const apiData = {
      transferred: transferred,
      userId: user.Id,
      ical: icalString,
      ...this.getApiData(data),
    };

    return this.api.store(APPOINTMENT_ENDPOINT, apiData);
  }

  public getApiData(data: NewICalAppointment) {
    const relevantData = this.filterIcalFields(data);

    delete relevantData['repeatEnd'];
    if (relevantData['firm']) {

      // Falls kein Kunde ausgewählt wurde, Freitext annehmen und entsprechenden Parameter setzen
      if (typeof relevantData['firm'] === 'string') {
        relevantData['customerName'] = relevantData['firm'];
      } else if(relevantData['firm'].Hauptkundennummer != null){    //check ob Customer oder PotentialCustomer
        relevantData['customerId'] = (relevantData['firm'] as FromDB<Customer>).Id;
      } else {
        relevantData['customerId'] = (relevantData['firm'] as FromDB<PotentialCustomer>).Id;
      }
    }

    delete relevantData['firm'];

    // appointmentType anpassen
    if (relevantData['appointmentType']) {
      relevantData['appointmentTypeId'] = relevantData['appointmentType'].Id;
    }

    delete relevantData['appointmentType'];

    // Kontaktpersonen auf Namen oder Id setzen, wird vom Backend behandelt
    relevantData['contactPersons'] = relevantData['contactPersons'].map(personOrString => {
      return typeof personOrString === 'string'
        ? personOrString
        : personOrString.Id;
    });

    return relevantData;
  }

  /**
   * Filtert die Ical-Spezifischen Felder raus, sodass nur noch api-relevante Daten zurückgegeben werden.
   */
  private filterIcalFields(data) {
    const {
      // Ical-Spezifische Daten rausfiltern
      allDay, repeats, repeatEnd, appointmentLocation, timeRangeStart, timeRangeEnd, appointmentName,
      /* eslint-disable-next-line */
      ...relevantData
    } = data;

    return relevantData;
  }

  public updateOnAppointmentDetailComponent(appointment: AugmentedAppointment & BaseModel, existingICal: string, recurrenceInstance: Date,
                                            data: NewICalAppointment, editMode: ICalEventType, reportDutyChecked: boolean) {
    this.normalizeIcalData(data);
    let newData = {};

    const calendar = new Component(parse(existingICal));
    const recurrenceId = Time.fromJSDate(recurrenceInstance as Date);
    const event = this.icalQuery.getEvent(calendar, recurrenceId.toICALString(), true) as Event;

    log(`Aktualisiere Termin ${event.summary} als ${editMode}...`);
    if (!event.isRecurring() || editMode === ICalEventType.Series) {
      if (!this.dataFitsWithSelectedSeason(data)) {
        return null
      }
      this.icalEdit.updateEventData(event, data, calendar);
    } else {
      const exception = this.icalQuery.getException(calendar, recurrenceId.toICALString());
      if (exception === null) {
        this.icalEdit.createOccurenceException(event, recurrenceId, data);
      } else {
        this.icalEdit.updateEventData(exception, data);
      }
    }

    newData['ical'] = calendar.toString();
    newData['SkipVisitReport'] = !reportDutyChecked;

    if (editMode === ICalEventType.Series || appointment.repeats == NO_REPETITION) {
      newData = {
        ...newData,
        ...this.getApiData(data),
      };
    }

    return this.api.update(APPOINTMENT_ENDPOINT, appointment.Id, newData);
  }

  public transferNonRecurringAppointment(appointment: ExpandedAugmentedAppointment, user: User) {
    let newData = {
      userId: user.Id,
      transferred: true
    };
    return this.api.update(APPOINTMENT_ENDPOINT, appointment.Id, newData);
  }

  public updateIcal(appointment: ExpandedAugmentedAppointment, icalString: string) {
    let newData = {
      ical: icalString
    };
    return this.api.update(APPOINTMENT_ENDPOINT, appointment.Id, newData);
  }

  /**
   * Normalisiert die ICal-Daten.
   * Wird benötigt, damit ein Nutzer einen ganztägigen Termin auch beispielsweise
   * vom 12.02 - 12.02 laufen lassen kann. Laut ICal wäre dies nicht passend, daher
   * wird das hier angepasst.
   */
  private normalizeIcalData(data: NewICalAppointment): void {
    if (data.timeRangeStart && data.timeRangeEnd && data.allDay) {
      const end = moment(data.timeRangeEnd);
      data.timeRangeEnd = end.add(1, 'days').toDate();
    }
  }

  private dataFitsWithSelectedSeason(data: NewICalAppointment) {
    if (data.season === "") {
      return true;
    }

    let seasonData = this.icalEdit.updatedDataForSeason(data)
    if (seasonData === null) {
      return false;
    } else {
      data.timeRangeStart = seasonData.timeRangeStart
      data.timeRangeEnd = seasonData.timeRangeEnd
      data.repeatEnd = seasonData.repeatEnd
    }
    return true
  }

  /**
   * Denormalisiert die ICal-Daten.
   * Hier wird bei ganztägigen Terminen wieder ein Tag draufgerechnet, um dem ICal-Standard zu entsprechen.
   * @param occurrenceDetails
   */
  public denormalizeICalData(occurrenceDetails: OccurrenceDetails): void {
    if (occurrenceDetails.startDate.icaltype === 'date') {
      const end = moment(occurrenceDetails.endDate.toJSDate());
      occurrenceDetails.endDate = createTime(end.subtract(1, 'days').toDate(), true);
    }
  }
}

log = LogFactory.create(ICalAppointmentService);
