import { Component, Input, OnInit } from '@angular/core';
import { BaseEntityForm } from '../../../../common/forms/BaseEntityForm';
import { Category } from '../../models/Category';
import { ToastrService } from 'ngx-toastr';
import { ApiClient } from '../../../../common/api/ApiClient';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FromDB } from '../../../../common/api/FromDB';
import autobind from 'autobind-decorator';
import { isInteger } from '../../../../common/forms/validators/integerValidator';
import { DATEPICKER_DE } from '../../../../config/locales';
import * as moment from 'moment';

type CategoryFromDB = FromDB<Category> & {
  parent: CategoryFromDB;
  picture?: FromDB<Blob>;
};

@Component({
  selector: 'im-category-form',
  templateUrl: './category-form.component.html',
})
export class CategoryFormComponent extends BaseEntityForm<CategoryFromDB, FormData> implements OnInit {
  public readonly today = new Date();
  @Input() public showSubmit = false;
  @Input() public isEditable = false;
  @Input() model: CategoryFromDB | null | undefined;
  @Input() imageIsDeletable = false;

  /**
   * Hier wird sich die ID des zu verwaltenden Models gespeichert, sodass man im Kategoriedropdown alle Unterkategorien
   * und die eigene Kategorie herausfiltern kann. So soll vermieden werden, dass man sich selbst oder eine seiner
   * Unterkategorien sich selbst als Oberkategorie zuweisen kann.
   */
  modelId = '';
  shouldDeleteFile = false;
  public de = DATEPICKER_DE;

  @Input()
  submit(data: FormData) {
  }

  constructor(
    protected toastr: ToastrService,
    protected api: ApiClient,
  ) {
    super(toastr, api);
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      parent: new UntypedFormControl(this.model ? this.model.parent : '', this.notTheSameEntityValidator),
      categoryName: new UntypedFormControl(this.model ? this.model.name : '', Validators.required),
      description: new UntypedFormControl(this.model ? this.model.description : '', Validators.maxLength(1000)),
      sortOrder: new UntypedFormControl(this.model ? this.model.sortOrder : 1, [Validators.required, isInteger]),
      picture: new UntypedFormControl(''),
      publishedAt: new UntypedFormControl(
        this.model
          ? moment(this.model.publishedAt, 'YYYY-MM-DD').toDate()
          : new Date(),
      ),
    });

    this.modelId = this.model ? this.model.Id : '';
  }

  getDataToSubmit(): FormData {
    const { parent, picture, categoryName, publishedAt, ...sameNameAttributes } = this.form.value;

    const data = new FormData();

    Object.entries({
      name: categoryName,
      publishedAt: moment(publishedAt).format('YYYY-MM-DD'),
      ...sameNameAttributes,
    }).forEach(([name, value]) => {
      data.set(name, value as string);
    });

    if (picture && (picture as FileList).length > 0) {
      data.set('picture', picture[0]);
    }

    if (this.shouldDeleteFile) {
      data.set('picture', '');
    }

    if (parent) {
      data.set('parentId', parent.Id);
    }

    return data;
  }

  @autobind
  notTheSameEntityValidator(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value || !this.model) {
      return null;
    }

    const isSame = (control.value as CategoryFromDB).Id === this.model.Id;

    // entweder kein Wert oder eine andere ID als die eigene
    return isSame ? { 'sameName': { value: control.value } } : null;
  }

  public get parent() {
    return this.form.get('parent');
  }

  public get categoryName() {
    return this.form.get('categoryName');
  }

  public get description() {
    return this.form.get('description');
  }

  public get sortOrder() {
    return this.form.get('sortOrder');
  }

  public get publishedAt() {
    return this.form.get('publishedAt');
  }

  public get picture() {
    return this.form.get('picture');
  }

  get parentCategorySelectorFilter() {
    return this.modelId
      ? { 'filter-exclude-subcategorys-from': this.modelId }
      : {};
  }
}
