import { MessageCategory } from './MessageCategory';
import { User } from '../../user/users/User';
import { BaseModel } from '../../../common/api/BaseModel';
import { FromDB } from '../../../common/api/FromDB';

export interface Message extends BaseModel {
  title: string;
  activationDateTime: string|Date;
  endDate: string|Date;
  category: FromDB<MessageCategory>;
  users: Array<User>;
}

export const MESSAGE_ENDPOINT = '/messages';
