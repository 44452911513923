<im-data-table [endpoint]="endpoint">
  <ng-template imDataTableHeaders>
    <th>Kategorie</th>
    <th>Nachricht</th>
    <th>Empfänger</th>
    <th>Versandt</th>
    <th>Erstellt</th>
    <th>Löschen am</th>
    <th class="actions-column">Aktionen</th>
  </ng-template>

  <ng-template imDataTableRow let-row>
    <td>
      {{ row.category?.name }}
    </td>
    <td class="onliner" [title]="row?.title">{{row?.title}}</td>
    <td class="onliner" [title]="formatEmails(row.users)">{{formatEmails(row.users)}}</td>
    <td>{{row.activationDateTime | sbDate}}</td>
    <td>{{row.CreatedAt | sbDate}}</td>
    <td>{{row.endDate | sbDate:true}}</td>
    <td>
      <div class="btn-group">
        <button imDetailButton [model]="row">Details</button>
        <button imDeleteButton [model]="row" [endpoint]="endpoint">Löschen</button>
      </div>
    </td>
  </ng-template>
</im-data-table>
