import { Component, EventEmitter, Input } from '@angular/core';
import { AppSetting } from '../models/AppSetting';
import { FromDB } from '../../../../common/api/FromDB';
import { SettingComponent } from './setting.component';

export interface EmailAppSetting extends AppSetting {
  type: 'email';
  value: string;
}

@Component({
  selector: 'im-string-setting',
  template: `
      <div class="input-group">
          <input type="text" class="form-control"
                 [class.is-invalid]="showError"
                 [value]="_value"
                 (input)="onValueChanged($event.target.value)">
              <button class="btn btn-primary" type="button"
                      (click)="onSaveClicked()"
                      [disabled]="showError"
                      imDisabledWhileLoading>
                  Speichern
              </button>
          <div class="invalid-feedback">
              Bitte geben Sie eine gültige E-Mail Adresse ein!
          </div>
      </div>
  `,
  styles: [],
})
export class EmailSettingComponent implements SettingComponent<EmailAppSetting> {

  public settingChange = new EventEmitter<FromDB<EmailAppSetting>>();
  /** Die interne repräsentation des Wertes der Einstellung */
  _value: string;
  /** Die Einstellung */
  _setting: FromDB<EmailAppSetting>;
  private validating = false;

  public onSaveClicked() {
    if (this.validate()) {
      this.validating = false;
      this.settingChange.emit(this.setting);
    }
  }

  public onValueChanged(newValue: string) {
    if (this.validating) {
      this.validating = false;
    }

    this._value = newValue;
  }

  /**
   * Setzt die zu verwaltende Einstellung, und verarbeitet den Stringwert in eine
   * interne Darstellung.
   *
   * @param setting
   */
  @Input()
  set setting(setting: FromDB<EmailAppSetting>) {
    this._setting = setting;
    this._value = setting.value;
  }

  get showError() {
    return this.validating && !this.valid;
  }

  get valid() {
    const EMAIL_REGEXP =
      // eslint-disable-next-line
      /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    return EMAIL_REGEXP.test(this._value);
  }

  validate() {
    this.validating = true;
    return this.valid;
  }

  /**
   * Ruft das Setting mit seinem aktuellen Wert wieder ab.
   */
  get setting(): FromDB<EmailAppSetting> {
    return {
      ...this._setting,
      value: this._value,
    };
  }
}
