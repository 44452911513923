import { AfterViewInit, Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from './auth.service';
import { canEdit } from '../users/User';

/**
 * Directive, zum anzeigen von bestimmten UI-Elementen, die *nur* für Admins gedacht sind.
 */
@Directive({
  selector: '[imAdminOnly]',
})
export class AdminOnlyDirective implements AfterViewInit {
  constructor(private auth: AuthService,
              private view: ViewContainerRef,
              private template: TemplateRef<any>) {
  }

  ngAfterViewInit() {
    if (canEdit(this.auth.user)) {
      this.view.createEmbeddedView(this.template);
    }
  }
}
