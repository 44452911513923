import { Component, ElementRef, ViewChild } from '@angular/core';
import { DetailComponentBase } from '../../../../common/layout/detail-container/DetailComponentBase';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiClient } from '../../../../common/api/ApiClient';
import { Category, CATEGORY_ENDPOINT } from '../../models/Category';
import { DetailContainerComponent } from '../../../../common/layout/detail-container/detail-container.component';
import autobind from 'autobind-decorator';
import { CategoryFormComponent } from '../category-form/category-form.component';
import { EntityDependantsService } from '../../../../common/api/entity-dependants.service';

declare const bootstrap: any;
type DetailCategory = Category & { hasChildren: boolean };

@Component({
  selector: 'im-category-details',
  templateUrl: './category-details.component.html',
})
export class CategoryDetailsComponent extends DetailComponentBase<DetailCategory> {
  @ViewChild('detailContainer') detailContainer: DetailContainerComponent<DetailCategory>;
  @ViewChild(CategoryFormComponent) form: CategoryFormComponent;
  @ViewChild('deleteModal') deleteModal: ElementRef;

  endpoint = CATEGORY_ENDPOINT;

  constructor(
    protected api: ApiClient,
    protected toastr: ToastrService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected catgegoryHasDependants: EntityDependantsService,
  ) {
    super(api, toastr, router, route);
  }

  @autobind
  saveEntity() {
    this.form.onSubmit();
  }

  @autobind
  save(data: FormData) {
    data.set('_method', 'PUT');

    this.api
      .store(this.endpoint + '/' + this.detailContainer.entity.Id, data)
      .subscribe(() => {
        this.detailContainer.refresh();
        this.toastr.success('Erfolgreich aktualisiert!');
        this.isEditable = false;
      });
  }

  @autobind
  confirmDelete() {
    const modal = new bootstrap.Modal(this.deleteModal.nativeElement);
    modal.show();
  }

  delete() {
    this.api
      .destroy(CATEGORY_ENDPOINT, this.detailContainer.entity.Id)
      .subscribe(() => {
        this.toastr.success('Erfolgreich gelöscht!');
        const modal = new bootstrap.Modal(this.deleteModal.nativeElement);
        modal.hide();
        this.router.navigate([this.redirectRoute], { relativeTo: this.route });
      });
  }
}
