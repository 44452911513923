import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CategoriesOverviewService {
  public _refreshEvent = new Subject();

  public refresh() {
    this._refreshEvent.next(null);
  }
}
