import { Component } from '@angular/core';

@Component({
  selector: 'sebu-customers',
  template: `
    <h1 class="page-title">Kunden</h1>

    <sebu-customers-table></sebu-customers-table>
  `,
})
export class CustomersComponent {

}
