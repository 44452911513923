<div class="card">
  <div class="card-header" (click)="toggleAll($event)">
    <div class="form-check">
      <label class="form-check-label">
        <input type="checkbox" class="form-check-input"
               [checked]="allChecked" style="pointer-events: none">
        Alle {{allChecked ? 'ab' : 'aus'}}wählen
      </label>
    </div>
  </div>
  <ul class="list-group list-group-flush options-list">
    <ng-content></ng-content>
  </ul>
</div>
