<im-data-table [endpoint]="endpoint">
  <ng-template imDataTableHeaders>
    <th>Herkunft-ID</th>
    <th>Bezeichnung</th>
    <th>Erstellt</th>
    <th>Zuletzt Bearbeitet</th>
    <th class="actions-column">Aktionen</th>
  </ng-template>

  <ng-template imDataTableRow let-row>
    <td class="onliner" [title]="row?.Key">{{row?.Key}}</td>
    <td class="onliner" [title]="row?.Name">{{row?.Name}}</td>
    <td>{{row.CreatedAt | sbDate}}</td>
    <td>{{row.UpdatedAt | sbDate}}</td>
    <td>
      <div class="btn-group">
        <button imDetailButton [model]="row" >Details</button>
        <button imDeleteButton [disabled]="!row?.editable" [model]="row" [endpoint]="endpoint">Löschen</button>
      </div>
    </td>
  </ng-template>
</im-data-table>
