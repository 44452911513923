<div class="card">
  <div class="card-body">
    <h1 class="card-title">
      {{ showCustomerName(customer) }}
    </h1>

    <ul class="nav nav-pills" role="tablist">
      <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" [replaceUrl]="true"
           routerLink="base-data">
          Stammdaten
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" [replaceUrl]="true"
           routerLink="notice">
          Notizen
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" [replaceUrl]="true"
           routerLink="contacts">
          Kontakte
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" [replaceUrl]="true"
           routerLink="order-history">
          Auftragshistorie
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" [replaceUrl]="true"
           routerLink="appointments">
          Termine
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" [replaceUrl]="true"
           routerLink="documents">
          Dokumente
        </a>
      </li>
    </ul>
    <div class="tab-content">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div class="card-footer d-flex">
    <div class="me-auto">
      <button class="btn btn-outline-secondary-transparent" *ngIf="!isEditable" (click)="location.back()">
        <im-icon name="arrow-left"></im-icon>
        Zurück
      </button>
    </div>
  </div>
</div>
