<div>
  <input type="file" [id]="id" class="form-control" [ngClass]="inputClasses"
         [accept]="accept" (change)="onChange($event.target.files)" #fileInput/>
</div>

<div *ngIf="deletable" class="mt-3">
  <button
    class="btn btn-outline-danger"
    type="button"
    *ngIf="!shouldDelete"
    (click)="shouldDelete=true"
  >
    Löschen
  </button>

  <button
    class="btn btn-outline-secondary"
    type="button"
    *ngIf="shouldDelete"
    (click)="shouldDelete=false"
  >
    Nicht mehr Löschen
  </button>

  <div class="alert alert-info mt-3" role="alert" *ngIf="shouldDelete">
    Die Datei wird beim Speichern gelöscht!
  </div>
</div>
