<im-detail-container
  [save]="saveEntity"
  [edit]="editEntity"
  [abort]="abort"
  [delete]="deleteEntity"
  [isEditable]="isEditable"
  [transformEntity]="editEntityAfterRetrieved"
  [onEntityLoaded]="onEntityLoaded"
  [endpoint]="endpoint"
  #detailContainer
>
  <ng-template>
    <h1 class="card-title">
      {{ entity.name }}
      <im-new-product-badge [product]="entity"></im-new-product-badge>
    </h1>

    <im-product-form
      [isEditable]="isEditable"
      [showSubmit]="false"
      [submit]="submitForm"
      [entity]="entity"
      [fileRequired]="false"
    ></im-product-form>
  </ng-template>
</im-detail-container>
