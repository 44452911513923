import { PaginationMetadata } from './PaginationMetadata';
import { LaravelPaginatedResponse } from '../LaravelPaginatedResponse';

export class LaravelPaginationMetadata implements PaginationMetadata {
  public get pageName() {
    return 'page';
  }

  constructor(protected meta: LaravelPaginatedResponse<any>) {
  }

  getCurrentPageNum(): number {
    return this.meta.current_page;
  }

  getData(): Array<any> {
    return this.meta.data;
  }

  getNumPages(): number {
    return this.meta.last_page;
  }
}
