import { Injectable } from '@angular/core';
import { ApiClient } from '../../../common/api/ApiClient';

@Injectable({
  providedIn: 'root',
})
export class ArticleSuggestionsService {

  public readonly ENDPOINT = '/artikelvorschlaege';

  constructor(public api: ApiClient) { }

  create(
    { artikelId, }: {
      artikelId: string,
    }) {
    const data = new FormData();
    data.set('ArtikelId', artikelId);

    return this.api.store(this.ENDPOINT, data);
  }

  remove(
    { suggestionId, }: {
      suggestionId: string,
  }) {
    return this.api.destroy(this.ENDPOINT, suggestionId);
  }
}
