import { Directive, ElementRef } from '@angular/core';
import { GlobalLoadingIndicatorService } from '../api/global-loading-indicator/global-loading-indicator.service';

/**
 * Kann an einen Button geschrieben werden, um diesen zu disablen, wenn etwas
 * auf der Seite läd.
 */
@Directive({
  selector: 'button[imDisabledWhileLoading]',
})
export class DisabledWhileLoadingDirective {
  constructor(
    private loadingService: GlobalLoadingIndicatorService,
    private el: ElementRef<HTMLButtonElement>,
  ) {
    this.loadingService.loading$.subscribe(isLoading => {
      if (!this.el.nativeElement) {
        return;
      }

      this.el.nativeElement.disabled = isLoading;
    });
  }
}
