<div>
  <h3 class="h3 mt-3 mb-3">Interessenten Upload</h3>
  <form [formGroup]="form" (submit)="onSubmit()">
    <br>
    <div class="form-group">
      <im-file-input id="csvFile" [formControl]="csvFile" accept=".csv"
                     placeholder="CSV hochladen..." (change)="onFileChange()"
                     [inputClasses]="displayFieldCss('csvFile')">
      </im-file-input>
      <im-field-error-display
        [displayError]="isFieldInValid('csvFile')"
        errorMsg="Bitte laden Sie eine CSV-Datei hoch!">
      </im-field-error-display>
      <br>
      <div class="form-group">
        <button type="submit" class="btn btn-primary" imDisabledWhileLoading>
          <im-icon name="plus"></im-icon>
          Hochladen
        </button>
      </div>
      <p>
        <im-icon name="info" size="{{IconSize.large}}"></im-icon> Eine Anleitung zum Aufbau der Datei finden Sie unten auf dieser Seite.
      </p>
    </div>
  </form>
  <div>
    <h3 class="h3 mt-5 mb-3">Letzte Importe</h3>
    <im-data-table #latestImportsTable [endpoint]="historyEndpoint">
      <ng-template imDataTableHeaders>
        <th>Datei Name</th>
        <th>Status*</th>
        <th>Datum</th>
        <th>Zeilen Gesamt</th>
        <th>Zeilen Importiert</th>
        <th>Zeilen Übersprungen</th>
      </ng-template>

      <ng-template imDataTableRow let-row>
        <td>{{ row.metadata.file_name ?? 'Unbekannt' }}</td>
        <td>
          <span *ngIf="row.status === 'success'" class="text-success">Erfolgreich</span>
          <span *ngIf="row.status === 'partial'" class="text-warning">Teilweise Erfolgreich</span>
          <span *ngIf="row.status === 'failure'" class="text-danger">Nicht Erfolgreich</span>
        </td>
        <td>{{ row.created_at | date: 'dd.MM.yyyy HH:mm' }}</td>
        <td>{{ row.metadata.rows_count }}</td>
        <td>{{ row.metadata.imported_count }}</td>
        <td>{{ row.metadata.skipped_count }}</td>
      </ng-template>
    </im-data-table>
    <span style="font-size: x-small">* Erfolgreich: Alle Zeilen wurden importiert. Teilweise Erfolgreich: Einige Zeilen wurden importiert. Nicht Erfolgreich: Keine Zeilen wurden importiert.</span>

  </div>
  <div class="pt-5">
    <h3 class="h3 mt-5 mb-3">Anleitung</h3>
    <p>
      Um Interessenten zu importieren, laden Sie eine CSV-Datei hoch. Die erste Zeile der Datei definiert die Header der Spalten.
    </p>
    <h4>Wichtige Hinweise</h4>
    <ul>
      <li>Die Datei muss im CSV-Format vorliegen.</li>
      <li>Das Trennzeichen muss ein semi-colon (;) sein.</li>
      <li>Es wird <b>nicht</b> auf Duplikate geprüft.</li>
      <li>Die Spalten werden anhand der ersten Zeile (<b>Kopfzeile</b>) erkannt. Die Reihenfolge ist nicht wichtig, doch die Header müssen exakt wie unten aufgelistet geschrieben sein.</li>
    </ul>
    <p>
      Folgende Spalten sind <b>erforderlich:</b>
    </p>
    <ul>
<!--  Vertreter, Kunde, Nachname, Straße, PLZ, Stadt    -->
      <li><b>Vertreter:</b> Die Vertreter Nummer. Wird kein Vertreter mit dieser Nummer gefunden, wird die Zeile übersprungen.</li>
      <li><b>Kunde:</b> Kundenname (z.B. Firma ABC)</li>
      <li><b>Nachname:</b> Nachname des Ansprechpartners</li>
      <li><b>Straße:</b> Adressdaten: Straße</li>
      <li><b>PLZ:</b> Adressdaten: Postleitzahl</li>
      <li><b>Stadt:</b> Adressdaten: Stadt</li>
    </ul>
    <p>
      Folgende Spalten sind <b>optional:</b>
    </p>
    <ul>
<!--   Anrede, Vorname, Email, Telefon, Status, Cluster   -->
      <li><b>Anrede:</b> Anrede des Ansprechpartners (z.B. Herr, Frau)</li>
      <li><b>Vorname:</b> Vorname des Ansprechpartners</li>
      <li><b>Email:</b> E-Mail Adresse des Ansprechpartners</li>
      <li><b>Telefon:</b> Telefonnummer des Ansprechpartners</li>
      <li><b>Status:</b> Status des Ansprechpartners. Darf nur einen der folgenden Werte enthalten: {{statiList}}</li>
    </ul>
  </div>
</div>
