/**
 * Eine Einstellung für die App. Diese Werte stammen aus der Datenbank und werden vom Superadmin gepflegt.
 */
export interface AppSetting {
  /** In der Tabelle eindeutiger Key für die Einstellung */
  key: string;
  /** Eine Beschreibung der Auswirkungen der Einstellung auf die Anwendung */
  description: string;
  /** Der Typ der Einstellung. Dies entscheidet auch, welche Komponente das AppSetting anzeigt! */
  type: string;
  /** Der Wert der Einstellung */
  value: string;
}

/** Endpunkt, um AppSettings abzurufen */
export const APP_SETTINGS_ENDPOINT = '/appsettings';
export const BACKEND_SETTINGS_ENDPOINT = '/backend-settings';
