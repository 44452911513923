import { Component, ContentChild, Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[imFormGroupReadable]',
})
export class FormGroupReadableDirective {
}

@Directive({
  selector: '[imFormGroupEditable]',
})
export class FormGroupEditableDirective {
}

@Component({
  selector: 'im-form-group-container',
  templateUrl: './form-group-container.component.html',
  styleUrls: ['./form-group-container.component.scss'],
})
export class FormGroupContainerComponent {
  @Input() public editable: boolean;
  @Input() public id: string;
  @Input() public label: string;

  /**
   * TODO: Eine Nachricht reicht hier eventuell nicht aus
   */
  @Input() public errorMessage = '';
  @Input() public isValid = true;

  @ContentChild(FormGroupEditableDirective, { read: TemplateRef }) public editableTemplate;
  @ContentChild(FormGroupReadableDirective, { read: TemplateRef }) public readableTemplate;
}
