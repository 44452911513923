import { Injectable, isDevMode } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { User } from '../users/User';
import { catchError, tap } from 'rxjs/operators';

export const ACCESS_TOKEN_KEY = 'access_token';
export const REFRESH_TOKEN_KEY = 'refresh_token';
export const TOKEN_EXPIRES_KEY = 'access_token_expires';
export const USER_KEY = 'logged_in_user';
export const AUTH_ENDPOINT = '/api/auth';

@Injectable()
export class AuthService {
  loginWarningMessages = {
    401: 'Die Vertreter-ID und/oder das Passwort sind nicht korrekt.',
  };

  loginErrorMessages = {
    500: 'Bei der Anmeldung ist etwas schief gelaufen!', // Default
  };

  public get currentToken(): JWTResponse {
    return {
      access_token: localStorage.getItem(ACCESS_TOKEN_KEY) || '',
      refresh_token: localStorage.getItem(REFRESH_TOKEN_KEY) || '',
      expires_in: parseInt(localStorage.getItem(TOKEN_EXPIRES_KEY) || '-1', 10),
    };
  }

  constructor(
    protected http: HttpClient,
    protected toastr: ToastrService,
    protected router: Router,
  ) {}

  public get isAuthenticated(): boolean {
    return this.currentToken && Boolean(this.currentToken.access_token);
  }

  public login(credentials: Credentials) {
    return this.http
      .post(`${AUTH_ENDPOINT}/login`, credentials)
      .pipe(
        tap(this.saveToken.bind(this)),
        catchError(this.onLoginError.bind(this)),
      );
  }

  private onLoginError(response: HttpErrorResponse) {
    const message = this.loginErrorMessages[response.status] ||
      this.loginWarningMessages[response.status] ||
      this.loginErrorMessages[500];

    const shouldBeError = this.loginErrorMessages[response.status] || !this.loginWarningMessages[response.status];

    if (shouldBeError) {
      this.toastr.error(message);
    } else {
      this.toastr.warning(message,
        `Anmeldung nicht möglich.`);
    }
  }

  public refresh(): Observable<JWTResponse> {
    return this.http
      .post(`${AUTH_ENDPOINT}/refresh`, {
        refresh_token: this.currentToken.refresh_token,
      })
      .pipe(
        tap(this.saveToken, () => {
          this.clear();
          return this.router.navigate(['/login']);
        }),
      ) as Observable<JWTResponse>;
  }

  public logout() {
    this.http
      .post(`${AUTH_ENDPOINT}/logout`, {})
      .subscribe(() => {
        this.clear();
        this.router.navigate(['/login']);
      }, () => {
        this.clear();
        this.router.navigate(['/login']);
      });
  }

  public clear() {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    localStorage.removeItem(TOKEN_EXPIRES_KEY);
    localStorage.removeItem(USER_KEY);
  }

  protected saveToken(authResult: JWTResponse & { user: User }): void {
    localStorage.setItem(ACCESS_TOKEN_KEY, authResult.access_token);
    localStorage.setItem(REFRESH_TOKEN_KEY, authResult.refresh_token);
    localStorage.setItem(TOKEN_EXPIRES_KEY, String(authResult.expires_in));

    // User wird nur bei Login zurückgeschickt, beim Refresh wird davon
    // ausgegangen, dass sich bereits ein Nutzer im localStorage befindet, da
    // sich ja bereits einmal authentifiziert wurde
    if (authResult.user) {
      localStorage.setItem(USER_KEY, JSON.stringify(authResult.user));
    }
  }

  public get user(): User | null {
    if (!this.isAuthenticated) {
      return null;
    }

    const user = localStorage.getItem(USER_KEY);

    if (!user) {
      return null;
    }

    try {
      return JSON.parse(user) as User;
    } catch (ex) {
      return null;
    }
  }
}

export interface JWTResponse {
  access_token: string;
  refresh_token: string;
  expires_in: number;
}

export interface Credentials {
  email: string;
  password: string;
}
