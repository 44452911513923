/**
 * Repräsentiert einen Knoten im Baum.
 */
export class TreeViewNode<T = any> {
  public children: Array<TreeViewNode<T>> = [];
  public parent?: TreeViewNode<T>;
  public level = 0;
  public data: T;

  constructor(
    public isRoot = false,
  ) {}
}
