import {Component, Event, parse, Recur, Time} from 'ical.js';
import * as moment from 'moment';
import {Injectable} from '@angular/core';
import {AugmentedAppointment} from './i-cal-appointment.service';
import {Appointment} from '../models/Appointment';
import {Frequency, NO_REPETITION, CUSTOM_REPETITION, SupportedFrequency} from '../models/SupportedRepetitions';
import {BaseModel} from '../../../common/api/BaseModel';
import {ICalExpanderService} from './ical-expander.service';
import {createTime} from './utils';

export type ExpandedAugmentedAppointment = AugmentedAppointment & BaseModel & { recurrence: Time };
export type RecurringAppointmentTransformer = {
  firstAppointmentInstance: ExpandedAugmentedAppointment,
  icalStringOfOldUser: string,
  icalStringOfNewUser: string
}

/**
 * Service zum extrahieren von Daten aus ICal-Strings
 */
@Injectable()
export class IcalQueryService {
  constructor(private expander: ICalExpanderService) {
  }

  /**
   * @param {moment.Moment} from
   * @param {moment.Moment} to
   */
  public generateAppointmentReducer(from: moment.Moment, to: moment.Moment) {
    return (allAppointments: Array<ExpandedAugmentedAppointment>, currentAppointment: Appointment & BaseModel) => {
      this.expander
        .between(
          currentAppointment.icalAppointment.ical,
          from.startOf('day').toDate(),
          to.endOf('day').toDate(),
        )
        // Für jedes Event am Tag einmal das gesamte Appointment pushen
        // plus Metainformationen
        .forEach(expandedEvent => {
          const augmented = this.augmentAppointment(
            currentAppointment,
            expandedEvent.event,
          ) as ExpandedAugmentedAppointment;

          augmented.recurrence = expandedEvent.recurrence;

          allAppointments.push(augmented);
        });

      return allAppointments;
    };
  }

  /**
   * Sortiert eine Liste von ICal.Events nach dem Startdatum, ganztägige Termine
   * zuerst, dannach aufsteigend.
   *
   * @param a
   * @param b
   */
  public sortByStartTime(a: ExpandedAugmentedAppointment, b: ExpandedAugmentedAppointment) {
    // Falls einer der beiden ein ganztägiger Termin ist, diesen zuerst
    // anzeigen, sind beide ganztägig, so sind sie gleichwertig
    if (a.allDay && !b.allDay) {
      return -1;
    } else if (!a.allDay && b.allDay) {
      return 1;
    } else if (a.allDay && b.allDay) {
      return 0;
    }

    // Handelt es sich um nicht ganztägige Termine, werden diese nach der
    // Startzeit aufsteigend sortiert
    return moment(a.recurrence.toJSDate()).diff(moment(b.recurrence.toJSDate()));
  }

  /**
   * Gibt für eine RecurrenceId ein Event aus dem Kalendar zurück.
   *
   * @deprecated getInstance nutzen
   * @see getInstance
   * @param ical
   * @param recurrenceId
   * @param fallBackToFirst
   */
  public getEvent(ical: string | Component, recurrenceId = '', fallBackToFirst = false): Event | null {
    if (typeof ical === 'string') {
      const jCal = parse(ical);
      ical = new Component(jCal);
    }

    const events = ical.getAllSubcomponents()
      .filter(component => component.name === 'vevent')
      .map(vevent => new Event(vevent));

    // Wiederholt sich ein Event, so befindet sich in einem ICal-String immer
    // eine Reihe von Events + ein paar Ausnahmen. Diese Ausnahmen haben eine
    // RECURRENCE-ID, die auf ein Datum gesetzt ist. Daher muss das passende
    // Event mit dieser ID gefunden werden
    if (recurrenceId) {
      const matchingEvent = events
        .filter(icalEvent => {
          if (!icalEvent.recurrenceId) {
            return false;
          }

          const format = icalEvent.recurrenceId.icaltype === 'date'
            ? 'YYYYMMDD'
            : 'YYYYMMDDHHMM';
          const recurrenceDate = moment(icalEvent.recurrenceId.toJSDate());
          const recurrenceFormatted = recurrenceDate.format(format);

          return recurrenceFormatted === recurrenceId;
        });

      const event = Array.isArray(matchingEvent) && matchingEvent.length > 0
        ? matchingEvent[0]
        : null;

      if (!fallBackToFirst && !event) {
        return event;
      }
    }

    // Wird keine recurrenceId angegeben, so wird einfach die Seriendefinition
    // zurückgegeben.
    return events.filter(entry => !entry.isRecurrenceException())[0];
  }

  /**
   * Findet zu einem
   *
   * @param ical
   * @param recurrenceId
   */
  public getInstance(ical: string | Component, recurrenceId: string | Time): Event | null {
    if (typeof ical === 'string') {
      const jCal = parse(ical);
      ical = new Component(jCal);
    }

    if (typeof recurrenceId !== 'string') {
      recurrenceId = recurrenceId.toICALString();
    }

    const eventDefinitions = this.expander.getEvents(ical);
    const seriesDefinition = eventDefinitions.filter(entry => !entry.isRecurrenceException())[0];
    const exDates = this.expander.getExDates(seriesDefinition);
    const exceptionMap = this.expander.getSeriesExceptionMap(eventDefinitions);

    // Wurde die Termininstanz gelöscht?
    if (exDates.has(recurrenceId) && !exceptionMap.has(recurrenceId)) {
      return null;
    }

    // Existiert eine bearbeitete Termininstanz?
    if (exceptionMap.has(recurrenceId)) {
      return exceptionMap.get(recurrenceId) as Event;
    }

    // Findet das Event regulär in der Reihe statt?
    if (this.expander.hasRecurrenceId(seriesDefinition, recurrenceId)) {
      return seriesDefinition;
    }

    return null;
  }

  public getSeriesDefinition(ical: string | Component, recurrenceId: string | Time): Event | null {
    if (typeof ical === 'string') {
      const jCal = parse(ical);
      ical = new Component(jCal);
    }

    const eventDefinitions = this.expander.getEvents(ical);

    return eventDefinitions.filter(entry => !entry.isRecurrenceException())[0];
  }

  /**
   * Versucht, aus dem Kalender die bearbeitete Termininstanz herauszusuchen.
   *
   * @param calendar
   * @param recurrenceId
   */
  public getException(calendar: Component, recurrenceId: string): Event | null {
    const exceptions = calendar.getAllSubcomponents()
      .filter(component => component.name === 'vevent')
      .map(vevent => new Event(vevent))
      .filter(event => event.isRecurrenceException());

    for (const exception of exceptions) {
      if (exception.recurrenceId.toICALString() === recurrenceId) {
        return exception;
      }
    }

    return null;
  }

  public augmentAppointment(appointment: Appointment & BaseModel, event: Event): AugmentedAppointment & BaseModel {
    return {
      ...appointment,
      start: event.startDate.toJSDate(),
      end: event.endDate.toJSDate(),
      title: event.summary,
      report: event.component.hasProperty('description')
        ? event.component.getFirstProperty('description').getFirstValue() as string
        : '',
      location: event.location,
      allDay: event.startDate.icaltype === 'date',
      isRecurring: event.isRecurring(),
      isException: event.isRecurrenceException(),
      repeats: event.isRecurring()
        ? this.extractRepetitionFrequency(event)
        : NO_REPETITION,
      repeatEnd: event.isRecurring()
        ? event.component.getFirstProperty('rrule').getValues()[0].until.toJSDate()
        : null,
      repeatInterval: event.isRecurring()
        ? this.extractRepetitionInterval(event)
        : 0,
      geo: event.component.hasProperty('geo')
        ? event.component.getFirstProperty('geo').getFirstValue()
        : null,
      completed: event.component.hasProperty('status') &&
        (event.component.getFirstProperty('status').getFirstValue() as string).trim().length > 0,
    };
  }

  /**
   * Gibt die Recurrence-Id zurück für ein Event an einem bestimmten Tag.
   * Diese repräsentiert den Starzeitpunkt, an der das Event an dem Tag regulär anfängt.
   *
   * @param {module:ical.js.Event} event Der Termin
   * @param {Date} day Der Tag, an dem der Termin vorkommen soll
   * @returns {module:ical.js.Time | null} Der Startzeitpunkt des Termins an dem Tag oder null, falls er nicht an dem Tag vorkommt
   */
  public getRecurrenceIdOnDay(event: Event, day: Date): Time {
    const recurrenceId = moment(day).clone();
    const startDate = moment(event.startDate.toJSDate());

    recurrenceId.hours(startDate.hours());
    recurrenceId.minutes(startDate.minutes());
    recurrenceId.seconds(startDate.seconds());

    return event.startDate.icaltype === 'date-time'
      ? Time.fromJSDate(recurrenceId.toDate())
      : createTime(recurrenceId.toDate(), true);
  }

  extractRepetitionFrequency(event: Event) {
    const rrule = event.component
      .getFirstProperty('rrule')
      .getValues();

    const freq = rrule[0] as Recur;
    const freqInterval = freq.interval as number;
    const hasInterval = Number.isInteger(freqInterval) && freqInterval > 1;
    const isWeekly = freq.freq === Frequency.WEEKLY;
    const isCustomRepeating = freq.freq === Frequency.DAILY && hasInterval;

    if (hasInterval && isWeekly) {
      // Muss hier zusammengebaut werden, damit das Dropdown die
      // SupportedFrequency erkennt!
      return Frequency.WEEKLY + ';INTERVAL=' + freq.interval as SupportedFrequency;
    }

    if (isCustomRepeating && freq.interval) {
      return CUSTOM_REPETITION as SupportedFrequency;
    }
    return freq.freq as SupportedFrequency;
  }

  public extractRepetitionInterval(event: Event) {
    const rrule = event.component
      .getFirstProperty('rrule')
      .getValues();

    const freq = rrule[0] as Recur;
    if (!freq || !freq.interval || !Number.isInteger(freq.interval)) {
      return 0;
    }
    return freq.interval;
  }
}
