<im-detail-container
  [save]="saveEntity"
  [edit]="editEntity"
  [abort]="abortEdit"
  [delete]="confirmDelete"
  [isEditable]="isEditable"
  [transformEntity]="editEntityAfterRetrieved"
  [endpoint]="endpoint"
  #detailContainer
>
  <ng-template>
    <h1 class="card-title">
      {{ detailContainer?.entity?.name }}
    </h1>

    <im-category-form
      [showSubmit]="false"
      [isEditable]="isEditable"
      [model]="detailContainer.entity"
      [imageIsDeletable]="true"
      [submit]="save"
    >
    </im-category-form>
  </ng-template>
</im-detail-container>

<div class="modal fade" tabindex="-1" role="dialog" #deleteModal>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-danger">
          {{ detailContainer?.entity?.hasChildren
          ? 'Warnung'
          : 'Löschen bestätigen'
          }}
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p *ngIf="detailContainer?.entity?.hasChildren">
          <b>
            Der Kategorie <i>{{detailContainer?.entity?.name}}</i> sind
            weitere Unterkategorien zugeordnet!<br>
          </b>
          Wenn Sie diese Kategorie löschen, werden auch ihre Unterkategorien
          gelöscht.
        </p>
        <p class="mb-0">
          Nach dem Löschen werden alle dieser Kategorie und ihrer
          Unterkategorien zugeordneten Präsentation freigegeben. Dies bedeutet,
          dass die Präsentationen nun <b>nicht</b> mehr in der App angezeigt
          werden, obwohl sie nicht gelöscht sind!<br>
          <br>
          Damit die App die Präsentationen nun wieder anzeigen kann, müssen
          sie erneut einer existierenden Kategorie zugeordnet werden.
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" data-bs-dismiss="modal"
                class="btn btn-outline-secondary-transparent me-auto">
          Abbrechen
        </button>
        <button type="button" data-bs-dismiss="modal" class="btn btn-danger ms-auto" (click)="delete()">
          <im-icon name="trash"></im-icon>
          Löschen
        </button>
      </div>
    </div>
  </div>
</div>
