<a
  class="nav-link dropdown-toggle user-menu-toggle" href="#" id="navbarDropdown"
  role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
  <span class="username">
    Account
  </span>
</a>
<div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
  <a class="dropdown-item" [routerLink]="userProfileLink">
    <im-icon name="user"></im-icon>
    {{ auth.user?.name }}
  </a>
  <a class="dropdown-item text-danger logout" (click)="auth.logout()">
    <im-icon name="sign-out"></im-icon>
    Abmelden
  </a>
</div>
