import { Component, Output, EventEmitter } from '@angular/core';
import { BaseForm } from '../../../../common/forms/base-form';
import { ApiClient } from '../../../../common/api/ApiClient';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DataTableService } from '../../../../common/api/data-table/data-table.service';
import {ORIGIN_ENDPOINT} from "../modals/OrderOrigin";

@Component({
  selector: 'im-create-origin',
  templateUrl: './create-origin.component.html',
})
export class CreateOriginComponent extends BaseForm  {
  @Output()
  public originCreated = new EventEmitter();

  public form = new UntypedFormGroup({
    Key: new UntypedFormControl('', [Validators.required, Validators.pattern("^[0-9]*$")]),
    Name: new UntypedFormControl('', Validators.required),
  });

  constructor(public api: ApiClient, private toastr: ToastrService, private dataTable: DataTableService) {
    super();
  }

  public onSubmit(): void {
    super.onSubmit();
    if (this.form.valid) {
      const { users, category, ...data } = this.form.value;
      this.api.store(ORIGIN_ENDPOINT, data).subscribe(
        response => {
          this.toastr.success('Die Auftragsherkunft wurde erfolgreich erstellt!');
          this.reset();
          this.dataTable.reload();
          this.originCreated.emit();
        }, error => {
          this.toastr.error("Auftragsherkunft kann nicht angelegt werden. Herkunfts-ID darf nicht doppelt vergeben werden", "Fehler beim Anlegen");
        }
      );
    } else {
      this.validateAllFormFields(this.form);
    }
  }

  get Key () {
    return this.form.get('Key');
  }

  get Name () {
    return this.form.get('Name');
  }

}
