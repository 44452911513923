import { Component, Input, OnInit } from '@angular/core';
import { SimpleEntityForm } from '../../../../common/forms/SimpleEntityForm';
import { ContactPosition } from '../../models/ContactPosition';
import { ToastrService } from 'ngx-toastr';
import { Formify } from '../../../../common/forms/Formify';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'sebu-customer-contact-position-form',
  templateUrl: 'customer-contact-position-form.component.html',
  styles: [],
})
export class CustomerContactPositionFormComponent extends SimpleEntityForm<ContactPosition> implements OnInit {
  @Input()
  entity: ContactPosition = {
    position: '',
  };

  @Input()
  send: (data: ContactPosition) => void;

  @Input()
  showSubmit = false;

  constructor(protected toastr: ToastrService) {
    super(toastr);
  }

  ngOnInit() {
    const controls: Formify<ContactPosition> = {
      position: new UntypedFormControl(this.entity.position, Validators.required),
    };

    this.form = new UntypedFormGroup(controls);
  }

  get position() {
    return this.form.get('position');
  }
}
