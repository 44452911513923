import { Component } from '@angular/core';
import {
  CUSTOMER_ENDPOINT,
  showAnsprechPartner,
  showContactInformation,
  showCustomerName,
  showCustomerNumber,
  showOrt,
} from '../../models/Customer';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FileDownloadService } from '../../../../common/api/file-download.service';
import { CONTACT_CSV_EXPORT_ENDPOINT } from '../../models/Contact';

@Component({
  selector: 'sebu-customers-table',
  templateUrl: './customers-table.component.html',
  styleUrls: ['customers-table.component.scss'],
})
export class CustomersTableComponent {
  endpoint = CUSTOMER_ENDPOINT;
  filters = new UntypedFormGroup({
    'search': new UntypedFormControl(),
  });

  showCustomerName = showCustomerName;
  showCustomerNumber = showCustomerNumber;
  showOrt = showOrt;
  showAnsprechPartner = showAnsprechPartner;
  showContactInformation = showContactInformation;
  searchHint = `Es können die Felder Kundennummer, Kundenname und Ort durchsucht werden.`;

  constructor(
    protected fileDownload: FileDownloadService,
  ) {}

  public static get csvExportEndpoint() {
    return `${CUSTOMER_ENDPOINT}/csv-export`;
  }

  public downloadCustomerExport() {
    this.fileDownload.download(CustomersTableComponent.csvExportEndpoint);
  }

  public downloadContactExport() {
    this.fileDownload.download(CONTACT_CSV_EXPORT_ENDPOINT);
  }
}
