<im-data-table [endpoint]="endpoint">
  <ng-template imDataTableHeaders>
    <th>Name</th>
    <th>Farbe</th>
    <th class="actions-column">Aktionen</th>
  </ng-template>

  <ng-template imDataTableRow let-row>
    <td>{{ row.name }}</td>
    <td><span class="colorDisplay" [style.background-color]="row.color"></span></td>
    <td>
      <div class="btn-group">
        <button imDetailButton [model]="row">Details</button>
        <button
          imDeleteButton
          [delete]="deleteCategory"
          [model]="row"
          [endpoint]="endpoint"
        >
          Löschen
        </button>
      </div>
    </td>
  </ng-template>
</im-data-table>
