<div class="modal fade" tabindex="-1" role="dialog" #modalRoot>
  <div class="modal-dialog mw-100 w-75" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Notiz {{ createMode ? 'erstellen' : 'bearbeiten' }}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <h2>Notiz</h2>
        <textarea class="form-control" rows="15" [formControl]="contentControl" maxLength="1000"></textarea>
        <p [ngClass]="{'text-danger': !contentControl.valid}">{{ contentControl.value?.length}} / 1000 Zeichen</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="hide()">Abbrechen</button>
        <button type="button" class="btn btn-primary" (click)="saveNote()">Speichern</button>
      </div>
    </div>
  </div>
</div>
