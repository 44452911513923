import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {BsModalComponentBase} from "../../../../../common/layout/bs-modal";
import {ENDPOINT, Note} from "../../../models/Note";
import {FormControl, Validators} from "@angular/forms";
import { ApiClient } from '../../../../../common/api/ApiClient';
import { ToastrService } from 'ngx-toastr';
import {UUID} from "../../../../../common/generic/UUID";
import {AuthService} from "../../../../../im-modules/user/auth/auth.service";

@Component({
  selector: 'sebu-customer-note-modal',
  templateUrl: './customer-note-modal.component.html',
  styles: [
  ]
})
export class CustomerNoteModalComponent extends BsModalComponentBase {
  @ViewChild('modalRoot') modalRoot: ElementRef;
  @Input() noteId: UUID|null = null;
  @Input() customerId: UUID;
  @Input() content: string;

  @Output() save = new EventEmitter<string>();
  endpoint = ENDPOINT;
  createMode = true;

  contentControl: FormControl = new FormControl('', [Validators.required, Validators.minLength(1)]);

  constructor(public api: ApiClient, private toastr: ToastrService, private auth: AuthService) {
    super();
  }

  showForNote = (note: Note|null) => {
    if (!note) {
      this.noteId = null;
      this.contentControl.setValue('');
      this.createMode = true;
      this.show();
    } else {
      this.noteId = note.Id;
      this.contentControl.setValue(note.content);
      this.createMode = false;
      this.show();
    }
  }
  saveNote() {
    if (!this.contentControl.valid) {
      this.toastr.error('Bitte geben Sie einen Inhalt ein!');
      return;
    }
    if (!this.noteId) { // Create
      this.api.store('notes', {
        content: this.contentControl.value,
        customerId: this.customerId,
        creatorId: this.auth.user!.Id,
        creatorName: this.auth.user!.name,
      }).subscribe(
        () => {
          this.toastr.success('Die Notiz wurde erfolgreich erstellt!');
          this.save.emit(this.contentControl.value);
        });
    } else {
      this.api.update('notes', this.noteId.toString(), {content: this.contentControl.value}).subscribe(
        () => {
          this.toastr.success('Die Notiz wurde erfolgreich aktualisiert!');
          this.save.emit(this.contentControl.value);
        });
    }
  }
}
