<form [formGroup]="form" (submit)="onSubmit()">
  <div class="form-group" *ngIf="isEditable && !isRecurringInstance">
    <label for="appointmentName">Name</label>
    <input type="text" class="form-control" id="appointmentName"
           [formControl]="appointmentName"
           [ngClass]="displayFieldCss('appointmentName')"/>
    <im-field-error-display
      [displayError]="isFieldInValid('appointmentName')"
      errorMsg="Bitte geben Sie einen Namen ein!">
    </im-field-error-display>
  </div>

  <div class="d-flex mt-1 mb-1" *ngIf="isEditable" >
    <label class="p-2">
      <input type="radio"  name="choose" [disabled]="editMode === editModes.Instance" [checked]="isCustomer" (change)="radioButtonClicked()"> Kunde
    </label>
    <label class="p-2">
      <input type="radio" name="choose" [disabled]="editMode === editModes.Instance" [checked]="!isCustomer" (change)="radioButtonClicked()"> Interessent
    </label>
  </div>
  <div class="form-group">
    <ng-container *ngIf="isEditable&&isCustomer">
      <im-kunde-search
        endpoint="/kunden" [formatter]="displayKundeSearchResult"
        id="firm" [formControl]="firm" [onSelect]="onKundeSelect"
        [ngClass]="displayFieldCss('firm')"
        [disabled]="isRecurringInstance"
        ngDefaultControl
      >
      </im-kunde-search>
      <im-field-error-display
        [displayError]="isFieldInValid('firm')"
        errorMsg="Bitte geben Sie einen Kundennamen ein!">
      </im-field-error-display>
    </ng-container>

    <ng-container *ngIf="isEditable&&!isCustomer">
      <im-kunde-search
        endpoint="/potential-customers" [formatter]="displayPotentialKundeSearchResult"
        id="firm" [formControl]="firm" [onSelect]="onPotentialCustomerSelect"
        [ngClass]="displayFieldCss('firm')"
        [disabled]="editMode === editModes.Instance"
        [userId]="userId"
        ngDefaultControl
      >
      </im-kunde-search>
      <im-field-error-display
        [displayError]="isFieldInValid('firm')"
        errorMsg="Bitte geben Sie einen Interessenten-Namen ein!">
      </im-field-error-display>
    </ng-container>
    <ng-container *ngIf="!isEditable">
      <span class="form-control-plaintext"
            id="firm">{{appointment.customerName}}</span>
    </ng-container>
  </div>

  <div class="form-group" *ngIf="!isEditable && appointment?.customer">
    <label for="customer-number">Kundennummer</label>
    <span class="form-control-plaintext" id="customer-number">
        <a [routerLink]="linkToCustomer(appointment?.customer)"
           class="text-dark">
          {{ showCustomerNumber(appointment?.customer) }}
        </a>
    </span>
  </div>

  <div class="form-group">
    <label for="contactPersons">Ansprechpartner</label>
    <im-addable-text-list id="contactPersons" [formControl]="contactPersons"
                          [display]="displayContactPerson"
                          [disabled]="isRecurringInstance"
                          [ngClass]="displayFieldCss('contactPersons')"
                          [editable]="isEditable"></im-addable-text-list>
    <im-field-error-display
      [displayError]="isFieldInValid('contactPersons')"
      errorMsg="Bitte geben Sie einen Ansprechpartner an!">
    </im-field-error-display>
  </div>

  <div class="form-group">
    <label for="appointmentType">Terminart</label>
    <ng-container *ngIf="isEditable">
      <select class="form-control form-select" id="appointmentType"
              [compareWith]="identityComparator"
              [formControl]="appointmentType"
              [ngClass]="displayFieldCss('appointmentType')"
      >
        <option></option>
        <option *ngFor="let type of appointmentTypes" [ngValue]="type">
          {{type.name}}
        </option>
      </select>
      <im-field-error-display
        [displayError]="isFieldInValid('appointmentType')"
        errorMsg="Bitte geben Sie eine Terminart an!">
      </im-field-error-display>
    </ng-container>
    <ng-container *ngIf="!isEditable">
      <span class="form-control-plaintext"
            id="appointmentType">{{appointment?.appointmentType?.name}}</span>
    </ng-container>
  </div>

  <div class="form-row">
    <div class="form-group col-md-6">
      <label for="timeRangeStart" class="me-2">Start</label>
      <ng-container *ngIf="isEditable">
        <p-calendar
          class="ui-fluid"
          dateFormat="dd.mm.yy"
          [hourFormat]="24"
          (onSelect)="onStartChange($event);"
          [locale]="de"
          [showTime]="!_allDay"
          [inputStyleClass]="getFieldCssString('timeRangeStart', !startNotBeforeEnd)"
          id="timeRangeStart"
          [formControl]="timeRangeStart"
          [minDate]="dateOfToday"
          [maxDate]="nextSeasonEnd"
        >
        </p-calendar>

        <im-field-error-display
          [displayError]="isFieldInValid('timeRangeStart') || startNotBeforeEnd"
          errorMsg="Bitte geben Sie einen gültigen Zeitraum ein!">
        </im-field-error-display>
      </ng-container>
      <ng-container *ngIf="!isEditable">
        <span class="form-control-plaintext"
              id="timeRangeStart">{{safeStartDate | sbDate:appointment.allDay}}</span>
      </ng-container>
    </div>

    <div class="form-group col-md-6">
      <label for="timeRangeEnd" class="me-2">Ende</label>
      <ng-container *ngIf="isEditable">
        <p-calendar
          class="ui-fluid"
          dateFormat="dd.mm.yy" hourFormat="24"
          [locale]="de" [showTime]="!_allDay"
          [inputStyleClass]="getFieldCssString('timeRangeEnd', !startNotBeforeEnd)"
          [minDate]="minMaxDates.timeRangeEnd.min"
          [maxDate]="nextSeasonEnd"
          (onSelect)="onEndChange($event)"
          id="timeRangeEnd" [formControl]="timeRangeEnd"></p-calendar>

        <im-field-error-display
          [displayError]="isFieldInValid('timeRangeEnd') || startNotBeforeEnd || appointmentLengthExceeded"
          [errorMsg]="form && form.errors && form.errors['appointmentLengthExceeded']
              ? form.errors['appointmentLengthExceeded']
              : 'Bitte geben Sie einen gültigen Zeitraum ein!'">
        </im-field-error-display>
      </ng-container>
      <ng-container *ngIf="!isEditable">
        <span class="form-control-plaintext"
              id="timeRangeEnd">{{safeEndDate | sbDate:appointment.allDay}}</span>
      </ng-container>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-md-6 d-flex align-items-center">
      <div class="form-check" *ngIf="isEditable">
        <input type="checkbox" class="form-check-input" id="allDay"
               [formControl]="allDay" [(ngModel)]="_allDay"/>
        <label class="form-check-label" for="allDay">Ganztägig</label>
      </div>
    </div>

    <div class="form-group col-md-6">
      <label for="repeats">Wiederholen</label>
      <ng-container *ngIf="isEditable">
        <select id="repeats" class="form-control form-select" [formControl]="repeats"
                (change)="onRepeatChange()">
          <option *ngFor="let interval of repetitions"
                  [ngValue]="interval[0]">{{interval[1]}}</option>
        </select>
      </ng-container>
      <ng-container *ngIf="isEditable && isCustomRepeating && !isRecurringInstance">
        <input type="number" min="2" class="form-control" id="customRepeatDays" value="{{((this.appointment.repeatInterval === undefined) || (this.appointment.repeatInterval === 0)) ? 2 : this.appointment.repeatInterval}}"
               (change)="onCustomRepeatDaysChanged()"/>
      </ng-container>
      <ng-container *ngIf="!isEditable">
        <span class="form-control-plaintext"
              id="repeats">{{getFrequencyLabel(appointment.repeats)}}</span>
      </ng-container>
    </div>
  </div>

  <div class="form-row" *ngIf="isRepeating">
    <div class="form-group col-md-6 offset-6">
      <label for="season">Saison</label>
      <ng-container *ngIf="isEditable">
        <select class="form-control form-select" id="season" [formControl]="season">
          <option value="">Ganzjährig</option>
          <option value="{{AppointmentSeasons.SUMMER}}">Sommer</option>
          <option value="{{AppointmentSeasons.WINTER}}">Winter</option>
        </select>
      </ng-container>
      <ng-container *ngIf="!isEditable">
        <span class="form-control-plaintext"
              id="season">{{appointment.season === AppointmentSeasons.SUMMER ? "Sommer" : appointment.season === AppointmentSeasons.WINTER ? "Winter" : "Ganzjährig"}}</span>
      </ng-container>
    </div>
  </div>

  <div class="form-row" *ngIf="isRepeating">
    <div class="form-group col-md-6 offset-6">
      <label for="repeatEnd" class="me-2">Enddatum der Wiederholung</label>
      <ng-container *ngIf="isEditable">
        <p-calendar
          class="ui-fluid" inputId="repeatEnd"
          dateFormat="dd.mm.yy" [hourFormat]="24"
          [locale]="de" [showTime]="false" inputStyleClass="form-control"
          [formControl]="repeatEnd" [minDate]="minMaxDates.repeatEnd.min"
          [maxDate]="nextSeasonEnd"
          [inputStyleClass]="getFieldCssString('repeatEnd', !repeatEndHasErrors)"></p-calendar>
      </ng-container>
      <im-field-error-display
        [displayError]="isFieldInValid('repeatEnd') || repeatEndHasErrors"
        [errorMsg]="form && form.errors && form.errors['recurrenceEnd']
          ? form.errors['recurrenceEnd']
          : 'Bitte geben Sie einen gültigen Zeitraum ein!'"
      ></im-field-error-display>
      <ng-container *ngIf="!isEditable">
        <span class="form-control-plaintext"
              id="repeatEnd">{{appointment.repeatEnd | sbDate:true}}</span>
      </ng-container>
    </div>
  </div>

  <div class="form-group">
    <label for="appointmentLocation">Ort</label>
    <ng-container *ngIf="isEditable">
      <input type="text" class="form-control" id="appointmentLocation"
             [formControl]="appointmentLocation"
             [ngClass]="displayFieldCss('appointmentLocation')"/>
      <im-field-error-display
        [displayError]="isFieldInValid('appointmentLocation')"
        errorMsg="Bitte geben Sie einen Ort an!">
      </im-field-error-display>
    </ng-container>
    <ng-container *ngIf="!isEditable">
      <a *ngIf="appointmentLocationLink" class="form-control-plaintext"
         [href]="appointmentLocationLink">
        {{appointment.location}}
      </a>
      <span *ngIf="!appointmentLocationLink"
            title="Es konnten keine Koordinaten zum angegebenen Ort ermittelt werden!"
            class="form-control-plaintext" id="appointmentLocation">
        {{appointment.location}}
      </span>
    </ng-container>
  </div>

  <div class="form-group" *ngIf="isCreationForm">
    <button class="btn btn-primary" type="submit" imDisabledWhileLoading>
      <im-icon name="plus"></im-icon>
      Termin erstellen
    </button>
  </div>
</form>
