<form [formGroup]="form" (submit)="onSubmit()">
  <!---------------------------------------------------------------------------
                                    Name
  ---------------------------------------------------------------------------->

  <im-form-group-container
    [editable]="isEditable"
    [id]="'categoryName'"
    label="Name"
    errorMessage="Bitte geben Sie einen Namen an!"
    [isValid]="isFieldInValid('categoryName')"
  >
    <ng-template imFormGroupReadable>
      <span class="form-control-plaintext">
        {{model?.name}}
      </span>
    </ng-template>

    <ng-template imFormGroupEditable>
      <input class="form-control" type="text" id="categoryName"
             [formControl]="categoryName" [ngClass]="displayFieldCss('categoryName')"/>
    </ng-template>
  </im-form-group-container>

  <!---------------------------------------------------------------------------
                                    Beschreibung
  ---------------------------------------------------------------------------->

  <im-form-group-container
    [editable]="isEditable"
    [id]="'description'"
    label="Beschreibung"
    errorMessage="Die Beschreibung darf maximal 1000 Zeichen lang sein!"
    [isValid]="isFieldInValid('description')"
  >
    <ng-template imFormGroupReadable>
      <p class="form-control-plaintext"
         style="white-space: pre-wrap"
         [innerHTML]="model?.description | nl2br"
      ></p>
    </ng-template>

    <ng-template imFormGroupEditable>
      <textarea class="form-control" id="description"
                [formControl]="description" [ngClass]="displayFieldCss('description')">
      </textarea>
      <small id="emailHelp" class="form-text text-muted">
        {{ description?.value?.length }} / 1000
      </small>
    </ng-template>
  </im-form-group-container>

  <div class="form-group">
    <label>Veröffentlichen am</label>
    <ng-container *ngIf="isEditable">
      <p-calendar
        class="ui-fluid"
        dateFormat="dd.mm.yy"
        hourFormat="24"
        [minDate]="today"
        [locale]="de"
        [showTime]="false"
        [formControl]="publishedAt"
      >
      </p-calendar>
    </ng-container>
    <ng-container *ngIf="!isEditable">
      <span class="form-control-plaintext">{{model?.publishedAt | sbDate:true}}</span>
    </ng-container>
  </div>

  <!---------------------------------------------------------------------------
                                    Bild
  ---------------------------------------------------------------------------->

  <im-form-group-container
    [editable]="isEditable"
    [id]="'picture'"
    label="Bild"
    [isValid]="isFieldInValid('picture')"
  >
    <ng-template imFormGroupReadable>
      <blob-image class="d-block" [blob]="model?.picture"></blob-image>
    </ng-template>

    <ng-template imFormGroupEditable>
      <im-file-input
        [formControl]="picture"
        [deletable]="imageIsDeletable && model?.picture !== undefined"
        (delete)="shouldDeleteFile = true"
        (cancelDelete)="shouldDeleteFile = false"
        accept="image/*"
      ></im-file-input>
    </ng-template>
  </im-form-group-container>

  <!---------------------------------------------------------------------------
                                 Oberkategorie
  ---------------------------------------------------------------------------->
  <im-form-group-container
    [editable]="isEditable"
    [id]="'parent'"
    label="Oberkategorie"
    errorMessage="Eine Kategorie kann sich nicht selbst als Oberkategorie zugeordnet werden!"
    [isValid]="isFieldInValid('parent')"
  >
    <ng-template imFormGroupReadable>
     <span id="parent" class="form-control-plaintext">
      {{model?.parent?.name}}
    </span>
    </ng-template>

    <ng-template imFormGroupEditable>
      <im-category-selector id="parent" [formControl]="parent" [additionalEndpointParams]="parentCategorySelectorFilter"
                            [childClasses]="displayFieldCss('parent')"></im-category-selector>
    </ng-template>
  </im-form-group-container>

  <!---------------------------------------------------------------------------
                               Sortierreihenfolge
  ---------------------------------------------------------------------------->
  <im-form-group-container
    [editable]="isEditable"
    [id]="'sortOrder'"
    label="Sortierreihenfolge"
    errorMessage="Bitte geben sie eine gültige Stelle in der Sortierreihenfolge an!"
    [isValid]="isFieldInValid('sortOrder')"
  >
    <ng-template imFormGroupReadable>
      <span class="form-control-plaintext">
        {{model?.sortOrder}}
      </span>
    </ng-template>

    <ng-template imFormGroupEditable>
      <input class="form-control" type="number" id="sortOrder" step="1" min="1"
             [formControl]="sortOrder" [ngClass]="displayFieldCss('sortOrder')"/>
    </ng-template>
  </im-form-group-container>

  <!---------------------------------------------------------------------------
  -                                                                           -
  -                                 Submit                                    -
  -                                                                           -
  ---------------------------------------------------------------------------->
  <div class="form-group" *ngIf="showSubmit">
    <button class="btn btn-primary" type="submit" imDisabledWhileLoading>
      <im-icon name="plus"></im-icon>
      Erstellen
    </button>
  </div>
</form>
