import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../im-modules/user/auth/auth.guard';
import { UpdateTitle } from '../../common/generic/title/title.service';
import { NgModule } from '@angular/core';
import { SettingsComponent } from './settings.component';
import { OrderCommentsComponent } from './order-comments/order-comments.component';
import { OrderPositionCommentsComponent } from './order-position-comments/order-position-comments.component';
import { EmailsFailedOrderUploadComponent } from './emails-failed-order-upload/emails-failed-order-upload.component';
import {NewCustomerPdfUploadComponent} from "./newcustomer-pdf-upload/newCustomer-pdf-upload";
import {
  EmailsNewCustomerApplicationComponent
} from "./emails-new-customer-application/emails-new-customer-application.component";
import {PotentialCustomerImportComponent} from "./potential-customer-import/potential-customer-import";
import {AdminGuard} from "../../im-modules/user/auth/admin.guard";

export const PATHS = {
  'uebersicht': '',
  'auftragskommentare': 'auftrag-kommentare',
  'auftragpositionskommentare': 'auftrag-position-kommentare',
  'emailsFailedAuftragUpload': 'emails-failed-auftrag-upload',
  'emailsNewCustomerApplication': 'emails-new-customer-application',
  'neukundenpdf':'Neukunden-PDF',
  'potentialCustomerImport':'potential-customer-import',
};

const routes: Routes = [
  {
    path: PATHS['uebersicht'],
    component: SettingsComponent,
    canActivate: [AuthGuard],
    data: { title: new UpdateTitle('Einstellungen') },
  },
  {
    path: PATHS['auftragskommentare'],
    component: OrderCommentsComponent,
    canActivate: [AuthGuard],
    data: { title: new UpdateTitle('Auftragskommentare')  },
  },
  {
    path: PATHS['auftragpositionskommentare'],
    component: OrderPositionCommentsComponent,
    canActivate: [AuthGuard],
    data: { title: new UpdateTitle('Positionskommentare')  },
  },
  {
    path: PATHS['emailsFailedAuftragUpload'],
    component: EmailsFailedOrderUploadComponent,
    canActivate: [AuthGuard],
    data: { title: new UpdateTitle('E-Mails Auftrag Upload')  },
  },
  {
    path: PATHS['emailsNewCustomerApplication'],
    component: EmailsNewCustomerApplicationComponent,
    canActivate: [AuthGuard],
    data: { title: new UpdateTitle('E-Mails Neukunden Antrag')  },
  },
  {
    path: PATHS['neukundenpdf'],
    component: NewCustomerPdfUploadComponent,
    canActivate: [AuthGuard],
    data: { title: new UpdateTitle('Neukunden-Pdf hochladen')  },
  },
  {
    path: PATHS['potentialCustomerImport'],
    component: PotentialCustomerImportComponent,
    canActivate: [AdminGuard],
    data: { title: new UpdateTitle('Interessenten Import')  },
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SettingsRoutingModule {
}
