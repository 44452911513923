import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Contact } from '../../../../models/Contact';
import { FromDB } from '../../../../../../common/api/FromDB';
import { GlobalLoadingIndicatorService } from '../../../../../../common/api/global-loading-indicator/global-loading-indicator.service';

@Component({
  selector: 'sebu-contact-switcher',
  templateUrl: 'contact-switcher.component.html',
})
export class ContactSwitcherComponent {
  constructor(public loadingService: GlobalLoadingIndicatorService) {
  }

  @Input() contacts: FromDB<Contact>[] = [];
  @Input() activeContact: Contact | undefined | null;
  @Input() adisContactName = '';
  @Output() contactSelected = new EventEmitter<Contact>();
}
