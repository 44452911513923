import { EventEmitter, Injectable } from '@angular/core';
import { PaginationMetadata } from '../paginator/PaginationMetadata';
import { Params } from '@angular/router';

/**
 * Ein Service, der die im Injectorgraph am nächsten gelegene DataTable mit anderen Komponenten lässt.
 * So können Komponenten auch ohne konkreten Verweis auf eine DataTable-Instanz z.B. ein Neuladen der DataTable auslösen.
 */
@Injectable({
  providedIn: 'root',
})
export class DataTableService {
  /**
   * Wird *nur* von der DataTable verwendet, um es zu ermöglichen, dass Komponenten ein Neuladen der
   * Seite anfordern können.
   * Komponenten sollten dazu die Methoden {@link DataTableService::load} und {@link DataTableService::reload} nutzen.
   * @private
   */
  public _reloadEvent = new EventEmitter<Params | string | null | undefined>();

  /**
   * Wird nach jedem erfolgreichem Seitenladen von der DataTable ausgelöst. So können Metadaten über die Seiten erhalten
   * werden. Hier kann der Eventemitter nocht erweitert werden, fürs erste reicht es, nur die Metadaten zu
   * veröffentlichen.
   */
  public pageLoadedEvent = new EventEmitter<PaginationMetadata>();

  /**
   * Wird vor jedem Seitenladen von der DataTable ausgelöst.
   */
  public loadingStartedEvent = new EventEmitter();

  /**
   * Wird nach jedem Seitenladen von der DataTable ausgelöst, auch bei Fehlern!
   */
  public loadingFinishedEvent = new EventEmitter();

  /**
   * Fordert die DataTable auf, sich zu aktualisieren. Dabei bleiben alle Query-Parameter erhalten!
   */
  public reload() {
    this._reloadEvent.next(null);
  }

  /**
   * Fordert die DataTable auf, die gewünschte Seite zu laden.
   *
   * @param {Params | string} url Ist hier ein String übergeben, so wird angenommen, dass es sich um eine gültige URL
   *                              handelt. Diese wird dann direkt angefragt. Werden Parameter übergeben, so werden diese
   *                              mit den aktuellen Query-Parametern aus der URL vereinigt, wobei die übergebenenen
   *                              Parameter eine höhere Priorität haben. Als Basis wird dann der der DataTable
   *                              übergebene Endpunkt verwendet.
   *                              Die strings des Objekts werden zudem noch nachher durch encodeURIComponent gereicht,
   *                              sie sollten also noch nicht escaped sein!
   */
  public load(url: Params | string) {
    this._reloadEvent.next(url);
  }
}
