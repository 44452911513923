import { Component, Host, Input, OnInit, Optional } from '@angular/core';
import { MESSAGE_CATEGORY_ENDPOINT, MessageCategory } from '../models/MessageCategory';
import {AbstractControl, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import { Formify } from '../../../common/forms/Formify';
import { BaseEntityForm } from '../../../common/forms/BaseEntityForm';
import { ToastrService } from 'ngx-toastr';
import { ApiClient } from '../../../common/api/ApiClient';
import { OverviewContainerComponent } from '../../../common/layout/overview-container/overview-container.component';

@Component({
  selector: 'im-message-category-form',
  template: `
    <form [formGroup]="form" (submit)="onSubmit()">
      <div class="form-group" >
        <label>Name</label>
        <input type="text" class="form-control" [formControl]="name" required/>
        <div *ngIf="name.invalid && name.touched"
             class="alert alert-danger">
        <div *ngIf="name.errors?.['required']">
          Bitte Namen Für die Kategorie eingeben
        </div>
        </div>
      </div>

      <div class="form-group">
        <label>Farbe</label>
        <input class="d-none" [formControl]="color"/>
        <span
          [style.background]="color.value"
          [colorPicker]="color.value"
          (colorPickerChange)="color.setValue($event)"
          [cpToggle]="true"
          [cpDialogDisplay]="'inline'"
          [cpOutputFormat]="'hex'"
        ></span>
      </div>

      <div class="form-group" *ngIf="isCreationForm">
        <button type="submit" class="btn btn-primary" imDisabledWhileLoading>
          <im-icon name="plus"></im-icon>
          Kategorie erstellen
        </button>
      </div>
    </form>
  `,
})
export class MessageCategoryFormComponent extends BaseEntityForm<MessageCategory, MessageCategory> implements OnInit {
  @Input() entity: MessageCategory = {
    color: '#FFF',
    name: '',
  };
  @Input() isCreationForm = false;
  endpoint = MESSAGE_CATEGORY_ENDPOINT;

  @Input() submitForm(category: MessageCategory) {
  }

  constructor(
    protected toastr: ToastrService,
    protected api: ApiClient,
    @Optional() @Host() private overviewContainer: OverviewContainerComponent,
  ) {
    super(toastr, api);
  }

  ngOnInit() {
    const controls: Formify<MessageCategory> = {
      name: new UntypedFormControl(this.entity.name),
      color: new UntypedFormControl(this.entity.color),
    };

    this.form = new UntypedFormGroup(controls);
  }

  getDataToSubmit(): MessageCategory {
    return this.form.value;
  }

  get color() {
    return this.form.get('color') as AbstractControl;
  }

  get name() {
    return this.form.get('name') as AbstractControl;
  }

  submit(data: MessageCategory) {
    this.submitForm(data);
  }
}
