import { WarenGruppenIconWithBlob } from './WarenGruppenIcon';

export interface WarenGr {
  Sortimentsnummer: number;
  Hauptwarengruppe: number;
  Unterwarengruppe: number;
  Bezeichnung: string;
  Order: number;
}

export type WarenGrWithIcon = WarenGr & { icon: WarenGruppenIconWithBlob };

export const WAREN_GR_ENDPOINT = '/warengr';
