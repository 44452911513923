import { BaseForm } from './base-form';
import { UntypedFormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';

/**
 * Eine Klasse zum anlegen einer simplen Form, die eine Entität verwaltet.
 * T => Die verwaltete Entität
 * V => Form, in der die Daten an die Api gesendet werden
 */
@Injectable()
export abstract class SimpleEntityForm<T, V = T> extends BaseForm {
  /**
   * Sendet die fürs Backend vorbereiteten Daten aus dem Formular an das Backend. Sollte via @Input()-hereingereicht
   * werden, um ein Erstellen, sowie Aktualisieren zu ermöglichen.
   * @param data Die fürs Backend vorbereiteten Daten
   */
  public abstract send: (data: V) => void;
  /**
   * Die zu verwaltende Entität. Diese sollte via @Input()-hereingereicht werden können, sodass die Form zum
   * Erstellen, sowie Aktualisieren von Entitäten verwendet werden kann! Aus diesem Grund sollte auch die
   * `send`-Funktion als Parameter übergeben werden.
   */
  public abstract entity: T;

  protected constructor(protected toastr: ToastrService) {
    super();
  }

  /**
   * Callback, der im Falle einer erfolgreichen Antwort aufgerufen werden sollte.
   * @param message
   */
  public onSuccessfulSubmit(message: string) {
    this.toastr.success(message);
    this.reset();
  }

  /**
   * Berechnet aus dem aktuellem Zustand der Form die Daten, die ans Backend gesendet werden.
   */
  get dataForApi(): V {
    return this.form.value;
  }

  /**
   * Diese Methode triggert das Validieren der Form und sollte als (submit)-Handler angegeben werden.
   * Ist das Formular valide, so wird die `send`-Methode mit den extrahierten Daten der Form aus `dataForApi`
   * @see send
   * @see dataForApi
   */
  public onSubmit() {
    super.onSubmit();
    if (this.form.valid) {
      this.send(this.dataForApi);
    } else {
      this.validateAllFormFields(this.form);
    }
  }
}
