import { Component, Input } from '@angular/core';
import { NavRoute } from './top-nav-routes.component';

/**
 * Zeigt, basierend auf dem children-Array der übergebenen Route entweder einen Link oder ein Dropdown mit Links an.
 */
@Component({
  selector: 'im-top-nav-route',
  template: `
    <ng-container *ngIf="!isArray(route.children) || route?.children?.length === 0">
      <ng-container *ngTemplateOutlet="navRouteTemplate"></ng-container>
    </ng-container>
    <ng-container *ngIf="route.children">
      <ng-container *ngTemplateOutlet="navRouteGroupTemplate"></ng-container>
    </ng-container>

    <ng-template #navRouteTemplate>
      <div
        class="nav-item"
        routerLinkActive="active"
        [routerLinkActiveOptions]="route.routerLinkActiveOptions"
      >
        <a class="nav-link" [routerLink]="'/' + route.path">{{route.label}}</a>
      </div>
    </ng-template>

    <ng-template #navRouteGroupTemplate>
      <div
        class="nav-item dropdown"
        routerLinkActive="active"
        [routerLinkActiveOptions]="route.routerLinkActiveOptions"
      >
        <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="true">
          {{route.label}}
        </a>
        <div class="dropdown-menu">
          <a *ngFor="let childRoute of route.children" class="dropdown-item"
             routerLinkActive="active" [routerLinkActiveOptions]="childRoute.routerLinkActiveOptions"
             routerLink="/{{childRoute.path}}">
            {{childRoute.label}}
          </a>
        </div>
      </div>
    </ng-template>
  `,
})
export class TopNavRouteComponent {
  @Input() route: NavRoute;
  isArray = Array.isArray;
}
